import React from 'react'
import classnames from 'classnames';
import { config } from '../../helpers';

export const renderScrollDownHint = (isScrollo = false, isDesktop = false) => {
    /* let deviceFrame = document.getElementById("bb-device-frame-content-body");
    if (deviceFrame) {
      let deviceFrameParent = deviceFrame.parentElement;
      if (deviceFrameParent) {
        setTimeout(function () {deviceFrameParent.addEventListener('scroll', () => {
          setTimeout(function () {
            document.getElementById("scroller-hint").style.display = "none"
          }, 1000)
        });
      }, 1000)
      }
    } 
    else {
      setTimeout(function () {
        
          document.getElementById("bm-creative-preview").addEventListener('scroll', () => {
            setTimeout(function () {
              document.getElementById("scroller-hint").style.display = "none"
            }, 1000)
          });
  
          window.addEventListener('scroll', () => {
            setTimeout(function () {
              document.getElementById("scroller-hint").style.display = "none"
            }, 1000)
          });        
        
    }, 1000)
    } */

    return (
        <div id="scroller-hint" className={classnames("col-12", isScrollo ? isDesktop ? "scroller-hint-scrollo-desktop" : "scroller-hint-scrollo" : "")}>
            <div className="hint-text d-flex flex-row justify-content-center">
                <img src={config.host_bb + "/images/scroll-arrow.svg"} alt="" />
                <span className="align-self-center">Scroll down</span>
                <img src={config.host_bb + "/images/scroll-arrow.svg"} alt="" />
            </div>
            <img className="hint-gesture" src={config.host_bb + "/images/scroll-gesture.png"} alt="" />
        </div>
    )
}

export const renderArticle = (short = true, desktop = false, isScroller = false) => {
    return (
        !desktop ?
            <div className={classnames("col-12 noselect", isScroller ? "pointer-auto" : "")}>
                <h2>Mobile</h2>
                <div className="placeholder fa-pull-left"><span className="d-none"></span></div>
                {short ?
                    <p>...... ........ ... ....... ... ........ . ...... ..... ........ ... ...... ... ......... .. ....... ... ... .. .... ............</p>
                    :
                    <span>
                        <p>... ..... .. ...... ..... .. ... .. .... ...... .. ...... .......... .......... ... ... .... .. ..... .. ...... ... ..... .. ... ........ . ..... .. ... ..... ......... .. ... ........</p>
                        <p>.. . ...... .. ... ........ ........ .. ....... .. ....... .. ... .... .. ...... ... ........ ....... .. ... .........</p>
                    </span>
                }
            </div>
            :
            <div className={classnames("col-12 noselect", isScroller ? "pointer-auto" : "")}>
                <h2>Mobile</h2>
                <div className="d-flex flex-row">
                    {short ?
                        <p>...... ........ ... ....... ... ........ . ...... ..... ........ ... ...... ... ......... .. ....... ... ... .. .... ............</p>
                        :
                        <span>
                            <p>... ..... .. ...... ..... .. ... .. .... ...... .. ...... .......... .......... ... ... .... .. ..... .. ...... ... ..... .. ... ........ . ..... .. ... ..... ......... .. ... ........</p>
                            <p>.. . ...... .. ... ........ ........ .. ....... .. ....... .. ... .... .. ...... ... ........ ....... .. ... .........</p>
                        </span>
                    }
                </div>
            </div>
    )
}
