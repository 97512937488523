import { DeviceConstants } from './device.constants';

export const device = (state = {}, action) => {

    switch (action.type) {
        case DeviceConstants.SELECTED_DEVICE:
            return { ...state, id: action.payload}
        case DeviceConstants.RESET_SELECTED_DEVICE:
            return { ...state, id: action.payload }        
        case DeviceConstants.REFRESH_PREVIEW:
                return { ...state, rnd: Date.now() }               
        default:
            return state;
    }
};
