export const IPHONE_5 = 9;
export const IPHONE_X = 1;
export const IPHONE_Y = 2;
export const IPHONE_Z = 3;
export const SAMSUNG_X = 4;
export const SAMSUNG_Y = 5;
export const SMALL_PC = 6;
export const MEDIUM_PC = 7;
export const LARGE_PC = 8;

export const SAMSUNG_NOTE_X = 10;

export const NO_DEVICE = -1;
export const NO_DEVICE_DESKTOP = -2;

export const LARGE_DEVICES = [SMALL_PC, MEDIUM_PC, LARGE_PC];

export const MobileList = [
    {
        id: IPHONE_X,
        title: 'iPhone 11, XR, XS Max ( 414 x 896 )',
        selected: true,
        viewport: {
            width: 414,
            height: 896
        },
        frameContent: {
            width: 446,
            height: 938
        }
    },
    {
        id: IPHONE_Y,
        title: 'iPhone 12 Mini, 13 Mini ( 375 x 812 )',
        selected: false,
        viewport: {
            width: 375,
            height: 812
        },
        frameContent: {
            width: 407,
            height: 854
        }
    },    
    {
        id: IPHONE_Z,
        title: 'iPhone 12 Pro Max, 13 Pro Max ( 428 x 926 )',
        selected: false,
        viewport: {
            width: 428,
            height: 926
        },
        frameContent: {
            width: 460,
            height: 968
        }
    },
    {
        id: SAMSUNG_X,
        title: 'Samsung Galaxy S20 ( 360 x 800 )',
        selected: false,
        viewport: {
            width: 360,
            height: 800
        },
        frameContent: {
            width: 370,
            height: 825
        }
    },
    {
        id: SAMSUNG_Y,
        title: 'Samsung Galaxy S21, S21 Ultra ( 384 x 854 )',
        selected: false,
        viewport: {
            width: 384,
            height: 854
        },
        frameContent: {
            width: 394,
            height: 879
        }
    },
    {
        id: SAMSUNG_NOTE_X,
        title: 'Samsung Galaxy Note 20 ( 412 x 915 )',
        selected: false,
        viewport: {
            width: 412,
            height: 915
        },
        frameContent: {
            width: 422,
            height: 940
        }
    },
    {
        id: NO_DEVICE,
        title: 'Mobile',
        selected: false,
        viewport: {
            width: 414,
            height: 896
        },
        frameContent: {
            width: 446,
            height: 938
        }
    }
];

export const DesktopList = [
    {
        id: SMALL_PC,
        title: 'PC - Small ( 1366 x 768 )',
        selected: true,
        viewport: {
            width: 1366,
            height: 768
        },
        frameContent: {
            width: 1381,
            height: 844
        }
    },
    {
        id: MEDIUM_PC,
        title: 'PC - Medium ( 1400 x 900 )',
        selected: false,
        viewport: {
            width: 1400,
            height: 900
        },
        frameContent: {
            width: 1415,
            height: 956
        }
    },
    {
        id: LARGE_PC,
        title: 'PC - Large ( 1920 x 1080 )',
        selected: false,
        viewport: {
            width: 1920,
            height: 1080
        },
        frameContent: {
            width: 1935,
            height: 1110
        }
    },
    {
        id: NO_DEVICE_DESKTOP,
        title: 'PC - No Frame ( 1000 x 1000 )',
        selected: false,
        viewport: {
            width: 1000,
            height: 1000
        },
        frameContent: {
            width: 1000,
            height: 1000
        }
    }
]

export const AllDeviceList = [...MobileList, ...DesktopList]