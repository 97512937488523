import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import App from "../components/app";

import LiveAd from "../components/live";
import PreviewAd from "../components/preview"
import PreviewShowcase from "../components/preview/showcase"
import TagPreview from "../components/live/Tag";
import Show from "../components/live/Show";

const BBRoutes = () => {
    return (                   
        
            <App>               
                <Routes>
                    <Route exact path="/live" element={<LiveAd />} /> {/*  For Ad builder live */}
                    <Route exact path="/show" element={<Show />} /> {/* For NEXD, Airtory & Primo template live */}
                    <Route exact path="/formats" element={<TagPreview />} /> {/* For Brief Big Mobile */}
                    <Route exact path="/" element={<PreviewAd />} /> {/* Preview empty ad */}    
                    <Route exact path="/c" element={<PreviewAd />} /> {/* Preview empty ad */}    
                    <Route exact path="/creative/:adid" element={<PreviewShowcase />} />{/* Preview showcase ad*/}
                    <Route exact path="/:feedwidth/creative/:adid" element={<PreviewShowcase />} />{/* Preview showcase ad*/}
                                        
                    <Route exact path="/:feedwidth/:adid" element={<PreviewAd />} /> {/* Preview ad using id*/}                                 
                    <Route exact path="/:adid" element={<PreviewAd />} /> {/* Preview ad using id*/}        
                  
            
                    <Route path="*" render={() => <Navigate to=""/>} />               
                </Routes>
                
            </App>        
            
           
    );
};

export default BBRoutes;

