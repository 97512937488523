import React, { Component } from 'react';
import PreviewContainer from '../../preview/container';
import postscribe from 'postscribe'
import { isInterstitial, isResponsive, getGeneratedPageURL, isSkin, isSwing } from '../../utilities/customFunctions'

class Show extends Component {
    constructor(props) {
        super(props)
        this.state = {
            previewTag: "",
            loading: true,
            width: 0,
            height: 0,
            isDesktop: false,
            ua: false,
            device: 0,
            isFirst: true,
            format_type: "Infeed",
            adDimension: "",
            adServer: "",
            ad_type: ""
        };
    }

    componentDidMount() {
        window.addEventListener('message', this.handleTagPreview);
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.handleTagPreview);
    }

    handleTagPreview = (event) => {
    
        if (event.data && event.data.previewTag) {
            var that = this;

            this.setState({
                previewTag: event.data.previewTag,
                loading: true,
                width: event.data.adWidth,
                height: event.data.adHeight,
                format_type: event.data.format_type,
                // eslint-disable-next-line
                isDesktop: event.data.environmentCode == "1" ? true : false,
                ua: event.data.ua,
                device: event.data.device,
                adDimension: event.data.adDimension,
                adServer: event.data.adServer,
                ad_type: event.data.ad_type
            }, () => {
                document.getElementById("bm-creative").innerHTML= "" ;
                setTimeout(function(){that.updatePreview()},100);                
            })                      
            
        }
    }

    getUA(device, ua) {
        let userAgent = '';
        if (ua) {
            // eslint-disable-next-line
            if (device == "1") {
                userAgent = ua.desktop;
            } else {
                userAgent = ua.mobile;
            }
        }
        else {
            // eslint-disable-next-line
            if (device == "1") {
                userAgent = "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_5) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/75.0.3770.100 Safari/537.36";
            } else {
                userAgent = "Mozilla/5.0 (Linux; Android 5.0; SM-G900P Build/LRX21T) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Mobile Safari/537.36";
            }
        }
        return {
            get: function () { return userAgent; }
            , configurable: true
        };
    }
    

    updatePreview = () =>{
       
        const { device, ua, previewTag, format_type, adDimension, adServer, width, height, ad_type, isDesktop} = this.state;
        const userAgentProp = this.getUA(device, ua);
 
        try {
          Object.defineProperty(window.navigator, 'userAgent', 
          userAgentProp          
            );        
        } catch (e) {
            window.navigator = Object.create(navigator, {
            userAgent: userAgentProp
          }); 
        }  
        var that = this;
        var dynTag = previewTag;
        if(dynTag){
            dynTag = dynTag.replace(/settings.json\?/g, 'settings.json?'+ Date.now())
        }
        let isSkinCreative = isSkin(ad_type);
        let isInterstitialCreative = isInterstitial(format_type);
        let isMobileSkinCreative = isSkinCreative && !isDesktop;
        let isSwingCreative = isSwing(ad_type);

        if(isResponsive(adDimension)){
            isInterstitialCreative = true;
        }
        
        if(isInterstitialCreative){
            document.getElementById("bm-creative").style.width = window.innerWidth + "px";
            document.getElementById("bm-creative").style.height = window.innerHeight + "px";
        }

        if(adServer === "airtory" && !isInterstitialCreative){ 
            dynTag = dynTag.replace('tmp=true', 'tmp=true&s=' + Date.now()) 
            let htmlCode = getGeneratedPageURL(dynTag, width === 320, adServer, isInterstitialCreative, isMobileSkinCreative, isSwingCreative);
            
            document.getElementById("bm-creative").innerHTML = (isMobileSkinCreative || isSwingCreative) ? 
            `<iframe width="${window.innerWidth}px" 
                height="${window.innerHeight}px" scrolling="yes" name="native" frameborder="0" src='${htmlCode}' >  
            </iframe> `
            :
            `<iframe width="${width}px" 
                height="${height}px" scrolling="no" frameborder="0" src='${htmlCode}' >  
            </iframe> `;            
            this.setState({loading: false});
        }
        else{
            postscribe("#bm-creative", dynTag,
            {
                done: function () {
                    that.setState({loading: false});
                } 
            });
        }        
    }

    render() {
        const { previewTag} = this.state;
        return (
            <PreviewContainer {...this.state}>
                {
                    // previewTag && <iframe   width={width} height={height} id="bm-creative" scrolling="no" frameBorder="0" srcDoc={previewTag}></iframe>              
                }
            { previewTag && <div id="bm-creative"></div> }
            </PreviewContainer>
        );
    }
}

export default Show;
