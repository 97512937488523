import React from 'react'

import Iphone from './mobile/iphone/iphone.se'
import Samsung from './mobile/android/samsung'
import Desktop from './desktop/screen'
import { IPHONE_X, IPHONE_Y, IPHONE_Z, SAMSUNG_X, SAMSUNG_Y, SAMSUNG_NOTE_X, SMALL_PC, MEDIUM_PC, LARGE_PC, NO_DEVICE, NO_DEVICE_DESKTOP } from './device-list'

import { getValueFromObject, isSkin, isScrollo, isResponsive } from '../utilities/customFunctions'
import { AllDeviceList } from './device-list'
import { connect } from 'react-redux';
import { Loader } from '../utilities/react-overlay-loader'
const DeviceSelector = (props) => {

    const { details, previewRnd, loading } = props;
    let selectedDevice = props.deviceId;
    let frameStyle = {};

    if (details) {
        if (!selectedDevice) {
            //eslint-disable-next-line
            if (details.device == "1") {
                selectedDevice = SMALL_PC;
                if (details.ad_type && (isSkin(details.ad_type) || isScrollo(details.ad_type))) {
                    selectedDevice = NO_DEVICE_DESKTOP
                }
            }
            else {
                selectedDevice = IPHONE_X
                if (details.ad_type && isScrollo(details.ad_type)) {
                    selectedDevice = NO_DEVICE;
                }
            }
        }
        if (details.dimension && isResponsive(details.dimension)) {
            selectedDevice = NO_DEVICE;
        }
    }
    else {
        selectedDevice = "default";
    }

    const DeviceInfo = selectedDevice && selectedDevice !== "default" && getValueFromObject(AllDeviceList, selectedDevice, "id");

    if (details && details.ad_type && isScrollo(details.ad_type)) {
        frameStyle.width = DeviceInfo.frameContent.width;
    }

    const renderPhone = (id) => {
        switch (id) {
            case IPHONE_Y:
            case IPHONE_Z:
            case IPHONE_X:
                return (<Iphone previewRnd={previewRnd} info={DeviceInfo}>
                    {props.children}
                </Iphone>);
            case SAMSUNG_X:
            case SAMSUNG_Y:
            case SAMSUNG_NOTE_X:
                return (<Samsung previewRnd={previewRnd} info={DeviceInfo}>
                    {props.children}
                </Samsung>);
            case SMALL_PC:
            case MEDIUM_PC:
            case LARGE_PC:
                return (<Desktop previewRnd={previewRnd} info={DeviceInfo}>
                    {props.children}
                </Desktop>);
            case NO_DEVICE:
            case NO_DEVICE_DESKTOP:
                return (
                    <div style={frameStyle}>
                        {props.children}
                    </div>
                )
            default:
                return (
                    <>
                        <Loader text="Loading..." loading={loading || (loading === undefined && details === undefined)} fullPage />
                        <Iphone previewRnd={previewRnd} info={getValueFromObject(AllDeviceList, IPHONE_X, "id")}>
                            {props.children}
                        </Iphone>
                    </>
                );
        }
    }

    return (
        <>
            {selectedDevice && renderPhone(selectedDevice)}
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        deviceId: state.device.id,
        details: state.previewAd.details,
        loading: state.previewAd.loading,

        previewRnd: state.device.rnd
    }
};

export default (connect(mapStateToProps, null)(DeviceSelector));