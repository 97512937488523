import React, { Component } from 'react';
import CloseButton from "../helpers/close-button"
import { getQueryStringParams, isScroller, getPreviewContainerWidth, isInterstitial, isSkin, isMobile, isScrollo, isResponsive, isIOS, isScrolloDesktop, isSwing } from '../utilities/customFunctions'
import { templateLists } from "./templates/helpers.js"
import styled from 'styled-components'
import classnames from 'classnames';

import { renderArticle, renderScrollDownHint } from './helpers';
import { withRouter } from '../HOC/withRouter';

const FakeText = styled.div`
    height: ${props => props.height}px;
    background-color: lightgrey;
`;

const SmallGutter = styled.div`
  margin-left: -4px !important;
  margin-right: ${props => props.marginRight || "11px"} !important;
  margin-bottom: 8px !important;
  margin-top: 8px !important;
`;

const SmallGutterCol = styled.div`
  width: ${props => props.width};
  padding-left: 4px !important;
    padding-right: 4px !important;
`;

const FakeSpace = styled.div`
  height: 1.5rem!important;
  background-color: white;
  width: 100%;
`;


class PreviewContainer extends Component {

  constructor(props) {
    super(props);

    let pos = "center";
    let focusAd = false;
    let isDesktop = false;
    let formatName = "";
    let isBuddy = false;
    let scaleDesktop = false;
    let template = "";
    let templateId = "";
    let searchParams = getQueryStringParams(this.props.location.search);

    if (searchParams && searchParams.isBuddy) {
      isBuddy = searchParams.isBuddy;
    }

    if (searchParams && searchParams.pos) {
      pos = searchParams.pos;
    }

    if (searchParams && searchParams.focusAd) {
      focusAd = searchParams.focusAd
    }

    if (searchParams && searchParams.desktop) {
      isDesktop = searchParams.desktop || true
    }

    if (searchParams && searchParams.format) {
      formatName = searchParams.format
    }

    if (searchParams && searchParams.scaleDesktop) {
      scaleDesktop = searchParams.scaleDesktop
    }

    if (searchParams && searchParams.siteId) {
      templateId = searchParams.siteId
      if (templateId && templateLists[templateLists.findIndex(x => x.id === templateId)]) {
        template = templateLists[templateLists.findIndex(x => x.id === templateId)]
      }
    }

    this.state = {
      pos: pos,
      focusAd: focusAd,
      isDesktopParam: isDesktop,
      formatName: formatName,
      isBuddy: isBuddy,
      scaleDesktop: scaleDesktop,
      template: template,
      templateId: templateId
    }

  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps) {
    //eslint-disable-next-line
    if (this.props != prevProps) {
      this.init();
    }

    /* if (this.state.focusAd) {
      if (!this.props.loading && this.props.loading !== undefined && !this.props.isLiveTagReady) {
        this.scrollViewToAd();
      }
    } */
  }

  init() {
    const { formatName, focusAd } = this.state

    let isSkinCreative = isSkin(formatName, this.props.ad_type);
    let isSwingCreative = isSwing(this.props.ad_type)
    /* if (isDesktopParam) {
      if(isSkinCreative){
        document.body.classList.remove("desktop");
      }
      else{
        document.body.classList.add("desktop");
      }
    } */

    if (focusAd && !isSkinCreative && !isSwingCreative) {
      if (!this.props.loading && this.props.loading !== undefined && !this.props.isLiveTagReady) {
        this.scrollViewToAd();
      }
    }
  }


  scrollViewToAd = () => {

    const id = "bm-creative-container";
    //const offset = 100;
    const $anchor = document.getElementById(id);
    const offsetTop = $anchor.getBoundingClientRect().top + document.getElementsByClassName("bm-creative-preview")[0].scrollTop;

    //else{    
    const { format_type, height } = this.props;
    let creativeHeight = $anchor.offsetHeight || height;
    let scrollToNumber = creativeHeight / 2;
    if (creativeHeight) {
      scrollToNumber = Math.abs(((570 - (creativeHeight)) / 2) - 10);
    }

    let isScrollerCreative = isScroller(format_type);
    if (!isScrollerCreative) {
      setTimeout(function () {
        document.getElementsByClassName("bm-creative-preview")[0].scroll({
          top: offsetTop - scrollToNumber,
          behavior: 'smooth'
        })
      }, 300);
    }
    //}  
  }

  renderAd = (isMobile = true, width = 0, isSkinCreative = false) => {
    return (
      isMobile ?
        <div id="bm-creative-container" className="bm-ad col-12">
          {this.props.children}
        </div>
        :
        <>

          <div id="bm-creative-container" style={{ width: width ? width + "px" : "100%" }} className={classnames("bm-ad-desktop mx-auto", width && width > 320 ? "mb-4" : "")}>
            {!isSkinCreative && this.props.children}
          </div>
        </>
    )
  }

  renderSmallGutter = (fakeTextHeight = 16, widths = [21, 39, 40], marginRight = "11px") => {
    return (
      <SmallGutter className="row" marginRight={marginRight}>
        <SmallGutterCol width={widths[0] + "%"}>
          <FakeText height={fakeTextHeight} />
        </SmallGutterCol>
        <SmallGutterCol width={widths[1] + "%"}>
          <FakeText height={fakeTextHeight} />
        </SmallGutterCol>
        <SmallGutterCol width={widths[2] + "%"}>
          <FakeText height={fakeTextHeight} />
        </SmallGutterCol>
      </SmallGutter>
    )
  }

  renderBlock = (className = "", thCl = "", marginRight = "") => {
    return (
      <div className={`${className} p-0`}>
        <div className="row m-0">
          <div className={`col-12 w-100 pl-0 ${thCl}`}>
            <FakeText height="180" />
          </div>
        </div>
        {this.renderSmallGutter(16, [20, 31, 49], marginRight)}
        {this.renderSmallGutter(16, [30, 39, 31], marginRight)}
        {this.renderSmallGutter(16, [40, 31, 29], marginRight)}
      </div>
    )
  }

  renderDesktop = (oldPos, width = 0, height = 0, isSkinCreative = false, isScrolloCreative = false) => {
    const {template} = this.state;
    let pos = oldPos;
    if (width && width > 320) {
      pos = "top";
    }

    let adPos = pos;
    if (adPos.match(/^(top|above)$/ig)) {
      adPos = "top";
    }
    else if (adPos.match(/^(bottom|below)$/ig)) {
      adPos = "bottom";
    }
    else {
      adPos = "center"
    }

    return (
      <>
      {template ? <>{template.getDesktopPreview(adPos, width, height, isSkinCreative, isScrolloCreative, this.renderAd)}</> :

      <React.Fragment>
        <div id="bm-desktop-container" className="bm-creative-preview-ad-content bm-desktop-container row my-4">
          {adPos === "top" && this.renderAd(false, width, isSkinCreative)}
          {adPos !== "top"
            ? <>
              <div className="row noselect w-100 mb-4">
                <div className="col-12 noselect p-0">
                  <FakeText height="180" />
                </div>
              </div>
              {isScrolloCreative && renderScrollDownHint(isScrolloCreative, true)}
              {(isScrolloCreative) &&
                <div className="row noselect w-100 mb-4">
                  {this.renderBlock("col-4")}
                  {this.renderBlock("col-4")}
                  {this.renderBlock("col-4", "pr-0", "-4px")}
                </div>
              }
              {(isScrolloCreative) &&
                <div className="row noselect w-100 mb-4">
                  {this.renderBlock("col-4")}
                  {this.renderBlock("col-4")}
                  {this.renderBlock("col-4", "pr-0", "-4px")}
                </div>
              }
              <div className="row noselect w-100 mb-4">
                {height > 300 ?
                  <div className="d-flex flex-column flex-grow-1">
                    {this.renderBlock("flex-grow-1")}
                    {this.renderBlock("flex-grow-1")}
                  </div>
                  : <>
                    {this.renderBlock("flex-grow-1")}
                    {this.renderBlock("flex-grow-1")}
                  </>
                }
                {adPos === "center" && this.renderAd(false, width, isSkinCreative)}
              </div>
            </>
            :
            <>
              <div className="row noselect w-100 mb-4">
                {this.renderBlock("col-6")}
                {this.renderBlock("col-6", "pr-0", "-4px")}
              </div>
            </>
          }
          <div className="row noselect w-100 mb-4">
            {this.renderBlock("col-4")}
            {this.renderBlock("col-4")}
            {this.renderBlock("col-4", "pr-0", "-4px")}
          </div>
          <div className="row noselect w-100 mb-4">
            {this.renderBlock("col-4")}
            {this.renderBlock("col-4")}
            {this.renderBlock("col-4", "pr-0", "-4px")}
          </div>

          {(isSkinCreative || isScrolloCreative) &&
            <div className="row noselect w-100 mb-4">
              {this.renderBlock("col-4")}
              {this.renderBlock("col-4")}
              {this.renderBlock("col-4", "pr-0", "-4px")}
            </div>
          }

          {adPos === "bottom" && this.renderAd(false, width, isSkinCreative)}

        </div>
      </React.Fragment>
      } </>)
  }

  renderMobile = (pos, isInterstitialCreative = false, isScrolloCreative = false, isScrolloDesktopCreative = false, isMobileSkinCreative = false) => {
    const { template } = this.state;
    const {height} = this.props;
    return (<>
      {template ? <>
          {template.getMobilePreview(pos, isInterstitialCreative, isScrolloCreative, isScrolloDesktopCreative, isMobileSkinCreative, this.renderAd, height)}
        </> :
        <React.Fragment>
          {pos.match(/^(top|above)$/ig) && !isInterstitialCreative ? this.renderAd() : null}
          <div className={classnames("bm-creative-preview-ad-content", isScrolloCreative ? "position-relative" : "position-relative", isScrolloDesktopCreative ? "ad-content-scrollo-desktop" : "")}>
            {isScrolloCreative && renderArticle()}
            {isScrolloCreative || window.innerHeight > 650 ?
              <>
                {renderArticle()}
                {renderArticle()}
                {!isInterstitialCreative ? renderScrollDownHint(true) : null}
              </>
              :
              null
            }

            {renderArticle()}
            {pos.match(/^(center|middle)$/ig) && !isInterstitialCreative ? this.renderAd() : null}
            {renderArticle(false)}
            {pos.match(/^(bottom|below)$/ig) && !isInterstitialCreative ? this.renderAd() : null}
            {(isScrolloCreative || isMobileSkinCreative || window.innerHeight > 650) && renderArticle(false)}
            {(isScrolloCreative || isMobileSkinCreative || window.innerHeight > 650) && renderArticle(false)}
            {/* <div id="adhesive-content"></div> */}
          </div>
        </React.Fragment>
      }</>
    )
  }

  renderScrollerView = (isDesktop, width, height) => {
    const {template} = this.state
    var browserWidth = isDesktop ? (Number(width) + 0) : getPreviewContainerWidth(width); //removed 30
    var dynHeight = Math.floor((browserWidth * height) / width);

    return (
      <>
      {template ? <>{template.getScrollerPreview(isDesktop, browserWidth, dynHeight)}</> :

      isDesktop ?
        (
          <>
            <FakeSpace />
            <div className="bm-creative-preview-ad-content bm-desktop-container row pointer-none">
              <div className="row noselect w-100 pointer-auto">
                <div className="col-12 noselect p-0">
                  <FakeText height="180" />
                </div>
              </div>
              <FakeSpace />
              <div className="row noselect w-100 pointer-auto">
                {this.renderBlock("flex-grow-1")}
                {this.renderBlock("flex-grow-1", "pr-0")}

              </div>
              <FakeSpace />
              <div style={{ width: browserWidth + "px", height: dynHeight + "px" }}> </div>
              <div className="row noselect w-100 pointer-auto">
                {this.renderBlock("col-4")}
                {this.renderBlock("col-4")}
                {this.renderBlock("col-4", "pr-0", "-4px")}
              </div>
              <FakeSpace />
              <div className="row noselect w-100 pointer-auto">
                {this.renderBlock("col-4")}
                {this.renderBlock("col-4")}
                {this.renderBlock("col-4", "pr-0", "-4px")}
              </div>
              <FakeSpace />
              <div className="row noselect w-100 pointer-auto">
                {this.renderBlock("col-4")}
                {this.renderBlock("col-4")}
                {this.renderBlock("col-4", "pr-0", "-4px")}
              </div>
              <FakeSpace />
            </div>
          </>
        )
        : (
          <div className="bm-creative-preview-ad-content">
            {renderArticle(false, false, true)}
            {renderScrollDownHint()}
            {renderArticle(false, false, true)}
            <div style={{ width: browserWidth + "px", height: dynHeight + "px" }}> </div>
            {renderArticle(false, false, true)}
            {renderArticle(false, false, true)}
          </div>
        )}
        </>) 
  }

  changeTitleBar = () => {

    const { brand_info } = this.props;
    if (brand_info) {
      let accountName = brand_info.name;

      let link = "/favicon.ico";

      if (brand_info.favicon_image) {
        link = brand_info.favicon_image;
        document.title = "Preview - " + accountName;
      }

      let favicon = document.getElementById("bb-preview-favicon")
      if (favicon !== null) {
        favicon.href = link
      } else {
        favicon = document.createElement("link")
        favicon.rel = "shortcut icon";
        favicon.id = "bb-preview-favicon";
        favicon.href = link;
        document.head.appendChild(favicon);
      }

      //document.title = "Preview - " + accountName;
    }

  }
  render() {

    const { pos, isDesktopParam, formatName, isBuddy, scaleDesktop, template } = this.state;
    const { isDesktop, width, height, brand_info, format_type, ad_type, adDimension = "", dimension = "", ad_server } = this.props;
    let desktop = isDesktopParam;
    if (isDesktop) {
      desktop = isDesktop;
    }

    this.changeTitleBar();
    let isScrollerCreative = isScroller(format_type);
    let isInterstitialCreative = isInterstitial(format_type);
    let isSkinCreative = isSkin(formatName, ad_type);
    let isScrolloCreative = isScrollo(ad_type);
    let isSwingCreative = isSwing(ad_type)
    let hasCloseAd = ad_server === "primo" && isInterstitial(format_type) ? true : false
    let isMobileSkinCreative = isSkinCreative && !desktop;
    if (isMobileSkinCreative || isSwingCreative) {
      isInterstitialCreative = true;
      isBuddy && document.body.style.setProperty("overflow", "hidden", "important");
    }

    if(isScrollerCreative){
      if (isBuddy) document.documentElement.classList.add("overflow-auto");
    }

    let isResponsiveCreative = isResponsive(adDimension) || isResponsive(dimension);
    if (isResponsiveCreative) {
      let bmPreview = document.getElementById("bm-creative-preview");
      if (bmPreview) {
        bmPreview.style.display = "none";
        //!isResponsivePreview && !isMobile() && document.body.style.setProperty("overflow","hidden", "important");
      }
    }
    let isScrolloDesktopCreative = isScrolloDesktop(desktop, ad_type)

    let iOS = isIOS();

    return (
      // div with position: absolute; z-index:1; top: 0;
      <>
        {
          (isMobile() && desktop && window.screen.width < 480) ?
            <>
              <div className="bb-bg bb-view-in-desktop d-flex justify-content-center flex-column mx-auto">
                <img className="my-3" src="/images/icons/desktop-logo.svg" alt="" />
                <h1 className="mb-3 mx-3 text-center">MOBILE VIEW FOR THIS DESKTOP AD IS UNAVAILABLE</h1>
                <h4 className="mb-5 mx-5 text-center px-3">Please view on a desktop device</h4>
              </div>
            </>
            :
            <>
              {hasCloseAd ? <CloseButton /> : null}
              {isScrollerCreative && <div id="bm-creative-container" className="bm-ad position-fixed">
                {this.props.children}
              </div>
              }
              {isInterstitialCreative && <div id="bm-creative-container" className={classnames("bm-ad", isMobileSkinCreative || isSwingCreative ? "" : "position-fixed")} style={{ zIndex: 1 }}>
                {this.props.children}
              </div>
              }
              {isResponsiveCreative && <div id="bm-creative-container" className="bm-ad" style={{ zIndex: 1 }}>
                {this.props.children}
              </div>
              }
              {(isSkinCreative && desktop) && <div className="bb-truskin bb-truskin-fake-header"></div>}
              <div id="bm-creative-preview"
                className={classnames("bm-creative-preview", isSwingCreative ? "d-none" : "", isMobileSkinCreative ? "position-relative bb-mobile-skin" : "",
                  isScrollerCreative
                    ?
                    iOS ? "position-static bm-creative-preview-scroller pointer-none" : "bm-creative-preview-scroller pointer-none"
                    : "",
                  (isSkinCreative && desktop) ? "bb-truskin-container" : "",
                  isScrolloCreative ? "bm-creative-preview-scrollo" : ""
                )} >
                {template ? <> {null} </> :
                  <div className={classnames("bm-creative-preview-header noselect pointer-auto", iOS && isScrollerCreative ? "position-relative" : "")}
                    style={{ background: (brand_info && brand_info.primaryColor) ? brand_info.primaryColor : "#1b2134" }}>
                    <img src={(brand_info && brand_info.logo) ? brand_info.logo : "/images/logo_demo_preview.png"} alt="logo" className="img-fluid img-responsive preview-logo" />
                  </div>
                }
                {
                  isScrollerCreative ? this.renderScrollerView(desktop, width, height) : (
                    desktop ? ((isBuddy && isScrolloDesktopCreative) || scaleDesktop ? this.renderMobile(pos, isInterstitialCreative, isScrolloCreative, isScrolloDesktopCreative || scaleDesktop, isMobileSkinCreative)
                      : this.renderDesktop(pos, width, height, isSkinCreative, isScrolloCreative))
                      : this.renderMobile(pos, isInterstitialCreative, isScrolloCreative, scaleDesktop, isMobileSkinCreative)
                  )
                }
              </div>
              {(isSkinCreative && desktop) &&
                <div className="bb-truskin bb-truskin-viewability-container">
                  <div className="bb-truskin-desktop-container">
                    <div style={{ "height": "0px", "width": "0px" }}>
                      <div style={{ "height": "100%", width: "100%", position: "absolute" }}>
                        {this.props.children}
                      </div>
                    </div>
                  </div>
                </div>
              }
            </>
        } {/* End of Mobile and desktop check */}
      </>
    );
  }
}

export default withRouter(PreviewContainer);
