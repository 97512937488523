import * as React from "react";

const styles = {
  text: {
    marginTop: 160,
    color: "#fff",
    marginLeft: 58
  },
  spinner: {
    backgroundColor: "rgba(28, 33, 53, 1)",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    zIndex: 1000,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
};

const Loader = ({
  loading,
  // eslint-disable-next-line
  text,
  fullPage,
  containerStyle,
  textStyle
}) => {
  if (typeof loading !== "boolean") {
    return null;
  }

  if (!loading) {
    return null;
  }
  const mergedContainerStyle = {
    ...styles.spinner,
    ...containerStyle,
    position: fullPage ? "fixed" : "absolute"
  };
  return (
    <div style={mergedContainerStyle}>
      {/* <div className="react-overlay-loader-spinner" /> */}
      <div className="bb-loader">
        <div className="bb-loader__bar"></div>
        <div className="bb-loader__bar"></div>
        <div className="bb-loader__bar"></div>
        <div className="bb-loader__bar"></div>
        <div className="bb-loader__bar"></div>
        <div className="bb-loader__ball"></div>
      </div>
      <span style={{ ...styles.text, ...textStyle }}>{text}</span>
    </div>
  );
};

export default Loader;
