import React, { useEffect } from 'react';
import PreviewContainer from './container';
import Swipe from './swipe';
import Streamview from './streamview';
import Still from './still';
import Scratch from './scratch';
import TruSkinDesktopClassic from './truskin/desktop/classic'

import NexdPreviewContainer from './nexd/container';
import { isResponsive } from '../utilities/customFunctions'

import { connect } from 'react-redux';

import * as previewActions from '../../redux/preview/preview.actions'
//import TagPreview from './tagPreview';
import { LARGE_DEVICES } from '../devices/device-list'
import { saveDeviceSelected } from '../../redux/device/device.actions'
import { useParams, useLocation } from "react-router"

function PreviewAd(props) {
  let { adid } = useParams()
  const location = useLocation();
  useEffect(() => {
    
    if (adid) {
      let c = location.pathname.includes("/c/") ? true : false
      props.getAdDetails(adid, c);
    }
    else {
      props.getAdDetails("empty");
    }

  }, [adid])



  function getFormat(details) {
    if (details.format) {
      return details.format.toLowerCase();
    }
    else if (details.ad_type) {
      return details.ad_type.toLowerCase();
    }
    else {
      return "";
    }
  }

  function renderPreview(details, isDesktop, isResponsivePreview) {
    let format = getFormat(details);

    if (format === "truskin classic" && isDesktop && !details.briefId) {
      return <TruSkinDesktopClassic {...props} isDesktop={isDesktop} />
    }
    else if (details.ad_for === "nexd" || (details.scripts && details.scripts.secureScript)) {
      return <NexdPreviewContainer {...props} isDesktop={isDesktop} isResponsivePreview={isResponsivePreview} />
    }
    else if (format === "swipe" || format === "cube" || format === "carousel" || format === "limelight") {
      return <Swipe {...props} isDesktop={isDesktop} />
    }
    else if (format === "streamview" || format === "vertical video" || format === "video" || format === "cinematics") {
      return <Streamview {...props} isDesktop={isDesktop} />
    }
    else if (format === "still" || format === "image" || format === "mrec" || format === "banner" || format === "standard iab" || format === "static") {
      return <Still {...props} isDesktop={isDesktop} />
    }
    else if (format === "scratch" || format.indexOf("scratch") > -1) {
      return <Scratch {...props} isDesktop={isDesktop} />
    }
    else {
      return null
    }
  }



  const { details, loading, deviceId } = props;
  let isDesktop = false;
  let isLiveTagReady = false;
  let isResponsivePreview = false;

  if (details) {
    // eslint-disable-next-line
    isDesktop = (details.device == "1" || LARGE_DEVICES.includes(deviceId));
    isLiveTagReady = details.ad_for === "nexd" || (details.scripts && details.scripts.secureScript);
    isResponsivePreview = details.dimension && isResponsive(details.dimension) && document.getElementById("bb-preview-device-container");
    // eslint-disable-next-line
  }

  return (
    <PreviewContainer isDesktop={isDesktop} loading={loading} {...details}
      isResponsivePreview={isResponsivePreview}
      isLiveTagReady={!loading && isLiveTagReady}>
      {details &&
        renderPreview(details, isDesktop, isResponsivePreview)
      }
    </PreviewContainer>
  );
}


const mapStateToProps = (state) => {
  return {
    details: state.previewAd.details,
    loading: state.previewAd.loading,
    deviceId: state.device.id
  }
};

const mapDispatchToProps = {
  ...previewActions,
  saveDeviceSelected
};

export default (connect(mapStateToProps, mapDispatchToProps)(PreviewAd));
