import React from 'react'
import styled from 'styled-components'
import Popup from '../../utilities/popup-menu'
import { QRCode } from 'react-qrcode-logo';

const Icon = styled.img`
    width: 24px;
    height: auto;
    align-self: right;    
    margin-left: 10px;
    cursor: pointer;
`;

const PreviewQRWrapper = styled.div`
    width: 190px;
    background: #000000;
    padding: 10px;
    border-radius: 5px;
`;

const PreviewTitle = styled.p`
    font-size: 14px;
    padding: 0;
    margin-bottom: 5px;
    color: white;
    text-align: center;
`;
const QrCodeIcon = (props) => {
    return (

        <Popup
            trigger={<Icon src="/images/icons/qr-icon.png" />}
            position="bottom left"
            on={["click"]}
            closeOnEscape={true}
            closeOnDocumentClick
            mouseLeaveDelay={300}
            mouseEnterDelay={0}
            contentStyle={{ borderRadius: "10px", width: "180px", padding: "0px", border: "none" }}
            arrow={true}
            keepTooltipInside={true}
            overlayStyle={{ position: "absolute" }}
        >
            <PreviewQRWrapper>
                <PreviewTitle>View on your device</PreviewTitle>
                <QRCode bgColor="whitesmoke" fgColor="#1b2134" qrStyle="dots" quietZone="10" ecLevel="L"
                    //logoImage="/images/bm-logo.png" 
                    logoWidth="60" logoHeight="44" logoOpacity="0.5" value={props.url} />
            </PreviewQRWrapper>
        </Popup>
    )
}

export default QrCodeIcon