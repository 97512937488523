import { combineReducers } from 'redux';
import { previewAd } from './preview/preview.reducer'
import { device } from './device/device.reducer'
import { benchmark } from './benchmark/benchmark.reducer'

const rootReducer = combineReducers({
    previewAd,
    device,
    benchmark
});

export default rootReducer;

