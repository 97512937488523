import { PreviewConstants } from './preview.constants';

export const previewAd = (state = {}, action) => {

    switch (action.type) {
        case PreviewConstants.AD_FORMAT:
            return { ...state, format: action.payload}
        case PreviewConstants.AD_SIZE:
            return { ...state, size: action.payload }        
        
        case PreviewConstants.AD_DETAILS:
            return { ...state, details: action.payload }
            
        case PreviewConstants.AD_ERRORS:
            return { ...state, errors: action.payload }

        case PreviewConstants.AD_LOADING_STATUS:
            return { ...state, loading: action.payload }
        
        case PreviewConstants.NEXD_DETAILS:
            return { ...state, nexd: action.payload }
        case PreviewConstants.NEXD_DETAILS_ERROR:
                return { ...state, nexd: {"success": "false"} }

        case PreviewConstants.AIRTORY_TAG_DETAILS:
            return { ...state, atag: action.payload }
        case PreviewConstants.AIRTORY_TAG_DETAILS_ERROR:
                return { ...state, atag: {"success": "false"} }
            
        default:
            return state;
    }
};
