import React, { useEffect } from 'react';
import PreviewContainer from '../container';

import { connect } from 'react-redux';

import { getShowcaseAdDetails } from '../../../redux/preview/preview.actions'
import { LARGE_DEVICES } from '../../devices/device-list'
import { saveDeviceSelected } from '../../../redux/device/device.actions'
import NexdPreview from '../nexd'
import { isResponsive, getAdServerUsingScript } from '../../utilities/customFunctions';
import { useParams } from 'react-router';

function PreviewShowcaseAd(props) {

  let { adid } = useParams()
  const { details, loading, deviceId, getShowcaseAdDetails } = props;
  useEffect(() => {
    adid && getShowcaseAdDetails(adid);
    //eslint-disable-next-line
  }, [adid])
  

  function renderPreview(details, isDesktop, isResponsivePreview) {
    if ((details.scripts && details.scripts.secureScript)) {
      return <NexdPreview {...props} isDesktop={isDesktop} isResponsivePreview={isResponsivePreview} isShowcasePreview={true} />
    }
    else {
      return null
    }
  }

  
    
    let isDesktop = false;
    let isLiveTagReady = false;
    let isResponsivePreview = false;
    let ad_server = false

    if (details) {
      // eslint-disable-next-line
      isDesktop = (details.device == "1" || LARGE_DEVICES.includes(deviceId));
      isLiveTagReady = (details.scripts && details.scripts.secureScript);
      isResponsivePreview = details.dimension && isResponsive(details.dimension) && document.getElementById("bb-preview-device-container");
      ad_server = getAdServerUsingScript(details.scripts && details.scripts.secureScript)
    }
    return (
      <PreviewContainer isDesktop={isDesktop} loading={loading} {...details}
        isResponsivePreview={isResponsivePreview}
        ad_server={ad_server}
        isLiveTagReady={!loading && isLiveTagReady}>
        {details &&
          renderPreview(details, isDesktop, isResponsivePreview)
        }
      </PreviewContainer>
    );
}

const mapStateToProps = (state) => {
  return {
    details: state.previewAd.details,
    loading: state.previewAd.loading,
    deviceId: state.device.id
  }
};

const mapDispatchToProps = {
  getShowcaseAdDetails,
  saveDeviceSelected
};

export default (connect(mapStateToProps, mapDispatchToProps)(PreviewShowcaseAd));
