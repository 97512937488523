import { store } from '../../redux/store'
import {config} from '../../helpers/config'
import { renderArticle, renderScrollDownHint } from '../preview/helpers'; 
import ReactElementToJSXString from 'react-element-to-jsx-string';

export const getMobileSkintyles = `
@font-face {
  font-family: "CenturyGothic";
  src: url("${config.host_bb}/fonts/CenturyGothic.ttf") format("truetype");
}
@font-face {
  font-family: "BLOKK";
  src: url("${config.host_bb}/fonts/BLOKKNeue-Regular/BLOKKNeue-Regular.eot");
  src: url("${config.host_bb}/fonts/BLOKKNeue-Regular/BLOKKNeue-Regular.eot?#iefix") format("embedded-opentype"), 
       url("${config.host_bb}/fonts/BLOKKNeue-Regular/BLOKKNeue-Regular.woff") format("woff"), 
       url("${config.host_bb}/fonts/BLOKKNeue-Regular/BLOKKNeue-Regular.otf") format("opentype"), 
       url("${config.host_bb}/fonts/BLOKKNeue-Regular/BLOKKNeue-Regular.ttf") format("truetype"), 
       url("${config.host_bb}/fonts/BLOKKNeue-Regular/BLOKKNeue-Regular.svg#BLOKKRegular") format("svg");
  font-weight: normal;
  font-style: normal;
}

.bm-creative-preview {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 22px;
  font-family: "BLOKK", serif;
  background: #fff;
}
.bm-creative-preview-header {
  background: #1b2134;
  text-align: center;
}
.bm-creative-preview-header img {
  max-width: 100%;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  width: 130px;
  padding: 20px 0;
}
  .noselect {
    user-select: none;
    background: white;
  }

  .bm-creative-preview-ad-content .placeholder {
    background: lightgrey;
    max-width: 150px;
    min-width: 150px;
    margin-right: 10px;
    height: 150px;
  }
  .bm-creative-preview-ad-content .placeholder:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
  @media only screen and (max-height: 480px) and (orientation: landscape) {
    .bm-creative-preview-ad-content .placeholder {
      height: 100px;
    }
  }

  .fa-pull-left {float:left}
  .col-12 {position:relative;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%; margin-right: 15px; margin-left: 15px;}

  .bm-creative-preview .bm-ad {
    font-family: "CenturyGothic";
    letter-spacing: normal;
    clear: both;
    margin: 10px 0px;
    padding: 0 !important;
    overflow: hidden;
  }

  .bm-creative-preview p {
    font-size: 22px;
    color: lightgray;
    user-select: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
    margin-bottom: 0px !important;
    margin-top: 0;
    letter-spacing: -0.1em;
    line-height: 1.2em;
    word-break: break-all;
  }
  .bm-creative-preview h2 {
    font-size:2rem;
    color: gray;
    user-select: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
    margin-top: 0;
  }

  
  body::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    box-shadow: inset 0 0 100px #2E3547;
    margin-right: 20px;
  }
  body::-webkit-scrollbar-thumb {
    height: 1em;
    border: 0.1em solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 0.5;
    background-color: rgba(202, 203, 211, 0.20);
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.025);
  }
  body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.025);
    padding: 10px;
  }

  
.scroller-hint-scrollo {
  top: 255px !important;
  z-index: 99 !important;
}

.scroller-hint-scrollo-desktop {
  left: unset !important;
  z-index: 99 !important;
  max-width: 500px;
}

#scroller-hint {
  pointer-events: none;
  font-family: "CenturyGothic";
  position: absolute;
  left: 0;
  width: calc(100% - 10px);
  z-index: 99999;
  height: 50vh;
  text-align: center;
  letter-spacing: 0.5px;
  top: 50%;
  margin: 5px;
}
#scroller-hint .hint-text {
  background: gray;
  border-radius: 5px;
  padding: 7px 0;
  color: white;
  font-size: 16px;
}
#scroller-hint .hint-text img {
  width: 10px;
  height: 20px;
  margin: 0 30px;
  align-self: center;
}
#scroller-hint .hint-gesture {
  width: 70px;
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, 40%);
  animation: scroll-hint 1.8s infinite;
  transition-timing-function: ease-in-out;
}

@keyframes scroll-hint {
  0% {
    transform: translate(-50%, 40%);
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  40% {
    opacity: 0.8;
  }
  80% {
    transform: translate(-50%, -100%);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -100%);
    opacity: 0;
  }
}

.align-self-center{ align-self: center!important;}
.d-flex:{ display: flex!important;}
.justify-content-center:{justify-content; center!important;}
.flex-row{flex-direction: row!important;}
`;

export const getMobileSkinArticle = (src = "") => {
  let details = store.getState().previewAd && store.getState().previewAd.details;
  let brand_info = details && details.brand_info;

  const divHeader = `<div class="bm-creative-preview-header noselect"
style=' background: ${(brand_info && brand_info.primaryColor) ? brand_info.primaryColor : "#1b2134"}'>
<img src='${(brand_info && brand_info.logo) ? brand_info.logo : config.host_bb+"/images/logo_demo_preview.png"}' alt="logo" class="img-fluid img-responsive preview-logo" />
</div>`

const ShortArticle = ReactElementToJSXString(renderArticle()).replace(/classname/ig,"class")
const LongArticle = ReactElementToJSXString(renderArticle(false)).replace(/classname/ig,"class")
const ScrollHint  = ReactElementToJSXString(renderScrollDownHint(true)).replace(/classname/ig,"class")
  return (
      `
      <div 
      class="bm-creative-preview">
      ${divHeader} 
      <div class="bm-creative-preview-ad-content">
        ${ShortArticle}
        ${ShortArticle}   
        ${src ? ScrollHint : ""}   
        ${ShortArticle}
        <div id="bm-creative-container" class="bm-ad col-12">
          <div id="bm-preview-iframe-container" class="mx-auto"> ${src} </div>
        </div>           
        ${LongArticle} 
        ${LongArticle}
        ${src ? LongArticle : ""}
      </div> 
    </div>`
  )
}

