
import React from 'react'
import classnames from 'classnames'
import ReactElementToJSXString from 'react-element-to-jsx-string';
import './index.scss'

const Content = [
    {
        id: 1,
        header: `Waiter/ess Floor staff`,
        image: `/images/template/gumtree/gumtree-1.png`,
        desciption: `East Lindfield`,
    },
    {
        id: 2,
        header: `Clinic room available`,
        image: `/images/template/gumtree/gumtree-2.png`,
        desciption: `Ivanhoe`,
    },
    {
        id: 3,
        header: `Apple Macbook Air 13 Inch`,
        image: `/images/template/gumtree/gumtree-3.png`,
        desciption: `Perth`,
    },
    {
        id: 4,
        header: `Hume Transport Services`,
        image: `/images/template/gumtree/gumtree-4.png`,
        desciption: `Sunbury`,
    },
    {
        id: 5,
        header: `Bathroom Kitchen & Laundry Renovations`,
        image: `/images/template/gumtree/gumtree-5.png`,
        desciption: `Sydney`,
    },
    {
        id: 6,
        header: `Wheelchair InvaCare REA Clematis Pro`,
        image: `/images/template/gumtree/gumtree-6.png`,
        desciption: `Hawthorn`,
        price: "1500"
    },
    {
        id: 7,
        header: `Licensed & Insured painter`,
        image: `/images/template/gumtree/gumtree-7.png`,
        desciption: `Penrith`,
    },
    {
        id: 8,
        header: `Kanga Hire $220 per day`,
        image: `/images/template/gumtree/gumtree-8.png`,
        desciption: `Mandurah`,
    }
]
const renderListings = (endIndex, desktop = false, startIndex = 0) => {
    let rows = [];
    for (let i = startIndex; i < endIndex; i++) {
        rows.push(renderListing(Content[i], desktop));
    }
    return rows;
}
const renderListing = (content, desktop = false) => {
    return (
        <div className={classnames('listing', desktop ? "col-3 desktop-listing" : "col-6")} key={content.id}>
            <div className="card template-listing">
            <div className="listing-header">
                <div><img src={content.image} alt="gumtree" /></div>
            </div>
            <div className="listing-body">
                <h6 className="text-truncate">{content.header}</h6>
                <div className="d-flex justify-content-between">
                    {content.desciption}
                    <img alt="" className="love-icon mt-1" src="/images/template/gumtree/love-icon.png"/>
                </div>
            </div>
            </div>
        </div>
    )
}

const gumtreeHeader = (desktop = false) => {
    return (
        <div>
            {desktop ? 
                <div>
                    <div className="w-100 bg-gumtree-main text-center">
                        <img className="template-image gumtree-desktop-width" src="/images/template/gumtree/header-desktop-1.png" alt="gumtree" />
                    </div>
                    <div className="w-100 bg-gumtree-dark text-center">
                        <img className="template-image image-header" src="/images/template/gumtree/header-desktop-2.png" alt="gumtree" />
                    </div>
                </div> : 
                <img className="template-image" src="/images/template/gumtree/gumtree-header.png" alt="gumtree" />
            }
        </div>
    )
}

const gumtreeFooter = (desktop = false) => {
    return (
        <div className="mt-5">
            {desktop ? <div>
                    <div className="w-100 bg-gumtree-main text-center">
                        <img className="template-image gumtree-desktop-width" src="/images/template/gumtree/footer-desktop-1.png" alt="gumtree" />
                    </div>
                    <div className="w-100 bg-gumtree-secondary text-center">
                        <img className="template-image gumtree-desktop-width" src="/images/template/gumtree/footer-desktop-2.png" alt="gumtree" />
                    </div>
                    <div className="w-100 bg-gumtree-main text-center">
                        <img className="template-image gumtree-desktop-width" src="/images/template/gumtree/footer-desktop-3.png" alt="gumtree" />
                    </div>
                </div>
                :
                <div>
                    <img className="template-image" src="/images/template/gumtree/gumtree-footer.png" alt="gumtree" />
                    <img className="template-image" src="/images/template/gumtree/gumtree-footer-2.png" alt="gumtree" />
                </div>
            }
        </div>
    )
}

export const gumtreeMobile = (pos, isInterstitialCreative = false, isScrolloCreative = false, isScrolloDesktopCreative = false, isMobileSkinCreative = false, renderAd, height) => {
    let bottom = height && height >= 150
    return (
        <>
            <div className={classnames("bm-creative-preview-ad-content gumtree position-relative", isScrolloDesktopCreative ? "ad-content-scrollo-desktop" : "")}>
                {gumtreeHeader()}
                <div className="gumtree-button main font-weight-bold m-2 py-2 mb-4">
                    Post an ad
                </div>

                {!bottom && pos.match(/^(center|middle)$/ig) && !isInterstitialCreative ? renderAd() : null}
                
                <img className='mb-2 mt-1' src="/images/template/gumtree/subnav.png" alt="gumtree" />

                {renderListings(4)}
                
                <div className="gumtree-button font-weight-bold mx-2 mt-3 py-2 mb-5">
                    See all
                </div>

                {bottom && !isInterstitialCreative ? <div>
                    {renderAd()}

                </div> : null}
                {gumtreeFooter()}
            </div>
        </>
    )
}

export const gumtreeDesktop = (adPos, width, height, isSkinCreative, isScrolloCreative, renderAd) => {
    let bottom = height && height > 250
    return (
        <React.Fragment>
            <div id="bm-desktop-container" className={"bm-creative-preview-ad-content bm-desktop-container row m-0 p-0" + (isSkinCreative ? "" : " premium-gumtree")}>
                <div className='preview-container gumtree'>
                    <div id="page-container">
                        {gumtreeHeader(true)}
                        
                        {adPos === "top" &&
                        <div className="mt-3">
                            {renderAd(false, width, isSkinCreative)}
                        </div>}
                        
                        <div className="w-100 d-flex justify-content-center">
                            <div className="template-image gumtree-desktop-width mb-3 mt-2 d-flex justify-content-center subnav-desktop">
                                <img src="/images/template/gumtree/subnav-desktop.png" alt="gumtree" />
                            </div>
                        </div>
                        <div className="d-flex justify-content-center">
                            <div id='content' className="px-2">
                                <div className="gumtree-desktop-width">
                                    <div className="d-flex">
                                        {renderListings(3, true)}
                                        {(adPos === "center" && !bottom && !isScrolloCreative) ? <div className={"listing col-3"}>{renderAd(false, width, isSkinCreative)}</div> : renderListing(Content[3], true)}
                                    </div>
                                    {renderListings(8, true, 4)}
                                    <div className="button-container">
                                        <div className="gumtree-button desktop font-weight-bold mx-2 mt-3 py-2">
                                            See all
                                        </div>
                                    </div>
                                    {(adPos !== "top" && bottom) ? <div className={"mt-3"}>{renderAd(false, width, isSkinCreative)}</div> : null}
                                </div>
                            </div>
                        </div>
                        {gumtreeFooter(true)}
                    </div>

                </div>
            </div>
        </React.Fragment>
    )
}


export const gumtreeScroller = (isDesktop, browserWidth, dynHeight) => {
    return (
        <>
            {isDesktop ?
                (
                    <> </>
                )
                : (
                    <div className={"bm-creative-preview-ad-content"}>
                        <div className="gumtree pb-2 pro-scroll-gumtree">
                            {gumtreeHeader()}
                            <div className="gumtree-button main font-weight-bold m-2 py-2 mb-4">
                                Post an ad
                            </div>
                            
                            <img className='mb-2 mt-1' src="/images/template/gumtree/subnav.png" alt="gumtree" />
                            {renderListings(4)}
                            
                            <div className="gumtree-button font-weight-bold mx-2 mt-3 py-2 mb-5">
                                See all
                            </div>
                        </div>
                        
                        <div style={{ width: browserWidth + "px", height: dynHeight + "px" }}> </div>
                        <div className="gumtree pt-2"> {gumtreeFooter()} </div>
                    </div>
                )}
        </>)
}

export const gumtreeMobileSkin = (src = "") => {
    return (
        `
        <div class="bm-creative-preview-ad-content gumtree">
          ${ReactElementToJSXString(gumtreeHeader(false)).replace(/classname/ig, "class")}
          <div class="gumtree-button main">
            Post an ad
          </div>
          <img class="subnav-proskin-gumtree" src="/images/template/gumtree/subnav.png" alt="gumtree" />
          
            ${ReactElementToJSXString(renderListing(Content[0])).replace(/classname/ig, "class")}
            ${ReactElementToJSXString(renderListing(Content[1])).replace(/classname/ig, "class")}
            ${ReactElementToJSXString(renderListing(Content[2])).replace(/classname/ig, "class")}
            ${ReactElementToJSXString(renderListing(Content[3])).replace(/classname/ig, "class")}
          <div class="gumtree-button">
            See all
          </div>

          <div id="bm-creative-container" class="bm-ad col-12">
            <div id="bm-preview-iframe-container" class="mx-auto"> ${src} </div>
          </div>           
          ${ReactElementToJSXString(gumtreeFooter(false)).replace(/classname/ig, "class")}
      </div>`
    )
}
export const gumtreeCss = `
    *{ box-sizing: border-box; }
    img.template-image, img.subnav-proskin-gumtree{ width: 100%; }
    .gumtree{
        background-color: #f4f4f4;
        font-family: ProximaNova-Light,Arial,Helvetica,sans-serif;
        font-style: none;
        width:100%;
    }
    .listing{
        display: inline-flex;
        padding: 5px;
        padding-left: 13px;
        position: relative;
        width: 100%;
        flex: 0 0 48%;
        max-width: 48%;
    }
    .button-container{
        text-align: center;
    }
    .gumtree-button{
        border: 2px solid #3baa33;
        cursor: default;
        color: #5a5a5a;
        font-size: 15px;
        font-weight: 700;
        padding: 0.5rem 0;
        margin: 0.5rem;
        margin-bottom: 1.3rem;
        text-align: center;
        border-radius: 3px;
    }
    .template-listing .love-icon{ width: 16px; height: 15px;}
    .gumtree-button.main{ border: none; background-color: #3baa33; color: white;}
    .template-listing {
        padding: 10px;
        justify-content: space-between;
        width: 100%;
        user-select: none;
        -webkit-user-drag: none;
        color: black;

        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        border: 1px solid rgba(0,0,0,.125);
        border-radius: 0.25rem;

    } 
    .template-listing img{ width: 100%; }
    .template-listing .listing-header{ 
        display: flex;
        justify-content: center;
        border: 1px solid #ebebeb;
        box-sizing: border-box;
        background-color: #e9e9e9;
        overflow: hidden;
        height: 100px;
    }
    .template-listing img {
        max-width: 100%;
        max-height: 100%;
        user-select: none;
        -webkit-user-drag: none;
    }
    .template-listing .listing-body{ margin-top: 10px; }
    .template-listing .listing-body div{ display: flex; justify-content: space-between; }
    .template-listing h6{
        font-size: 14px;
        margin: 0;
        color: #353535;
        font-weight: normal;
        white-space: nowrap;
        margin-bottom: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .bm-ad{
        clear: both;
        margin: 0px;
        padding: 0 !important;
        overflow: hidden;
    }
    .template-listing div{
        color: #9A9A9A;
        font-size: 13px;
    }
    .template-listing i{
        align-self: center;
        color: white;
        stroke: black;
        stroke-width: 2px;
        text-shadow: 0 0 2px gray;
    }
  
  `