
import React from 'react'
import classnames from 'classnames'
import ReactElementToJSXString from 'react-element-to-jsx-string';
import './index.scss'

const MainRecipe = [
    {
        id: 1,
        header: `Our most recent Recipes of the Day`,
        image: `/images/template/taste/recipe-1.png`,
    },
    {
        id: 2,
        header: `21 immune-boosting soups to get you through winter`,
        image: `/images/template/taste/recipe-2.png`,
    },
    {
        id: 3,
        header: `Condensed milk custard filo pie & more must-see videos`,
        image: `/images/template/taste/recipe-3.jpg`,
    },
    {
        id: 4,
        header: `Quick quinoa stir-fry`,
        image: `/images/template/taste/recipe-4.jpg`,
    },
]
const SubRecipe = [
    {
        id: 1,
        header: `Caramel-flavoured Maltesers are here!`,
        image: `/images/template/taste/subrecipe-1.jpg`,
    },
    {
        id: 2,
        header: `This month's on-trend entertaining recipes`,
        image: `/images/template/taste/subrecipe-2.png`,
    },
    {
        id: 3,
        header: `55 winter finger food ideas`,
        image: `/images/template/taste/subrecipe-3.png`,
    },
    {
        id: 4,
        header: `The world's most comforting sides`,
        image: `/images/template/taste/subrecipe-4.png`,
    },
    {
        id: 5,
        header: `Easy entertaining in 17 minutes or fewer`,
        image: `/images/template/taste/subrecipe-5.png`,
    },
    {
        id: 6,
        header: `Peppermint Crisp mousse cake`,
        image: `/images/template/taste/subrecipe-6.jpg`,
    },
    {
        id: 7,
        header: `Cadbury releases a special-edition Mint Chip block!`,
        image: `/images/template/taste/subrecipe-7.jpg`,
    },
]
const tasteHeader = (desktop = false) => {
    return (
        desktop ? 
            <div className="desktop-header">
                <div className="text-center bg-white bb-gray">
                    <img className="template-image" src="/images/template/taste/header-desktop-1.png" alt="taste.com.au header" />
                </div>
                <div className="text-center bg-white bb-gray">
                    <img className="template-image" src="/images/template/taste/header-desktop-2.png" alt="taste.com.au header" />
                </div>
                <div className="text-center py-2 bg-main text-white font-weight-bold">
                    $2 can provide 10 meals and reduce food waste
                </div>
                <div className='text-center pb-3 px-3 bg-taste-light'>
                    <img className="template-image" src="/images/template/taste/header-desktop-3.png" alt="taste.com.au header" />
                </div>
            </div>
            : <div className="mobile-header">
                <img className={"template-image header"} src={"/images/template/taste/header.png"} alt="taste.com.au header" />
            </div>
    )
}

const tasteFooter = (desktop = false) => {
    return (
        <div>
        <div className='text-center bg-gray'>
            <img alt="" src={desktop ? "/images/template/taste/collections-desktop.png" : "/images/template/taste/collections.png"} className={classnames("template-image", desktop ? "desktop-size my-3" : "")}/>
        </div>
        {desktop ? 
            <div className="desktop-footer">
                <h2 className='desktop-size new-banner font-weight-bold my-4 px-2'>New on taste</h2>
                <div className='bb-dash-gray pb-4 d-flex new-content'>
                    {SubRecipe.map( content => tasteSubRecipe(content, desktop, ""))}
                </div>
                <div className="bg-white bb-dash-gray p-3">
                    <img className="template-image" src="/images/template/taste/footer-desktop-1.png" alt="taste.com.au footer" />
                </div>
                <div className="bg-white p-2">
                    <img className="template-image" src="/images/template/taste/footer-desktop-2.png" alt="taste.com.au footer" />
                </div>
                <div className="bg-footer p-2">
                    <img className="template-image mb-3" src="/images/template/taste/footer-desktop-3.png" alt="taste.com.au footer" />
                </div>
            </div>
            :
            <div className='bg-white'>
                <h2 className='new-banner font-weight-bold'>New on taste</h2>
                <div className='bb-dash-gray new-content'>
                    {tasteSubRecipe(SubRecipe[5], false, "col-12")}
                </div>
                <img className="template-image" src="/images/template/taste/footer-1.png" alt="taste.com.au footer" />
                <img className="template-image" src="/images/template/taste/footer-2.png" alt="taste.com.au footer" />
                <img className="template-image" src="/images/template/taste/footer-3.png" alt="taste.com.au footer" />
            </div>}
        </div>
        
    )
}
const tasteSubRecipe = (content, desktop = false, customClass = "col-6") => {
    return (
        <div className={classnames("taste-subrecipe", (desktop ? "desktop" : "mobile"), customClass)} key={content.id}>
            <img src={content.image} alt="taste.com.au recipe" />
            <div className='text-description mt-1'>{content.header}</div>
        </div>
    )
}

const tasteMainRecipe = (desktop = false) => {
    return (
        <div className={"main-recipe bg-white"}>
            <div className="taste-subrecipe">
                <img alt="" src={desktop ? "/images/template/taste/main-recipe-desktop.png" : "/images/template/taste/main-recipe.png"} className="template-image"/>
            </div>
            <div className='py-2 recipe-list'>
                {MainRecipe.map( content => tasteSubRecipe(content, desktop))}
            </div>
        </div>
    )
}
export const tasteMobile = (pos, isInterstitialCreative = false, isScrolloCreative = false, isScrolloDesktopCreative = false, isMobileSkinCreative = false, renderAd, height) => {
    let bottom = height && height <= 150
    return (
        <>
            <div className={classnames("bm-creative-preview-ad-content taste mobile position-relative", isScrolloDesktopCreative ? "ad-content-scrollo-desktop" : "")}>
                {tasteHeader()}
                
                {tasteMainRecipe()}

                {!bottom && pos.match(/^(center|middle)$/ig) && !isInterstitialCreative ? <div className='pb-2'> {renderAd()} </div> : null}
                
                {tasteFooter()}
                {bottom && !isInterstitialCreative ? <div className='sticky-ad-mobile'>
                    {renderAd()}
                </div> : null}
            </div>
        </>
    )
}

export const tasteDesktop = (adPos, width, height, isSkinCreative, isScrolloCreative, renderAd) => {
    const billboardHeight = parseInt(height) + 55;
    return (
        <div className="taste">
            {adPos === "top" && !isSkinCreative &&
                <div className={classnames('billboard-container', isSkinCreative ? 'h-0' : '')}>
                    <div className='sticky-container'>
                        {renderAd(false, width, isSkinCreative)}
                    </div>
            </div>}
            {adPos === "top" && !isSkinCreative &&
                <div style={{paddingTop: billboardHeight+"px"}}>
            </div>}
            {tasteHeader(true)}
            <div className="desktop-container">
            
            <div id="right-container" className={adPos === "center" ? "sticky" : ""}>
                {adPos === "center" ? renderAd(false, width, isSkinCreative) : <img alt="" src="/images/template/taste/side-container.png"/>}
            </div> 
            
            <div id="bm-desktop-container" className={"bm-creative-preview-ad-content bm-desktop-container row taste-desktop" + (isSkinCreative ? "" : " premium-taste")}>
                <div className='preview-container taste'>
                    <div id="page-container">
                        <div id='content'>
                            {tasteMainRecipe(true)}
                        </div>
                    </div>
                </div>
            </div>
            </div>
            {tasteFooter(true)}
        </div>
    )
}

export const tasteScroller = (isDesktop, browserWidth, dynHeight) => {
    return (
        <>
            {isDesktop ?
                (
                    <> </>
                )
                : (
                    <div className={"bm-creative-preview-ad-content taste mobile"}>
                        <div className="taste pro-scroll-taste">
                            {tasteHeader()}
                            {tasteMainRecipe()}
                        </div>

                        <div style={{ width: browserWidth + "px", height: dynHeight + "px" }}> </div>
                
                        <div className="taste"> {tasteFooter()} </div>
                    </div>
                )}
        </>)
}

export const tasteMobileSkin = (src = "") => {
    return (
        `
        <div class="bm-creative-preview-ad-content taste proskin-mobile">
          ${ReactElementToJSXString(tasteHeader(false)).replace(/classname/ig, "class")}
          ${ReactElementToJSXString(tasteMainRecipe(false)).replace(/classname/ig, "class")}
          
          <div id="bm-creative-container" class="bm-ad col-12">
            <div id="bm-preview-iframe-container" class="mx-auto"> ${src} </div>
          </div> 
          ${ReactElementToJSXString(tasteFooter(false)).replace(/classname/ig, "class")}
      </div>`
    )
}
export const tasteCss = `
    *, ::after, ::before {
        box-sizing: border-box;
    }
    .taste{
        background-color:white;
        font-family: Cabin,"Open Sans",Arial,sans-serif;
        color: #393434;
        user-select: none;
        -webkit-user-drag: none;
        line-height: 1.3;
        letter-spacing: normal;
        position: relative;
        font-size: 14px;
    }
    .template-image{ width: 100%; }
    .text-description{ font-weight: 600; text-align: left; }
    .new-banner{ margin: 0 auto; text-align: left; color: #393434; padding: 20px 10px; padding-bottom: 0px; }
    .template-image{ width: 100%; }
    .bb-gray{ border-bottom: 1px solid #f1f0f0; }
    .bb-dash-gray{ border-bottom: 2px dashed #e1e1e0; }
    .taste-subrecipe {
        padding: 12px;
        background: white;
        display: inline-grid;
    }
    .taste.proskin-mobile .recipe-list{ display:table; }
    .taste-subrecipe img{ width: 100%; height: auto; }
    .new-content{ font-size: 18px; }
    .d-flex{ display: flex; }
    .col-6{ flex: 0 0 50%; max-width: 50%; }
    .col-12{ flex: 0 0 100%; max-width: 100%; }
    .bg-white{ background-color: white; }
    .mt-1{ margin-top: 0.25rem!important; }
    .font-weight-bold { font-weight: 700!important; }
  `