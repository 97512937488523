
import React from 'react'
import classnames from 'classnames'
import ReactElementToJSXString from 'react-element-to-jsx-string';
import './index.scss'

const Content = [
    {
        id: 1,
        header: `’Gloomy outlook’ for response to alien life`,
        image: `/images/template/news/article-1.jpg`,
        description: `A leading astronomer reveals there are currently no plans for how scientists are to report or respond to alien contact.`
    },
    {
        id: 2,
        header: `Bizarre object spotted in New Zealand`,
        image: `/images/template/news/article-2.jpg`,
        description: `A strange phenomenon has been spotted over the skies of New Zealand. And it turns out the world’s richest man might be responsible.`
    },
    {
        id: 3,
        header: `NASA’s ‘surprise’ discovery on Mars`,
        image: `/images/template/news/article-3.jpg`,
        description: `NASA has expressed its surprise after its Perseverance Mars rover came across an “unexpected” object while roaming the Red Planet.`
    },
    {
        id: 4,
        header: `China ‘discovers message from aliens’`,
        image: `/images/template/news/article-4.jpg`,
        description: `A bombshell Chinese report suggesting an alien civilisation may have been found was published - but now there’s been a mysterious twist.`
    },
    {
        id: 5,
        header: `‘Unexpected’ space find shocks scientists`,
        image: `/images/template/news/article-5.png`,
        description: `Scientists have made a surprising new space discovery, with astronomers thinking they had “run out of sky” where objects like this could be hiding.`
    },
    {
        id: 6,
        header: `Surprise phenomenon in new space photo`,
        image: `/images/template/news/article-6.jpg`,
        description: `A ring of debris orbiting a neighbouring star could contain vital clues to how Earth was formed millions of years ago`
    },
    {
        id: 7,
        header: `Huge NASA news for Australia`,
        image: `/images/template/news/article-7.jpg`,
        description: `American space agency NASA will be heading to Australia later this month for a series of rocket launches.`
    },
    {
        id: 8,
        header: `‘Alien door’ spotted on Mars by NASA`,
        image: `/images/template/news/article-8.jpg`,
        description: `Scientists have explained what looks like a doorway into the side of a mountain on the red planet.`
    },
]
const newsHeader = (desktop = false) => {
    return (
        desktop ? 
            <div className="desktop-header text-white bb-black">
                <div className="header-date">
                    <div className="d-flex justify-content-between desktop-container">
                        <div className="col-4"> Best Shopping Deals </div>
                        <div className="col-4 text-center">
                            <b>1:03pm</b> Monday, July 4th, 2022
                        </div>
                        <div className="col-4 text-right"> In the know quiz </div>
                    </div>
                </div>
                <div className='text-center bb-gray py-3 px-3'>
                    <img className="template-image desktop-width" src="/images/template/news/header-desktop-1.png" alt="news.com.au header" />
                </div>
                <div className="text-center header-tag my-2">
                    <span>National</span>
                    <span>World</span>
                    <span>Lifestyle</span>
                    <span>Travel</span>
                    <span>Entertaiment</span>
                    <span className='text-main'>Technology</span>
                    <span>Finance</span>
                    <span>Sport</span>
                    <span>More</span>
                </div>
            </div>
            : <div className="mobile-header bb-black">
                <img className={"template-image header"} src={"/images/template/news/header-1.png"} alt="news.com.au header" />
                <img className={"template-image header"} src={"/images/template/news/header-2.png"} alt="news.com.au header" />
            </div>
    )
}

const newsFooter = (desktop = false) => {
    return (
        desktop ? 
            <div className="desktop-footer">
                <img className="template-image bb-gray" src="/images/template/news/footer-desktop-1.png" alt="news.com.au footer" />
                <img className="template-image bb-black" src="/images/template/news/footer-desktop-2.png" alt="news.com.au footer" />
                <img className="template-image" src="/images/template/news/footer-desktop-3.png" alt="news.com.au footer" />
            </div>
            :
            <div>
                <img className="template-image" src="/images/template/news/footer-1.png" alt="news.com.au footer" />
                <img className="template-image" src="/images/template/news/footer-2.png" alt="news.com.au footer" />
            </div>
        
    )
}

const newsBreadcrumb = (isProskinMobile = false) => {
    return (<div className='my-2'>
        <span className='text-main'>Technology</span>
        <span className='text-gray mx-1'>{isProskinMobile ? <>{`&#62;`}</> :  `>`}</span>
        <span className='text-main'>Science</span>
        <h1 className='font-weight-bold text-main text-title mb-4'>Space</h1>
    </div>)
}

const newsMainArticle = (desktop = false, isProskinMobile = false) => {
    const category = <div className='d-flex'>
                        <span className='text-important font-weight-bold'>UPDATED</span>
                        <span className='text-main'>SPACE</span>
                    </div>
    return (
        <div className={"main-article"}>
            <div className="article-header">
                {desktop ? null : <>
                    {newsBreadcrumb(isProskinMobile)}
                    {category}
                </>}
            </div>
            {desktop ? <div className='d-flex my-2'>
                <img className="col-8 pl-0" src="/images/template/news/main-article.jpg" alt="news.com.au main-article" />
                <div>
                    {category}
                    <h4 className='mt-3 mb-2'>History as NASA launches Aussie rocket</h4>
                    <span className='text-description'>Around 100 VIPs gathered overnight in a remote part of Australia to watch an Australian-first rocket launch light up the dessert sky.</span>
                </div>
            </div> : 
            <div>
                <img className="template-image my-2" src="/images/template/news/main-article.jpg" alt="news.com.au main-article" />
                <h4>History as NASA launches Aussie rocket</h4>
                <span className='text-description'>Around 100 VIPs gathered overnight in a remote part of Australia to watch an Australian-first rocket launch light up the dessert sky.</span>
            </div>}
            
        </div>
    )
}
const newsSubArticle = (content, desktop = false, rowType = false, customClass = "") => {
    return (
        desktop ? 
            ( rowType ?
                <div className={"news-subarticle full-row desktop d-flex " + customClass} key={content.id}>
                    <img src={content.image} alt="news.com.au sub-article" />
                    <div>
                        <div className='text-main'>SPACE</div>
                        <h5 className='mb-2 mt-1'>{content.header}</h5>
                        <div className='text-description'>{content.description}</div>
                    </div>
                </div> :

                <div className={"news-subarticle desktop column col-4 border-0 " + customClass} key={content.id}>
                    <div className='text-main mb-2'>SPACE</div>
                    <img src={content.image} alt="news.com.au sub-article" />
                    <h5 className='my-2'>{content.header}</h5>
                    <div className='text-description'>{content.description}</div>
                </div>) :

        <div className={"news-subarticle mobile " + customClass} key={content.id}>
            <div className='d-flex'>
                <img src={content.image} alt="news.com.au sub article" />
                <div>
                    <div className='text-main mb-1'>SPACE</div>
                    <h5>{content.header}</h5>
                </div>
            </div>
            <div className='text-description mt-1'>{content.description}</div>
        </div>
    )
}
const newsArticle = (component = null, desktop = false) => {
    return(
        <div className="content-article">
            <div className={desktop ? "d-flex" : ""}>
                {newsSubArticle(Content[0], desktop, false, desktop ? "pl-0": "")}
                {newsSubArticle(Content[1], desktop)}
                {newsSubArticle(Content[2], desktop, false, desktop ? "pr-0": "")}
            </div>
            {component}
            {newsSubArticle(Content[3], desktop, desktop)}
            {newsSubArticle(Content[4], desktop, desktop)}
            {newsSubArticle(Content[5], desktop, desktop)}
            {newsSubArticle(Content[6], desktop, desktop)}
            {newsSubArticle(Content[7], desktop, desktop)}
        </div>
    )
}
export const newsMobile = (pos, isInterstitialCreative = false, isScrolloCreative = false, isScrolloDesktopCreative = false, isMobileSkinCreative = false, renderAd, height) => {
    let bottom = height && height <= 150
    return (
        <>
            <div className={classnames("bm-creative-preview-ad-content news mobile position-relative", isScrolloDesktopCreative ? "ad-content-scrollo-desktop" : "")}>
                {newsHeader()}
                
                {newsMainArticle()}

                {newsArticle(!bottom && pos.match(/^(center|middle)$/ig) && !isInterstitialCreative ? <div> {renderAd()} </div> : null)}
                
                {newsFooter()}
                {bottom && !isInterstitialCreative ? <div className='sticky-ad-mobile'>
                    {renderAd()}
                </div> : null}
            </div>
        </>
    )
}

export const newsDesktop = (adPos, width, height, isSkinCreative, isScrolloCreative, renderAd) => {
    const billboardHeight = parseInt(height) + 55;

    return (
        <div className="news">
            {adPos === "top" && !isSkinCreative &&
                <div className={classnames('billboard-container', isSkinCreative ? 'h-0' : '')}>
                    <div className='sticky-container'>
                        {renderAd(false, width, isSkinCreative)}
                    </div>
            </div>}
            {adPos === "top" && !isSkinCreative &&
                <div style={{paddingTop: billboardHeight+"px"}}>
            </div>}
            {newsHeader(true)}
            <div className="desktop-container">
            {newsBreadcrumb()}
            
            {adPos === "center" && 
            <div id="right-container">
                {renderAd(false, width, isSkinCreative)}
            </div>}
            
            <div id="bm-desktop-container" className={"bm-creative-preview-ad-content bm-desktop-container row news-desktop" + (isSkinCreative ? "" : " premium-news")}>
                <div className='preview-container news'>
                    <div id="page-container">
                        <div id='content'>
                            {newsMainArticle(true)}
                            {newsArticle(null, true)}
                        </div>
                    </div>
                </div>
            </div>
            </div>
            {newsFooter(true, isSkinCreative, adPos === "top")}
        </div>
    )
}

export const newsScroller = (isDesktop, browserWidth, dynHeight) => {
    return (
        <>
            {isDesktop ?
                (
                    <> </>
                )
                : (
                    <div className={"bm-creative-preview-ad-content news mobile"}>
                        <div className="news pro-scroll-news">
                            {newsHeader()}
                            {newsMainArticle()}
                        </div>
                
                        {newsArticle(<div style={{ width: browserWidth + "px", height: dynHeight + "px" }}> </div>)}
                
                        <div className="news"> {newsFooter()} </div>
                    </div>
                )}
        </>)
}

export const newsMobileSkin = (src = "") => {
    return (
        `
        <div class="bm-creative-preview-ad-content news">
          ${ReactElementToJSXString(newsHeader(false)).replace(/classname/ig, "class")}
          ${ReactElementToJSXString(newsMainArticle(false, true)).replace(/classname/ig, "class")}
          
          <div id="bm-creative-container" class="bm-ad col-12">
            <div id="bm-preview-iframe-container" class="mx-auto"> ${src} </div>
          </div> 
          ${ReactElementToJSXString(newsArticle(null)).replace(/classname/ig, "class")}
          ${ReactElementToJSXString(newsFooter(false, false, false)).replace(/classname/ig, "class")}
      </div>`
    )
}
export const newsCss = `
    .news{
        font-size: 14px;
        background:white;
        font-family: "Helvetica Neue",HelveticaNeue,Helvetica,Arial,sans-serif;
        color: #292A33;
        user-select: none;
        -webkit-user-drag: none;
        line-height: 1.3;
        letter-spacing: normal;
        position: relative;
    }
    .template-image{ width: 100%; }
    h4, h5{ margin-bottom: 0px; font-weight: bold; margin-top: 0px;}
    h4{ font-size: 1.5rem; }
    h5{ font-size: 1.25rem; }
    h1{ font-size: 2.5rem; }
    .text-main{ color: #F46A00; }
    .text-important{ color: #C90000; }
    .text-description{ color: #606669; }
    .text-gray{ color: #e0e1e2; }
    .text-title{ font-family: "Eva Bold","Times New Roman",Times,serif;  margin-top: 10px;}
    .bb-gray{ border-bottom: 1px solid #eaebec; }
    .bb-black{ border-bottom: 1px solid #202223; }
    .pro-scroll-news{background-color: white;}
    .main-article .text-important{ margin-right: 10px; }
    .news-subarticle, .main-article {
        padding-top: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid #eaebec;
    }
    .news-subarticle img{
        max-width: 100px;
        margin-right: 16px;
    }
    .content-article, .main-article{
        padding-left: 16px;
        padding-right: 16px;
    }
    .d-flex{ display: flex; }
    .mt-1{ margin-top: 0.25rem!important; }
    .mb-1{ margin-bottom: 0.25rem!important; }
    .mx-1{ margin-left: 0.25rem!important; margin-right: 0.25rem!important; }
    .my-2{ margin-top: 0.5rem!important; margin-bottom: 0.5rem!important; }
    .font-weight-bold { font-weight: 700!important; }
  `