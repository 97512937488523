
export const FeedList = [
    {
        id: 960,
        title: '960px',
        selected: false
    },
    {
        id: 1000,
        title: '1000px',
        selected: true
    },
    {
        id: 1200,
        title: '1200px',
        selected: false
    }
];
