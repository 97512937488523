import React from 'react'
import { ToastContainer } from "react-toastify"

import './toastify.scss'

const Toastify = () => {
    return (
        <ToastContainer theme="colored" />
    )
}

export default Toastify