import React, { Component } from 'react';

import { config } from '../../helpers'
import { getPreviewContainerWidth } from '../utilities/customFunctions'
import PreviewFrame from '../helpers/preview-frame'

const adContainer = "#bm-creative";

class Streamview extends Component {

  componentDidMount() {    
    window.addEventListener("resize", this.updateCreative);
    window.addEventListener("orientationchange", this.updateCreative);

    if (this.props.details && this.props.details._id) {
      this.loadSv(this.props.details);
    }
  }

  componentWillUnmount(){
    window.removeEventListener("resize", this.updateCreative);
    window.removeEventListener("orientationchange", this.updateCreative);
  }

  updateCreative = () => {
    if (this.props.details) {
      const {details} = this.props;
      var bmAdContainer = document.querySelectorAll('*[id^="bmAdContainer"]')[0];
      var bmWrapper = document.querySelectorAll('*[id^="bmWrapper"]')[0];

      var browserWidth = this.props.isDesktop ? details.width : getPreviewContainerWidth(details.width);
      //document.getElementsByClassName("bm-creative-preview")[0].clientWidth;

      var dynHeight = (browserWidth * details.height) / details.width;

      if (bmAdContainer) {        
        bmAdContainer.style.width = browserWidth + "px";
        bmAdContainer.style.height = dynHeight + "px";
      }
      if (bmWrapper) {
        bmWrapper.style.width = browserWidth + "px";
        bmWrapper.style.height = dynHeight + "px";
      }

      document.querySelector(adContainer).style.width = browserWidth + "px";
      document.querySelector(adContainer).style.height = dynHeight + "px";      
    }
  }

  loadSv = (details) => {
    
    this.xyz = new Array(2);
    var that = this;

    /* Remove if exists */
    if (document.getElementById('inscreenScript')) {
        document.getElementById('inscreenScript').remove();
    };

    var head = document.getElementsByTagName('head')[0];
    var inscreen = document.createElement("script");
    inscreen.src = "/js/inscreen.js";
    inscreen.id = "inscreenScript";
    head.appendChild(inscreen);    

    document.querySelector("#bm-creative").innerHTML = "";
    //document.querySelector("#bm-creative").style.height = "0px";
    if (window.bmSV) {
        window.bmSV.destroy();
    }
    /* Remove if exists */
    if (document.getElementById('svCreativeScript')) {
        document.getElementById('svCreativeScript').remove();
    };

    let script = document.createElement("script");
    script.type = "text/javascript";
    script.id = "svCreativeScript";
    script.src = config.scripts.streamview.script;
    script.async = false;
    script.onload = function () {

        if(details.content && details.content.length === 3){
            var obj = {
                src: details.content[0].imageSrc,
            };
            that.xyz[0] = obj;

            obj = {
                src: details.content[2].videoSrc,
            };
            that.xyz[1] = obj;
        }
      that.initSv(details);
    };

    document.querySelector(adContainer).appendChild(script);
  }

  initSv = (details) => {
    var startFrameImage = "";
    if (this.xyz[0]) {
        startFrameImage = this.xyz[0].src || "";            
    }

    var videoSrc = "";
    if (this.xyz[1]) {
        videoSrc = this.xyz[1].src || "";            
    }

    if (document.getElementById('renderStreamview')) {
        document.getElementById('renderStreamview').remove()
    };

    const setSv = document.createElement('script');
    setSv.setAttribute('id', 'renderStreamview');
    setSv.type = 'text/javascript';
    setSv.onload = this.injectInscreen();
    setSv.async = false;
  
    var containerWidth = this.props.isDesktop ? details.width : getPreviewContainerWidth(details.width);
    //var containerWidth = document.getElementsByClassName("bm-creative-preview")[0].clientWidth;
    var dynHeight = (containerWidth * details.height) / details.width;

    document.querySelector(adContainer).style.height = dynHeight + "px";

    var renderStreamview = `
 
    window.bmSV = new BMStreamview({
        clickThroughURL: '${details.default_landingURL}' , //default clickthrough URL
        parentElement: document.getElementById("bm-creative"), //container element for the Sv element
        width: '${containerWidth}', //width of the Sv element
        height: '${dynHeight}', //height of the Sv element
        startFrameImage: '${startFrameImage}',//"https://via.placeholder.com/300/0000FF/808080?text=1",
        endFrameImage: '${startFrameImage}',
        videoSrc: '${videoSrc}',
        videoStyle: "${details.videoStyle}", 
        closeButtonShow: "false",
        closeButtonPosition: "${details.closeButtonPosition}",
        openURLTarget: "_blank", //_top, _parent, _blank
    }); 
    `
    setSv.innerHTML = renderStreamview ;

    this.setState({loading : false});
    document.querySelector("#bm-creative").appendChild(setSv); 
    //this.injectInscreen()
  }

  injectInscreen(){
    if (document.getElementById('sdvs')) {
        document.getElementById('sdvs').remove()
    };
    let inscreenScript = document.createElement("script");
    inscreenScript.setAttribute('id', 'sdvs');
    inscreenScript.type = "text/javascript";
    inscreenScript.async = false;
    inscreenScript.innerHTML = `
    window.onload = function(){
      var videoElement = document.getElementById("showcaseVideo"); 
      $("#showcaseVideo").inViewport(function( px ){ 
      var h = $(this).height();
      var isHalfVisible =  (px >= h/2) ;      
      if(isHalfVisible) {videoElement.play();} else {videoElement.pause();}
    });
  }`
  document.querySelector("#bm-creative").appendChild(inscreenScript);
}

  render() {
    
    return (        
      <PreviewFrame {...this.props}/>
    );
  }
}

export default (Streamview);
