import React, { Component } from 'react'
import './global.scss'

class Dropdown extends Component{
  constructor(props){
    super(props)
    this.state = {
      listOpen: false,
      headerTitle: this.props.title
    }
    this.close = this.close.bind(this)
  }

  componentDidMount() {
    this.setTitle();
  }

  componentDidUpdate(){
    const { listOpen } = this.state
    setTimeout(() => {
      if(listOpen){
        window.addEventListener('click', this.close)
      }
      else{
        window.removeEventListener('click', this.close)
      }
    }, 0)
  }

  componentWillUnmount(){
    window.removeEventListener('click', this.close)
  }

    setTitle = () => {
    const{list} = this.props

    const item = list.find(item => item.selected);
    this.setState({headerTitle: item ? item.title : this.props.title});
  }

  close(timeOut){
    this.setState({
      listOpen: false
    })
  }

  selectItem(title, id, stateKey, i){
    this.setState({
      headerTitle: title,
      listOpen: false
    }, this.props.resetThenSet(id, title, stateKey, i))
  }

  toggleList(){
    this.setState(prevState => ({
      listOpen: !prevState.listOpen
    }))
  }

  render(){
    const{list, disabled = false } = this.props
    const{listOpen, headerTitle} = this.state
    return(
      <div className="dd-wrapper">
        <div className={"dd-header " + this.props.headerClass} onClick={() => {!disabled && this.toggleList()}}>
          <div className="dd-header-title">{headerTitle}</div>
          {listOpen
            ? <i className="fas fa-angle-up" />
            : <i className="fas fa-angle-down" />
          }
        </div>
        {listOpen && <ul className="dd-list" onClick={e => e.stopPropagation()}>
          {list.map((item, i)=> (
            <li className="dd-list-item" key={item.id} onClick={() => this.selectItem(item.title, item.id, item.key, i)}>
                {item.title} {item.selected && <i className="fas fa-check" />}</li>
          ))}
        </ul>}
      </div>
    )
  }
}

export default Dropdown