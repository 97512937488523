import React from 'react'
import styled from 'styled-components'

import { notify } from '../utilities/customFunctions'
import {CopyToClipboard} from 'react-copy-to-clipboard';

const Icon = styled.img`
    width: 24px;
    height: auto;
    align-self: right;    
    margin-left: 10px;
    cursor: pointer;
`;
const CopyLink = (props) => {
    return (
        <>
            <CopyToClipboard text={props.url}
                onCopy={() => notify('Copied!', "s")}>
                <Icon title="Copy preview link" src="/images/icons/copy-link-icon.png" />
            </CopyToClipboard>            
        </>
    )
}

export default CopyLink