import React, { Component } from 'react';

import { config } from '../../helpers'
import { getPreviewContainerWidth } from '../utilities/customFunctions'
import PreviewFrame from '../helpers/preview-frame'

const adContainer = "#bm-creative";

class Still extends Component {

  componentDidMount() {    
    window.addEventListener("resize", this.updateCreative);
    window.addEventListener("orientationchange", this.updateCreative);

    if (this.props.details && this.props.details._id) {
      this.loadStill(this.props.details);
    }
  }

  componentWillUnmount(){
    window.removeEventListener("resize", this.updateCreative);
    window.removeEventListener("orientationchange", this.updateCreative);
  }

  updateCreative = () => {
    if (this.props.details) {
      const {details} = this.props;
      var bmAdContainer = document.querySelectorAll('*[id^="bmAdContainer"]')[0];
      var bmWrapper = document.querySelectorAll('*[id^="bmWrapper"]')[0];

      var browserWidth = this.props.isDesktop ? details.width : getPreviewContainerWidth(details.width);
      //document.getElementsByClassName("bm-creative-preview")[0].clientWidth;

      var dynHeight = (browserWidth * details.height) / details.width;

      if (bmAdContainer) {        
        bmAdContainer.style.width = browserWidth + "px";
        bmAdContainer.style.height = dynHeight + "px";
      }
      if (bmWrapper) {
        bmWrapper.style.width = browserWidth + "px";
        bmWrapper.style.height = dynHeight + "px";
      }

      document.querySelector(adContainer).style.width = browserWidth + "px";
      document.querySelector(adContainer).style.height = dynHeight + "px";      
    }
  }

  loadStill = (details) => {
    this.xyz = new Array(1);
    var that = this;

    document.querySelector("#bm-creative").innerHTML = "";
    //document.querySelector("#bm-creative").style.height = "0px";
    if (window.bmStill) {
        window.bmStill.destroy();
    }
    /* Remove if exists */
    if (document.getElementById('stillCreativeScript')) {
        document.getElementById('stillCreativeScript').remove();
    };

    let script = document.createElement("script");
    script.type = "text/javascript";
    script.id = "stillCreativeScript";
    script.src = config.scripts.still.script;
    script.async = false;
    script.onload = function () {

        if(details.content && details.content.length > 0){
            var obj = {
                src: details.content[0].imageSrc,
            };
            that.xyz[0] = obj;         
        }
      that.initStill(details);
    };

    document.querySelector(adContainer).appendChild(script);
  }

  initStill = (details) => {
    var imageSrc = "";
    if (this.xyz[0]) {
      imageSrc = this.xyz[0].src || "";            
    }

    if (document.getElementById('renderStill')) {
        document.getElementById('renderStill').remove()
    };

    const setStill = document.createElement('script');
    setStill.setAttribute('id', 'renderStill');
    setStill.type = 'text/javascript';
    setStill.async = false;

    var containerWidth =  this.props.isDesktop ? details.width : getPreviewContainerWidth(details.width);
    //var containerWidth = document.getElementsByClassName("bm-creative-preview")[0].clientWidth;
    var dynHeight = (containerWidth * details.height) / details.width;

    document.querySelector(adContainer).style.height = dynHeight + "px";

    var renderStill = `
          window.bmStill = new BMSetStill({
          clickThroughURL: '${details.default_landingURL}' , //default clickthrough URL
          parentElement: document.getElementById("bm-creative"), //container element for the Sv element
          width: '${containerWidth}', //width of the Sv element
          height: '${dynHeight}', //height of the Sv element
          imageSrc: '${imageSrc}',//"https://via.placeholder.com/300/0000FF/808080?text=1",
          thirdPartyImpressionURL: '${details.default_impressionURL}',           
          openURLTarget: "_blank", //_top, _parent, _blank
      });       
    `;
    
    setStill.innerHTML = renderStill ;

    this.setState({loading : false});
    document.querySelector("#bm-creative").appendChild(setStill);
  }

  render() {
    
    return (        
        <PreviewFrame {...this.props}/>
    );
  }
}

export default (Still);
