export const PreviewConstants = {
    AD_FORMAT: "AD_FORMAT",
    AD_SIZE: "AD_FORMAT",
    AD_DETAILS: "AD_DETAILS",

    AD_ERRORS: "AD_ERRORS",
    AD_LOADING_STATUS: 'AD_LOADING_STATUS',

    NEXD_DETAILS: "NEXD_DETAILS",
    NEXD_DETAILS_ERROR: "NEXD_DETAILS_ERROR",

    AIRTORY_TAG_DETAILS: "AIRTORY_TAG_DETAILS",
    AIRTORY_TAG_DETAILS_ERROR: "AIRTORY_TAG_DETAILS_ERROR",
};
