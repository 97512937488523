import React from 'react'
import styled from 'styled-components'

const Icon = styled.div`
    width: 24px;
    height: auto;
    align-self: right;    
    margin-right: 10px;
    cursor: pointer;
    border-radius: 3px;
    transform: rotate(270deg);
`;
const BuddyLink = (props) => {
    return (
        <>
            <Icon onClick={() => window.open(props.url, "_blank").focus()} title="Go to Deal page" >
            <i className="fas fa-external-link-square-alt"></i>
            </Icon>
        </>
    )
}

export default BuddyLink