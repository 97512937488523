import React, { useState, useEffect } from 'react';
import { getQueryStringParams } from '../utilities/customFunctions'
import Swipe from "./Swipe"
import Streamview from "./Streamview"
import Still from "./Still"
import Scratch from "./Scratch"
import TruSkinDesktopClassic from "./TruSkin/Desktop/Classic"
import ProSkinDesktopClassic from "./Pro-Skin/Desktop/Classic"
import { useLocation } from 'react-router';

function LiveAd() {

  const [format, setFormat] = useState("")
  const [isDesktop, setIsDesktop] = useState(false)

  let { search } = useLocation()

  useEffect(() => {
    let searchParams = getQueryStringParams(search);
    if (searchParams && searchParams.format) {
      setFormat(searchParams.format)
      setIsDesktop(searchParams.desktop || false)
    }
  }, [search])


  function renderFormat(format, isDesktop) {
    if (format === "swipe" || format === "cube" || format === "carousel" || format === "limelight") {
      return <Swipe />
    }
    else if (format === "streamview" || format === "cinematics") {
      return <Streamview />
    }
    else if (format === "still" || format === "image" || format === "mrec" || format === "banner" || format === "standard iab" || format === "static") {
      return <Still />
    }
    else if (format === "scratch") {
      return <Scratch />
    }
    else if (format === "pro-niks" && isDesktop) {
      return <ProSkinDesktopClassic />
    }
    else if (format === "truskin classic" && isDesktop) {
      return <TruSkinDesktopClassic />
    }
    else {
      return "Format not found"
    }
  }


  return (renderFormat(format, isDesktop));

}

export default LiveAd;
