import { BenchmarkConstants } from './benchmark.constants';

export const benchmark = (state = {}, action) => {

    switch (action.type) {
        
        case BenchmarkConstants.BENCHMARK_LOADING:
            return { ...state, loading: action.payload }
        
        case BenchmarkConstants.GET_BENCHMARK:
            return { ...state, data: action.payload }
        case BenchmarkConstants.GET_BENCHMARK_ERROR:
                return { ...state, data: {"success": "false"} }
            
        default:
            return state;
    }
};
