import React, { useState } from 'react'
import styled from 'styled-components'

const ZoomWrapper = styled.div`
    width: 100px;
    position:relative;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid #6b6d76;
    padding: 2px;
`;

const Button = styled.button`
    height: 1.375rem;
    width: 1.375rem;
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    background: transparent;
    border: none;
    color:white;
    &:focus{
        outline: none;
    }
`;

const Label = styled.div`
    width: 100%;
    padding: 5px 2px;
    text-overflow: inherit;
    background: transparent;
    color: #fff;
    text-align: center;
    font-size: 14px;
`;

const Zoom = () => {
    
    const [value, setValue] = useState(100);

    const onInputChange = (val) =>{
    
        if(val > 59 && val < 149){
            setValue(val);
            document.getElementById("bb-preview-device-container").style.cssText = `transition: 0.5s transform;transform:scale(${val/100}); transform-origin: center top`;
        }
    }

    return (
        <ZoomWrapper>
             <Button onClick={() => onInputChange(value - 10)}>-</Button>
            <Label title="Zoom in/out">{value} %</Label>
            <Button onClick={() => onInputChange(value + 10)}>+</Button>
        </ZoomWrapper>
    )
}

export default Zoom