import React, { Component } from 'react';
import PreviewContainer from '../../preview/container';

import { config } from '../../../helpers'
import imageCompressor from '../../utilities/imageCompressor';

class Still extends Component {
    constructor(props) {
        super(props)
        this.state = {
            clickThroughURL: "",
            width: "",
            height: "",
            thirdPartyImpressionURL:"",
            loading: true,
            isDesktop: false,
            decarbonise: false,
            ar: 100
        };
    }

    xyz = [];

    componentDidMount() {
        window.addEventListener('message', this.handleBuilder);
    }

    componentWillUnmount(){
        window.removeEventListener('message', this.handleBuilder);
    }

    handleBuilder = (event) => {
        if(event.data && (event.data.image_upload)){
            var that = this;
            this.setState({
                clickThroughURL: event.data.default_landingURL,
                width: event.data.size.width,
                height: event.data.size.height,
                thirdPartyImpressionURL: event.data.default_impressionURL,
                loading: true,
                // eslint-disable-next-line
                isDesktop: event.data.device == "1" ? true : false,
                decarbonise: event.data.decarbonise || false,
                ar: event.data.decarbonise ? event.data.ar : 100
            }, () => {that.loadStill(event)})

                
        }
        else if(event.data && (event.data.image_upload == null && event.data.image_upload !== undefined)){      
            document.querySelector("#bm-creative").innerHTML = "";
            document.querySelector("#bm-creative").style.height = "0px";
            this.setState({
                clickThroughURL: "",
                width: "",
                height: "",
                thirdPartyImpressionURL:"",
                loading: true,
                isDesktop: false
            })

        }
    }

    loadStill = (event) => {
            var that = this;
            this.xyz = new Array(1);

            document.querySelector("#bm-creative").innerHTML = "";
            document.querySelector("#bm-creative").style.height = "0px";
            if (window.bmStill) {
                window.bmStill.destroy();
            }
            /* Remove if exists */
            if (document.getElementById('stillCreativeScript')) {
                document.getElementById('stillCreativeScript').remove();
            };

            var script = document.createElement("script");
            script.type = "text/javascript";
            script.id = "stillCreativeScript";
            script.src = config.scripts.still.script;
            script.async = false;
            script.onload = function () {

                if (event.data["image_upload"]) {
                    var files = event.data["image_upload"];
                    if (FileReader && files && typeof files === "object") {
                        let fr = new FileReader();
                        fr.onload = function () {
                            var obj = {
                                src: fr.result,
                                thirdPartyImpressionURL: event.data["default_impressionURL"]
                            };
                            that.xyz[0] = obj;
                            that.callInit();
                        }
                        fr.readAsDataURL(files);
                    }
                    else if (files) {
                        var obj = {
                            src: files,
                            thirdPartyImpressionURL: event.data["default_impressionURL"]
                        };
                        that.xyz[0] = obj;
                        that.callInit();
                    }
                }
            }
            document.querySelector("#bm-creative").appendChild(script);
    }

    callInit = () => {
        if (window.bmStill) {
            window.bmStill.destroy();
        }
        this.inits();        
    }

    inits = async () => {
        const { clickThroughURL, width, height, thirdPartyImpressionURL, isDesktop, decarbonise, ar} = this.state;
        
        var imageSrc = "";
        if (this.xyz[0]) {
            imageSrc = this.xyz[0].src || "";     
            if(decarbonise){
                imageSrc = await imageCompressor(imageSrc, ar)
            } 
        }
              
        //console.log("comp", compressedImage)
        if (document.getElementById('renderStill')) {
            document.getElementById('renderStill').remove()
        };

        const setStill = document.createElement('script');
        setStill.setAttribute('id', 'renderStill');
        setStill.type = 'text/javascript';
        setStill.async = false;

        var containerWidth = isDesktop ? width : document.getElementsByClassName("bm-creative-preview")[0].clientWidth;
        var dynHeight = (containerWidth * height) / width;
        
        document.querySelector("#bm-creative").style.height = dynHeight + "px";
        

        var renderStill = `window.bmStill = new BMSetStill({
            clickThroughURL: '${clickThroughURL}' , //default clickthrough URL
            parentElement: document.getElementById("bm-creative"), //container element for the Sv element
            width: '${containerWidth}', //width of the Sv element
            height: '${dynHeight}', //height of the Sv element
            imageSrc: '${imageSrc}',//"https://via.placeholder.com/300/0000FF/808080?text=1",
            thirdPartyImpressionURL: '${thirdPartyImpressionURL}',           
            openURLTarget: "_blank", //_top, _parent, _blank
        });       
        `
        setStill.innerHTML = renderStill;

        this.setState({loading : false});
        document.querySelector("#bm-creative").appendChild(setStill);
        
    }

    render() {
        return (
            <PreviewContainer {...this.state}>
                {
                        <React.Fragment>
                         {this.state.loading ? 
                            <div className="w-100 text-center d-flex flex-column justify-content-center h-100">
                                <p className="bm-text_pink">Advertisement</p>
                            </div> : null
                        }
                            <div id="bm-creative" className="bm-creative-full-width"></div>
                        </React.Fragment>
                }
            </PreviewContainer>
        );
    }
}

export default Still;
