import React, { Component } from 'react';
import PreviewContainer from '../../preview/container';

import { config } from '../../../helpers'

class Scratch extends Component {
    constructor(props) {
        super(props)
        this.state = {
            clickThroughURL: "",
            width: "",
            height: "",
            startFrameImage:"",
            endFrameImage: "",
            threshold: "",
            loading: true,
            totalF: 0,
            isDesktop: false
        };
    }

    xyz = [];

    componentDidMount() {
        window.addEventListener('message', this.handleBuilder);
    }

    componentWillUnmount(){
        window.removeEventListener('message', this.handleBuilder);
    }

    handleBuilder = (event) => {
        if(event.data && (event.data.threshold)){
            var that = this;
            this.setState({
                clickThroughURL: event.data.default_landingURL,
                width: event.data.size.width,
                height: event.data.size.height,
                startFrameImage: event.data.image_upload_1,
                endFrameImage: event.data.image_upload_2,
                threshold: event.data.threshold,
                loading: true,
                // eslint-disable-next-line
                isDesktop: event.data.device == "1" ? true : false,
            }, () => { that.loadScratch(event);})

            
        }
    }

    loadScratch = (event) => {
            var count = 0;
            this.xyz = new Array(2);
            var that = this;

            document.querySelector("#bm-creative").innerHTML = "";
            document.querySelector("#bm-creative").style.height = "0px";
            if (window.bmSc) {
                window.bmSc.destroy();
            }
            /* Remove if exists */
            if (document.getElementById('svCreativeScript')) {
                document.getElementById('svCreativeScript').remove();
            };

            var script = document.createElement("script");
            script.type = "text/javascript";
            script.id = "svCreativeScript";
            script.src = config.scripts.scratch.script;
            script.async = false;
            script.onload = function () {

                
               if (event.data["image_upload_1"] && event.data["image_upload_2"]) {
                    that.setState({ totalF: 2 });
               }
               else if (event.data["image_upload_1"] || event.data["image_upload_2"]) {
                    that.setState({ totalF: 1 });
                }             

                if(that.state.totalF === 0){
                    if (window.bmSc) {
                        window.bmSc.destroy();
                    }
                    that.setState({loading: false});
                }

                if (event.data["image_upload_1"]) {
                    let files = event.data["image_upload_1"];
                    if (FileReader && files) {
                        let fr = new FileReader();
                        fr.onload = function () {
                            var obj = {
                                src: fr.result,
                                thirdPartyImpressionURL: event.data["impressionURL_1"] !== "" ? event.data["impressionURL_1"] : null
                            };
                            that.xyz[0] = obj;
                            count++;
                            that.callInit(count);
                        }
                        fr.readAsDataURL(files);
                    }
                }
                if (event.data["image_upload_2"]) {
                    let files = event.data["image_upload_2"];
                    if (FileReader && files) {
                        let fr = new FileReader();
                        fr.onload = function () {
                            var obj = {
                                src: fr.result,
                                thirdPartyImpressionURL: event.data["impressionURL_2"] !== "" ? event.data["impressionURL_2"] : null
                            };
                            that.xyz[1] = obj;
                            count++;
                            that.callInit(count);
                        }
                        fr.readAsDataURL(files);
                    }
                }
            }
            document.querySelector("#bm-creative").appendChild(script);
    }

    callInit = (count) => {
        if (count === this.state.totalF) {
            if (window.bmSc) {
                window.bmSc.destroy();
            }
            this.inits();
        }
    }

    inits = () => {
        const { clickThroughURL, width, height, threshold, isDesktop } = this.state;

        var startFrameImage = "";
        if (this.xyz[0]) {
            startFrameImage = this.xyz[0].src || "";            
        }

        var endFrameImage = "";
        if (this.xyz[1]) {
            endFrameImage = this.xyz[1].src || "";            
        }

        if (document.getElementById('renderScratch')) {
            document.getElementById('renderScratch').remove()
        };

        const setSv = document.createElement('script');
        setSv.setAttribute('id', 'renderScratch');
        setSv.type = 'text/javascript';
        setSv.async = false;

        var containerWidth = isDesktop ? width : document.getElementsByClassName("bm-creative-preview")[0].clientWidth;
        var dynHeight = (containerWidth * height) / width;
        
        document.querySelector("#bm-creative").style.height = dynHeight + "px";

        var renderScratch = `window.bmSc = new BMSetScratch({
            clickThroughURL: '${clickThroughURL}' , //default clickthrough URL
            parentElement: document.getElementById("bm-creative"), //container element for the Sv element
            width: '${containerWidth}', //width of the Sv element
            height: '${dynHeight}', //height of the Sv element
            startFrameImage: '${startFrameImage}',
            endFrameImage: '${endFrameImage}',
            scratchIcon: "https://s3-ap-southeast-2.amazonaws.com/library.bigbuddy.io/templates/images/scratch/scratchIcon.png",
            threshold: '${threshold}',
            thirdPartyImpressionURL: null,
            openURLTarget: "_blank", //_top, _parent, _blank            
        });       
        `
        setSv.innerHTML = renderScratch;

        this.setState({loading : false});
        document.querySelector("#bm-creative").appendChild(setSv);
        
    }

    render() {
        return (
            <PreviewContainer {...this.state}>
                {
                        <React.Fragment>
                         {this.state.loading ? 
                            <div className="w-100 text-center d-flex flex-column justify-content-center h-100">
                                <p className="bm-text_pink">Advertisement</p>
                            </div> : null
                        }
                            <div id="bm-creative"></div>
                        </React.Fragment>
                }
            </PreviewContainer>
        );
    }
}

export default Scratch;
