import React, { Component } from 'react';
import PreviewContainer from '../../preview/container';

import { config } from '../../../helpers'
import imageCompressor from '../../utilities/imageCompressor';

//import Spinner from '../../utilities/Spinner'

class Swipe extends Component {
    constructor(props) {
        super(props)
        this.state = {
            clickThroughURL: "",
            swipeType: "",
            width: "",
            height: "",
            spinOnStart: "",
            autoSwipe: "",
            autoSwipeTimer: 1.5,
            totalF: 0,
            showDefault: false,
            loading: true,
            loop: true,
            isDesktop: false,
            isVertical: false,
            swipeIcon: config.scripts.swipe.defaultIcon,
            decarbonise: false,
            ar: 100
        };
    }

    xyz = [];

    componentDidMount() {
        window.addEventListener('message', this.handleSwipeBuilder);
    }

    componentWillUnmount(){
        window.removeEventListener('message', this.handleSwipeBuilder);
    }

    handleSwipeBuilder = (event) => {
    
        if(event.data && event.data.swipe_type){

            var that = this;
            this.setState({
                clickThroughURL: event.data.default_landingURL,
                swipeType: event.data.swipe_type,
                width: event.data.size.width,
                height: event.data.size.height,
                spinOnStart: event.data.spin,
                autoSwipe: event.data.auto_swipe,
                autoSwipeTimer: event.data.turn_pause,
                totalF: 0,
                //showDefault: false,
                loading: true,
                loop: event.data.loop,
                // eslint-disable-next-line
                isDesktop: event.data.device == "1" ? true : false,
                isVertical: event.data.swipe_direction === "vertical" || event.data.swipe_direction === true ? true : false,
                swipeIcon: event.data.swipe_icon ? event.data.swipe_icon : event.data.swipe_direction === "vertical" ? config.scripts.swipe.defaultIconV : config.scripts.swipe.defaultIcon,
                decarbonise: event.data.decarbonise || false,
                ar: event.data.decarbonise ? event.data.ar : 100
            }, () => {
                that.loadSwipe(event)
            })
      
        }
    }

    loadSetSwipe = (event, count) =>{
        /* Remove if exists */
        if (document.getElementById('swipeInitScript')) {
            document.getElementById('swipeInitScript').remove();
        };

        const setswipe = document.createElement('script');
        setswipe.type = 'text/javascript';
        setswipe.id = "swipeInitScript";
        setswipe.async = false;
        setswipe.src = config.scripts.swipe.setSwipe;
        var that = this;
        setswipe.onload = function () {
            that.callInit(count);
          }
        
        document.querySelector("#bm-creative").appendChild(setswipe);
    }

    loadSwipe = (event) => {
            var count = 0;
            this.xyz = [];
            var that = this;

            const { clickThroughURL, swipeType, isVertical } = this.state;
            
            document.querySelector("#bm-creative").innerHTML = "";
            document.querySelector("#bm-creative").style.height = "0px";           
            if (window.bmSwipeO) {
                window.bmSwipeO.destroy();
            }

            /* Remove if exists */
            if (document.getElementById('swipeCreativeScript')) {
                document.getElementById('swipeCreativeScript').remove();
            };

            var script = document.createElement("script");
            script.type = "text/javascript";
            script.id = "swipeCreativeScript";
            script.src = swipeType === "3d" ? ( isVertical ? config.scripts.swipe.threeDV : config.scripts.swipe.threeD ) : config.scripts.swipe.twoD;
            script.async = false;
            script.onload = function () {

                for (let i = 0; i < 8; i++) {
                    if (event.data["image_upload_" + (i + 1)]) {
                        that.setState({ totalF: that.state.totalF + 1 });
                    }
                }

                if(that.state.totalF === 0){
                    if (window.bmSwipeO) {
                        window.bmSwipeO.destroy();
                    }
                    that.setState({loading: false,
                         //showDefault: true
                        });
                }

                for (let i = 0; i < 8; i++) {
                    if (event.data["image_upload_" + (i + 1)]) {
                        var files = event.data["image_upload_" + (i + 1)];
                        if (FileReader && files && typeof files === "object") {
                            let fr = new FileReader();// eslint-disable-next-line
                            fr.onload = function () {
                                var obj = {
                                    imageSrc: fr.result,
                                    clickThroughURL: event.data["landingURL_" + (i + 1)] !== "" ? event.data["landingURL_" + (i + 1)] : clickThroughURL,
                                    thirdPartyImpressionURL: event.data["impressionURL_" + (i + 1)] !== "" ? event.data["impressionURL_" + (i + 1)] : null
                                };
                                that.xyz[i] = obj;
                                count++;
                                that.loadSetSwipe(event, count);
                                //that.callInit(count);
                            }
                            fr.readAsDataURL(files);
                        }
                        else if (files) {
                            var obj = {
                                imageSrc: files,
                                clickThroughURL: event.data["landingURL_" + (i + 1)] !== "" ? event.data["landingURL_" + (i + 1)] : clickThroughURL,
                                thirdPartyImpressionURL: event.data["impressionURL_" + (i + 1)] !== "" ? event.data["impressionURL_" + (i + 1)] : null
                            };
                            that.xyz[i] = obj;
                            count++;
                            that.loadSetSwipe(event, count);

                        }

                    }

                }
            };
            document.querySelector("#bm-creative").appendChild(script);
    }

    callInit = (count) => {
        if (count === this.state.totalF) {
            if (window.bmSwipeO) {
                window.bmSwipeO.destroy();
            }
            this.inits();
        }
    }

    inits = async () => {
        const { clickThroughURL, swipeType, width, height, spinOnStart, autoSwipe, autoSwipeTimer, loop, isDesktop, swipeIcon, decarbonise, ar } = this.state;

        var swipeImage = [];
        for (let i = 0; i < this.xyz.length; i++) {
            if (this.xyz[i]) {
                if(decarbonise){
  
                    let tempImg = await imageCompressor(this.xyz[i].imageSrc, ar)
                    this.xyz[i].imageSrc = tempImg
                   
                } 
               
                swipeImage.push(this.xyz[i]);
                
            }
        }

        if (document.getElementById('renderSwipe')) {
            document.getElementById('renderSwipe').remove()
        };


        const setswipe = document.createElement('script');
        setswipe.setAttribute('id', 'renderSwipe');
        setswipe.type = 'text/javascript';
        setswipe.async = false;

        var containerWidth = isDesktop ? width : document.getElementsByClassName("bm-creative-preview")[0].clientWidth;
        var dynHeight = (containerWidth * height) / width;
        
        document.querySelector("#bm-creative").style.height = dynHeight + "px";
        
        var swipeIconEnabled = true;
        var arrowsEnabled = false;
        if(swipeType === "2d" && isDesktop){
            swipeIconEnabled = false;
            arrowsEnabled = true;
        }

        var renderSwipe = `window.bmSwipeO = new BMSetSwipe({
            swipeType: '${swipeType}', //Can be 2d or 3d
            clickThroughURL: '${clickThroughURL}' , //default clickthrough URL
            parentElement: document.getElementById("bm-creative"), //container element for the swipe element
            width: '${containerWidth}', //width of the swipe element
            height: '${dynHeight}', //height of the swipe element
            spinOnStart: '${spinOnStart}', //start spinning first time only        
            autoSwipe: {
                enabled: '${autoSwipe}',
                delay: ${autoSwipeTimer}
            },
            loop: '${loop}',
            swipeIcon: {
                enabled: '${swipeIconEnabled}',
                src: '${swipeIcon || config.scripts.swipe.defaultIcon}'
            },         
            arrows:{ 
                enabled: '${arrowsEnabled}',
                leftIcon: '${config.scripts.swipe.leftArrowIcon}',
                rightIcon: '${config.scripts.swipe.rightArrowIcon}'
            },   
            openURLTarget: "_blank", //_top, _parent, _blank
            faces: ${JSON.stringify(swipeImage)}
        });`
        //console.log(renderSwipe)
        setswipe.innerHTML = renderSwipe;

        this.setState({loading : false});
        document.querySelector("#bm-creative").appendChild(setswipe);

        
    }

    render() {
        return (
            <PreviewContainer {...this.state}>
                {  
                    <React.Fragment>
                        {this.state.loading ? 
                            <div className="w-100 text-center d-flex flex-column justify-content-center h-100">
                                <p className="bm-text_pink">Advertisement</p>
                            </div> : null
                        }
                        <div id="bm-creative"></div>
                    </React.Fragment>
                }
            </PreviewContainer>
        );
    }
}

export default Swipe;
