import imageCompression from 'browser-image-compression';

function base64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

function readFile(file) {
  return new Promise((resolve, reject) => {
    var fr = new FileReader();
    fr.onload = () => {
      resolve(fr.result)
    };
    fr.onerror = reject;
    fr.readAsDataURL(file);
  });
}
async function imageCompressor(base64Image, ar = 100) {
  let imageFile = '';

  if (typeof base64Image === "string") {
    await fetch(base64Image)
      .then(response => response.blob())
      .then(blob => {
        imageFile = blob
      });
  } else {
    let block = base64Image.split(";");
    // Get the content type of the image
    let contentType = block[0].split(":")[1];// In this case "image/gif"
    // get the real base64 content of the file
    let realData = block[1].split(",")[1];// In this case "R0lGODlhPQBEAPeoAJosM...."
    imageFile = await base64toBlob(realData, contentType);

  }



  const options = {
    //maxSizeMB: 1,
    //maxWidthOrHeight: 1920,
    useWebWorker: true,
    alwaysKeepResolution: true,
    initialQuality: (ar / 100).toFixed(2)
  }
  try {
    const compressedFile = await imageCompression(imageFile, options);
    //console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
    //console.log(`compressedFile size ${compressedFile.size / 1024 } KB`); // smaller than maxSizeMB

    return await readFile(compressedFile);
  } catch (error) {
    console.log(error);
  }

}

export default imageCompressor;