
import React from 'react'
import classnames from 'classnames'
import ReactElementToJSXString from 'react-element-to-jsx-string';
import './index.scss'

const Content = [
    {
        header: `NASA is to launch a 'priority' mission to explore mysterious domes on the moon as part of its Artemis lunar exploration plans`,
        image: `/images/template/dailymail/dailymail-1.png`,
        desciption: `The US space agency has unveiled plans for a rover to visit the Gruithuisen Domes, geological features which experts believe were formed by a sticky magma rich in silica, similar in composition to granite. On Earth, formations like these need oceans of liquid water and plate tectonics to form, but without such ingredients on the moon, experts are unsure how the domes formed and evolved over time. The Lunar Vulkan Imaging and Spectroscopy Explorer (Lunar-VISE) investigation, slated for 2025, will consist of a suite of five instruments. Two of these will be mounted on a stationary lander and three mounted on a mobile rover to be provided as a service by a Commercial Lunar Payload Services (CLPS) initiative vendor.`,
        meta: `/images/template/dailymail/dailymail-meta-1.png`,
        desktopMeta: '/images/template/dailymail/dailymail-meta-desktop-1.png',

        subText: `Arietids meteor shower will peak during the DAYTIME on Tuesday - filling the sky with up to 200 invisible shooting stars per hour`,
        subImage: `/images/template/dailymail/dailymail-sub-1.png`,
    },
    {
        header: `Ancient Roman road discovered in Wales may follow the route taken by prehistoric people transporting the bluestones that built STONEHENGE, archaeologist claims`,
        image: `/images/template/dailymail/dailymail-2.png`,
        desciption: `A newly discovered ancient road in Wales may have been the route taken by prehistoric people transporting the stones that built road that crosses through the Preseli Hills in Pembrokeshire also revealed that Roman people ventured deeper into the country than previously thought. An archaeologist from Oxford University first found sections of the the 6.8 mile (11 km) road buried in peat or sunken into the ground last month. Up to 16.4 feet (5 m) in width in places, it followed straight routes and worked around hill contours in a way that is typically Roman.`,
        meta: `/images/template/dailymail/dailymail-meta-1.png`,
        desktopMeta: '/images/template/dailymail/dailymail-meta-desktop-1.png',
        
        subText: `Ride on a flyby of Jupiter! NASA's Juno spacecraft soars just 2,000 miles above the gas giant's cloud tops in fascinating clip`,
        subImage: `/images/template/dailymail/dailymail-sub-2.png`,
    },
    {
        header: `How does YOUR city stack up? Experts reveal the top UK cities to own an electric car based on cost, ease of charging and clean air zones - with London rated the best and Cardiff the worst`,
        image: `/images/template/dailymail/dailymail-3.png`,
        desciption: `New research from Saga Insurance has assessed the availability of charging points, accessibility to off-street parking, cost of charging and clean air zones in cities across the UK, to reveal the top places to own an electric car. The findings suggest that Greater London is the most electric-friendly city, while Cardiff is the least electric-friendly.`,
        meta: `/images/template/dailymail/dailymail-meta-1.png`,
        desktopMeta: '/images/template/dailymail/dailymail-meta-desktop-1.png',
        
        subText: `Meet Ross 508b: Scientists discover a 'SUPER EARTH' exoplanet four times bigger than our own planet orbiting a star 36.5 light-years away`,
        subImage: `/images/template/dailymail/dailymail-sub-3.png`,
    },
    {
        header: `How dogs see the world: Incredible images of popular UK tourist spots reveal how pups only see in yellow and blue and have up to eight times more blurred vision than humans`,
        image: `/images/template/dailymail/dailymail-4.png`,
        desciption: `Fascinating new photos developed by a UK vet show how our pooches see the world compared to humans. Rhian Rochford, from PocketVet, explained that dogs have dichromatic vision, meaning they only see in blue and yellow, and have four to eight times more blurred vision than humans. The images below of famous tourist spots show what we see (left) compared to dogs (right).`,
        meta: `/images/template/dailymail/dailymail-meta-1.png`,
        desktopMeta: '/images/template/dailymail/dailymail-meta-desktop-1.png',
        
        subText: `Is THIS how the giraffe got its long neck? African mammal's spine stretched to serve as a WEAPON - and not to help it reach high foliage, study claims `,
        subImage: `/images/template/dailymail/dailymail-sub-4.png`,
    },
    {
        header: `Is Maverick too OLD to be a fighter pilot? Tom Cruise's Top Gun character's age wouldn't stop him - but his lifestyle might, expert says`,
        image: `/images/template/dailymail/dailymail-5.png`,
        desciption: `EXCLUSIVE: The moment that Top Gun fans have waited more than 30 years for is finally here, with the UK launch of Top Gun: Maverick today. Maverick's age is a key plot point in the film, with colleagues questioning whether, at 57-years-old, he is too old to be a test and fighter pilot. So does Maverick really have what it takes to be still be a fighter pilot after 30 years of service? Here we delve into the details and reveal how while his age might not stop him, his lifestyle might.`,
        meta: `/images/template/dailymail/dailymail-meta-1.png`,
        desktopMeta: '/images/template/dailymail/dailymail-meta-desktop-1.png',
        
        subText: `'Alexa, how do you pronounce scone?' Amazon's smart assistant finally settles the debate in celebration of the Queen's Platinum Jubilee - and claims it should rhyme with 'gone'`,
        subImage: `/images/template/dailymail/dailymail-sub-5.png`,
    },
    {
        header: `Hundreds of Ancient Egyptian artefacts including 2,500-year-old painted sarcophagi with mummies inside and bronze statues of deities unearthed at the necropolis of Saqqara go on display near Cairo`,
        image: `/images/template/dailymail/dailymail-6.png`,
        desciption: `The artefacts were showcased on Monday at a makeshift exhibit at the feet of the Step Pyramid of Djoser - the oldest pyramid in Egypt - in Saqqara, 15 miles southwest of the Egyptian capital. The stunning collection includes 250 painted sarcophagi (pictured) with well-preserved mummies inside, as well as 150 bronze statues of ancient deities. The finds will be moved for a permanent exhibit at the new Grand Egyptian Museum, a major project still under construction near the Giza Pyramids and set to be opened in November this year.`,
        meta: `/images/template/dailymail/dailymail-meta-1.png`,
        desktopMeta: '/images/template/dailymail/dailymail-meta-desktop-1.png',
        
        subText: `Noise pollution can affect children's MEMORY: Kids who attend schools with more traffic show slower cognitive development, study warns`,
        subImage: `/images/template/dailymail/dailymail-sub-6.png`,
    },


]

const renderArticle = (content, top = false) => {
    return (
        <div className={classnames('col-12 template-article', top ? "p-top" : "")}>
            <h2 className="headline">
                {content.header}
            </h2>
            <div className='article-preview-image'>
                <img src={content.image} alt="dailymail" />

            </div>
            <div className="article-preview-description">
                <p>
                    {content.desciption}
                </p>
            </div>
            <img className="w-100" src={content.meta} alt="dailymail" />

        </div>
    )
}

const renderDesktopArticle = (content, top = false) => {
    return (
        <>
            {top ? <div className='article article-small'>
                <h2 >
                    {content.header}
                </h2>
                <div className='article-image'>
                    <img src={content.image} alt="dailymail" />

                </div>
                <div className="article-preview-description">
                    <p>
                        {content.desciption}
                    </p>
                </div>
                {/* <img className="w-100" src={content.desktopMeta} alt="dailymail" /> */}

            </div> : <div className='article'>
                <h2 >
                    {content.header}
                </h2>
                <div className='article-image'>
                    <img src={content.image} alt="dailymail" />

                </div>
                <div className="article-preview-description">
                    <p>
                        {content.desciption}
                    </p>
                </div>
                <img className="w-100" src={content.desktopMeta} alt="dailymail" />

            </div>}
        </>

    )
}

const dailyMailHeader = (desktop = false) => {
    return (
        <img className={desktop ? "menu" : "template-header"} src={desktop ? "/images/template/dailymail/dailymail-header-desktop.png" : "/images/template/dailymail/dailymail-header.png"} alt="dailymail" />
    )
}
//eslint-disable-next-line
const dailyMailFooter = (desktop = false) => {
    return (
        <>
            {desktop ? <img className="menu" src="/images/template/dailymail/dailymail-header-desktop.png" alt="dailymail" />
                :
                <img className="template-header" src="/images/template/dailymail/dailymail-header.png" alt="dailymail" />
            }
        </>
    )
}


export const dailyMailMobile = (pos, isInterstitialCreative = false, isScrolloCreative = false, isScrolloDesktopCreative = false, isMobileSkinCreative = false, renderAd, height) => {
    let bottom = height && height <= 150
    return (
        <>
            {/* {pos.match(/^(top|above)$/ig) && !isInterstitialCreative ? renderAd() : null} */}
            <div className={classnames("bm-creative-preview-ad-content dailymail", isScrolloCreative ? "position-relative" : "position-relative", isScrolloDesktopCreative ? "ad-content-scrollo-desktop" : "")}>
                {dailyMailHeader()}
                {renderArticle(Content[0], true)}
                {renderArticle(Content[1])}
                {renderArticle(Content[2])}
                {/* {!isInterstitialCreative ? renderScrollDownHint(true) : null} */}


                {!bottom && pos.match(/^(center|middle)$/ig) && !isInterstitialCreative ? renderAd() : null}
                {renderArticle(Content[3])}
                {renderArticle(Content[4])}
                {renderArticle(Content[5])}

                {bottom && !isInterstitialCreative ? <div className='sticky-ad sticky-ad-mobile'>
                    {renderAd()}

                </div> : null}
                {/* {(isScrolloCreative || isMobileSkinCreative || window.innerHeight > 650) && renderArticle(false)}
                {(isScrolloCreative || isMobileSkinCreative || window.innerHeight > 650) && renderArticle(false)} */}
                {/* <div id="adhesive-content"></div> */}
            </div>
        </>
    )
}

const renderSub = (content) => {
    return (
        <li>
            <img src={content.subImage} alt="sub" />
            <span>{content.subText}</span>
        </li>
    )

}

const renderSplitter = (content = 0) => {
    return (
        <div className='splitter'>
            <div className='column-split first-column'>
                {renderDesktopArticle(Content[content], true)}
            </div>
            <div className='column-split'>
                {renderDesktopArticle(Content[content+1], true)}
            </div>
        </div>
    )
}

export const dailyMailDesktop = (adPos, width, height, isSkinCreative, isScrolloCreative, renderAd) => {
    return (
        <React.Fragment>
            <div id="bm-desktop-container" className="bm-creative-preview-ad-content bm-desktop-container row my-4 daily-mail-desktop">
                <div className='preview-container daily'>
                    <div id="page-container">
                        {dailyMailHeader(true)}
                        <div id='content'>
                            {adPos === "top" &&
                                <div className={classnames('billboard-container mt-2', isSkinCreative ? 'h-0' : '')}>
                                    <div className='sticky-container'>
                                        {renderAd(false, width, isSkinCreative)}


                                    </div>

                                </div>}
                            {renderDesktopArticle(Content[0])}
                            <div className='split'></div>
                            <div>
                                <div className='alpha'>
                                    {renderDesktopArticle(Content[1])}
                                    {renderSplitter(2)}
                                    {renderSplitter(3)}

                                </div>
                                <div className='beta'>
                                    <div className='femail'>
                                        <div className='puff cleared'>
                                            <h3>Don't Miss</h3>
                                        </div>
                                        <ul className="link-bogr2">
                                            {renderSub(Content[0])}
                                            {renderSub(Content[1])}
                                            {renderSub(Content[2])}
                                            {adPos === "center" && isScrolloCreative && renderAd(false, width, isSkinCreative)}
                                            {renderSub(Content[3])}
                                            {renderSub(Content[4])}
                                            {renderSub(Content[5])}
                                        </ul>
                                    </div>
                                </div>



                            </div>

                        </div>
                    </div>


                    <div id="sky-left-container">
                        <div className='sticky-container'>
                            {adPos === "center" && !isScrolloCreative && renderAd(false, width, isSkinCreative)}
                        </div>
                    </div>
                    <div id="sky-right-container"></div>

                </div>





            </div>
        </React.Fragment>
    )
}


export const dailyMailScroller = (isDesktop, browserWidth, dynHeight) => {
    return (
        <>
            {isDesktop ?
                (
                    <>

                    </>
                )
                : (
                    <div className="bm-creative-preview-ad-content">
                        {dailyMailHeader()}
                        {renderArticle(Content[0], true)}
                        {renderArticle(Content[1])}
                        {renderArticle(Content[2])}
                        <div style={{ width: browserWidth + "px", height: dynHeight + "px" }}> </div>
                        {renderArticle(Content[3])}
                        {renderArticle(Content[4])}
                        {renderArticle(Content[5])}
                    </div>
                )}
        </>)
}



export const dailyMailMobileSkin = (src = "") => {

    return (
        `
        <div 
        class="bm-creative-preview">
        <div class="bm-creative-preview-ad-content dailymail">
          ${ReactElementToJSXString(dailyMailHeader(false)).replace(/classname/ig, "class")}
          ${ReactElementToJSXString(renderArticle(Content[0], true)).replace(/classname/ig, "class")}
          ${ReactElementToJSXString(renderArticle(Content[1])).replace(/classname/ig, "class")}
          ${ReactElementToJSXString(renderArticle(Content[2])).replace(/classname/ig, "class")}
          ${ReactElementToJSXString(renderArticle(Content[3])).replace(/classname/ig, "class")}

          <div id="bm-creative-container" class="bm-ad col-12">
            <div id="bm-preview-iframe-container" class="mx-auto"> ${src} </div>
          </div>           
          ${ReactElementToJSXString(renderArticle(Content[4])).replace(/classname/ig, "class")}
          ${ReactElementToJSXString(renderArticle(Content[5])).replace(/classname/ig, "class")}         
        </div> 
      </div>`
    )
}


export const dailyMailCss = `.template-header {
    max-width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    user-select: none;
    -webkit-user-drag: none;
  }
  .w-100{ width:100%; }
  .template-header img {
    max-width: 100%;
    position: fixed;
    top: 0px;
    z-index: 9;
    user-select: none;
    -webkit-user-drag: none;
  }
  
  .bm-creative-preview-ad-content.dailymail {
    letter-spacing: initial !important;
  }
  .bm-creative-preview-ad-content.dailymail .p-top {
    
  }
  .bm-creative-preview-ad-content.dailymail .sticky-ad {
    position: fixed;
    bottom: 0 !important;
    left: 0;
    width: 100%;
    z-index: 1002;
    text-align: center;
    display: flex;
    justify-content: center;
    top: auto;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 20px 0 20px 0;
    box-shadow: 0 0 5px 0 !important;
    border-radius: 0px 0px 18px 18px;
  }
  .bm-creative-preview-ad-content.dailymail img {
    user-select: none;
    -webkit-user-drag: none;
  }
  .bm-creative-preview-ad-content.dailymail .template-article {
    margin: 0px;
    padding: 2px;
  }
  .bm-creative-preview-ad-content.dailymail .template-article .headline {
    display: block;
    padding: 10px 8px;
    font-size: 22px;
    font-weight: bold;
    line-height: 26px;
    color: #004db3;
    font-family: system-ui, -apple-system, sans-serif;
  }
  .bm-creative-preview-ad-content.dailymail .template-article .article-preview-image img {
    position: relative;
    max-width: 100%;
    user-select: none;
    -webkit-user-drag: none;
  }
  .bm-creative-preview-ad-content.dailymail .template-article .article-preview-description {
    margin: 0;
    padding: 0;
  }
  .bm-creative-preview-ad-content.dailymail .template-article .article-preview-description p {
    margin: 8px 0 4px;
    display: block;
    padding: 0 8px;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.4em;
    color: #000;
    max-height: 131.6px;
    overflow: hidden;
    letter-spacing: normal;
  }
  

  `