import React, { Component } from 'react';
import PreviewContainer from '../../../../preview/container';

import { config } from '../../../../../helpers'

class TruSkinDesktopClassic extends Component {
    constructor(props) {
        super(props)
        this.state = {
            clickThroughURL: "",
            width: "",
            height: "",
            totalF: 0,
            loading: true,
            isDesktop: false
        };
    }

    xyz = [];

    componentDidMount() {
        window.addEventListener('message', this.handleAdBuilder);
    }

    componentWillUnmount(){
        window.removeEventListener('message', this.handleAdBuilder);
    }

    handleAdBuilder = (event) => {
        
        if(event.data && (event.data.image_upload_1 || event.data.image_upload_2 || event.data.image_upload_3 || event.data.image_upload_4)){
            var that = this;
            this.setState({
                clickThroughURL: event.data.default_landingURL,
                width: event.data.size.width,
                height: event.data.size.height,
                totalF: 0,
                loading: true,
                // eslint-disable-next-line
                isDesktop: event.data.device == "1" ? true : false,
            }, () => {that.loadAd(event)})          
        }
    }

    loadAd = (event) => {
            var count = 0;
            this.xyz = [];
            var that = this;

            const { clickThroughURL } = this.state;
            
            document.querySelector("#bm-creative").innerHTML = "";
            document.querySelector("#bm-creative").style.height = "0px";           
            if (window.bmTruskinDC) {
                window.bmTruskinDC.destroy();
            }

            /* Remove if exists */
            if (document.getElementById('AdCreativeScript')) {
                document.getElementById('AdCreativeScript').remove();
            };

            var script = document.createElement("script");
            script.type = "text/javascript";
            script.id = "AdCreativeScript";
            script.src = config.scripts.truskin.desktop.classic.script;
            script.async = false;
            script.onload = function () {

                for (let i = 0; i < 4; i++) {
                    if (event.data["image_upload_" + (i + 1)]) {
                        that.setState({ totalF: that.state.totalF + 1 });
                    }
                }

                if(that.state.totalF === 0){
                    if (window.bmTruskinDC) {
                        window.bmTruskinDC.destroy();
                    }
                    that.setState({ loading: false });
                }

                for (let i = 0; i < 4; i++) {
                    //if (event.data["image_upload_" + (i + 1)]) {
                        var files = event.data["image_upload_" + (i + 1)];
                        if (FileReader && files) {
                            let fr = new FileReader();// eslint-disable-next-line
                            fr.onload = function () {
                                var obj = {
                                    imageSrc: fr.result,
                                    clickThroughURL: event.data["landingURL_" + (i + 1)] !== "" ? event.data["landingURL_" + (i + 1)] : clickThroughURL,
                                    thirdPartyImpressionURL: event.data["impressionURL_" + (i + 1)] !== "" ? event.data["impressionURL_" + (i + 1)] : null
                                };
                                that.xyz[i] = obj;
                                count++;
                                that.callInit(count);
                            }
                            fr.readAsDataURL(files);
                        }
                    //}

                }
            };
            document.querySelector("#bm-creative").appendChild(script);
    }

    callInit = (count) => {
        if (count === this.state.totalF) {
            if (window.bmTruskinDC) {
                window.bmTruskinDC.destroy();
            }
            this.inits();
        }
    }

    inits = () => {
        const { clickThroughURL } = this.state;

        var AdImage = [];
        for (let i = 0; i < this.xyz.length; i++) {
            //if (this.xyz[i]) {
                AdImage.push(this.xyz[i]);
            //}
        }

        if (document.getElementById('renderAd')) {
            document.getElementById('renderAd').remove()
        };


        const setAd = document.createElement('script');
        setAd.setAttribute('id', 'renderAd');
        setAd.type = 'text/javascript';
        setAd.async = false;

        var containerWidth = 0; //isDesktop ? width : document.getElementsByClassName("bm-creative-preview")[0].clientWidth;
        var dynHeight = 0; //(containerWidth * height) / width;
        
        document.querySelector("#bm-creative").style.height = dynHeight + "px";
        var headerElement = AdImage[0] || "";
        var leftElement = AdImage[1] || "";
        var rightElement = AdImage[2] || "";
        var footerElement = AdImage[3] || "";

        var renderAd = `window.bmTruskinDC = new BMSetTruSkin({
            clickThroughURL: '${clickThroughURL}' , //default clickthrough URL
            parentElement: document.getElementById("bm-creative"), //container element for the Ad element
            previewContainerElement: document.getElementById("bm-creative-preview"),
            width: '${containerWidth}', //width of the Ad element
            height: '${dynHeight}', //height of the Ad element
            thirdPartyImpressionURL: null,        
            openURLTarget: "_blank", //_top, _parent, _blank
            headerElement: ${JSON.stringify(headerElement)},
            leftElement:${JSON.stringify(leftElement)},
            rightElement: ${JSON.stringify(rightElement)},
            footerElement: ${JSON.stringify(footerElement)}
        });`
        //console.log(renderAd)
        setAd.innerHTML = renderAd;

        this.setState({loading : false});
        document.querySelector("#bm-creative").appendChild(setAd);
        
    }

    render() {
        return (
            <PreviewContainer {...this.state}>
                {  
                    <React.Fragment>
                        {this.state.loading ? 
                            <div className="w-100 text-center d-flex flex-column justify-content-center h-100">
                                <p className="bm-text_pink">Advertisement</p>
                            </div> : null
                        }
                        <div id="bm-creative"></div>
                    </React.Fragment>
                }
            </PreviewContainer>
        );
    }
}

export default TruSkinDesktopClassic;
