import React, { useState } from 'react'
import styled from 'styled-components'


const Container = styled.div`
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); 
    position: absolute; 
    z-index: 2147483002; 
    box-sizing: border-box; 
    padding: 10px; 
    cursor: pointer; 
    width: 45px;
    height: 45px; 
    display: block; 
    top: 0px; 
    right: 0px; 
    opacity: 1;
`;
const Child = styled.div`
    color: rgb(255, 255, 255); 
    border: 2px solid rgb(255, 255, 255); 
    border-radius: 50%; 
    background: rgba(33, 33, 33, 0.5); 
    position: relative; 
    text-align: center; 
    width: 100%; 
    height: 100%; 
    padding: 8%; 
    box-sizing: border-box; 
    font-family: Arial; 
    font-size: 18px; 
    line-height: 18px;
`;
const CloseButton = (props) => {
    const [visible, setVisible] = useState(false)

    setTimeout(() => {
        setVisible(true)
    }, 3000);

    return (
        <>
            {!visible ? null :
                <Container id="close-button" className="close-button-container" onClick={() => {
                    let el = document.getElementById("bm-creative")
                    let elB = document.getElementById("close-button")
                    if (el && elB) {
                        el.style.visibility = "hidden"
                        elB.style.visibility = "hidden"
                    }
                }} ><Child className="close-button">×</Child></Container>
            }
        </>

    )
}

export default CloseButton