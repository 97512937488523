import { DeviceConstants } from "./device.constants";
import { IPHONE_X } from "../../components/devices/device-list"

export const saveDeviceSelected = (id) => dispatch => {

    dispatch({
        type: DeviceConstants.SELECTED_DEVICE,
        payload: id
    });

}


export const resetDeviceSelected = (id) => dispatch => {

    dispatch({
        type: DeviceConstants.RESET_SELECTED_DEVICE,
        payload: IPHONE_X
    });

}

export const refreshPreview = () => dispatch => {

    dispatch({
        type: DeviceConstants.REFRESH_PREVIEW
    });

}

