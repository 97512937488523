import React, { Component } from 'react';
import PreviewContainer from '../../preview/container';
import { getPreviewContainerWidth, isMobile, isScroller, isInterstitial, isScrollo, isResponsive, getGeneratedPageURL, isScrolloDesktop, isSkin, isSwing } from '../../utilities/customFunctions'
//import postscribe from 'postscribe'

class TagPreview extends Component {
    constructor(props) {
        super(props)
        this.state = {
            previewTag: "",
            loading: true,
            width: 0,
            height: 0,
            isDesktop: false,
            format_type: "Infeed",
            adDimension: "",
            ad_type: ""
        };
    }

    componentDidMount() {
        window.addEventListener('message', this.handleTagPreview);
    }

    /*  componentWillUnmount() {
         window.removeEventListener('message', this.handleTagPreview);
     } */

    getUA(device) {
        let userAgent = '';
        // eslint-disable-next-line
        if (device == "1") {
            userAgent = "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_5) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/75.0.3770.100 Safari/537.36";
        } else {
            userAgent = "Mozilla/5.0 (Linux; Android 5.0; SM-G900P Build/LRX21T) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Mobile Safari/537.36";
        }
        return {
            get: function () { return userAgent; }
            , configurable: true
        };
    }

    setUserAgent(window, device) {

        const userAgentProp = this.getUA(device);
        try {
            Object.defineProperty(window.navigator, 'userAgent', userAgentProp);
        } catch (e) {
            window.navigator = Object.create(navigator, {
                userAgent: userAgentProp
            });
        }
    }


    handleTagPreview = (event) => {
        if (event.data && event.data.previewTag) {
       
            this.setState({
                previewTag: event.data.previewTag,
                loading: true,
                width: event.data.adWidth,
                height: event.data.adHeight,
                // eslint-disable-next-line
                isDesktop: event.data.environmentCode == "1" ? isScrolloDesktop(true, event.data.format) ? false : true : false,
                format_type: event.data.adType,
                adDimension: event.data.adDimension,
                ad_type: event.data.format,
                ad_server: event.data.ad_server
            }, () => {
                let elB = document.getElementById("close-button")
                if (elB) {
                    elB.style.visibility = "visible"
                }
                document.getElementById("bm-preview-iframe-container").innerHTML = "";
                document.getElementById("bm-creative-preview").style.display = "block";
                let isScrollerCreative = isScroller(event.data.adType);
                let isInterstitialCreative = isInterstitial(event.data.adType);
                let isScrolloCreative = isScrollo(event.data.format);
                let isSkinCreative = isSkin(event.data.format)
                let isMobileSkinCreative = isSkinCreative && !this.state.isDesktop;
                let isSwingCreative = isSwing(event.data.format)

                if(isResponsive(event.data.adDimension) || isMobileSkinCreative || isSwingCreative){
                    isInterstitialCreative = true;
                }
                // eslint-disable-next-line
                var browserWidth = event.data.environmentCode == "0" ? getPreviewContainerWidth(event.data.adWidth) : event.data.adWidth;
                var dynHeight = Math.floor((browserWidth * event.data.adHeight) / event.data.adWidth);
                 // eslint-disable-next-line
                let isDesktop = event.data.environmentCode == "1" ? true : false;

                if(isScrollerCreative && !isDesktop){
                    browserWidth = document.getElementsByClassName("bm-creative-preview")[0].clientWidth;
                    dynHeight = document.getElementsByClassName("bm-creative-preview")[0].clientHeight;
                }
                else if(isInterstitialCreative && !isDesktop){
                    if (document.getElementById("bb-device-frame-body")) {
                        document.getElementById("bb-device-frame-body").style.overflowY = "hidden";
                        browserWidth = document.getElementById("bb-device-frame-body").clientWidth;
                        dynHeight = document.getElementById("bb-device-frame-body").clientHeight;
                    }
                    else if (document.getElementById("bb-device-frame-content-body")) {
                        document.getElementById("bb-device-frame-content-body").style.overflowY = "hidden";
                        document.getElementById("bm-creative-container").style.borderRadius = "30px";
                        document.getElementById("bm-creative-container").style.overflow = "hidden";
                        browserWidth = document.getElementById("bb-device-frame-content-body").clientWidth;
                        dynHeight = document.getElementById("bb-device-frame-content-body").clientHeight;
                    }
                    else {
                        browserWidth = window.innerWidth;
                        dynHeight = window.innerHeight;
                    }
                }
                else if (isScrolloCreative && !isDesktop){
                    document.getElementById("bm-creative-preview").classList.add("bm-creative-preview-scrollo");
                } 


                // eslint-disable-next-line
                let tagWithoutTrackings = event.data.previewTag.replace(/(^")|("$)/g, '').replace(/\<ins/igm, `<ins \n\t data-stay-in-container="true" data-tracking="no" data-trackingjs="no" data-preview-site="true" data-debug="true" data-cachebuster="${Date.now()}" \n\t`);
                let htmlCode = getGeneratedPageURL(tagWithoutTrackings, event.data.adWidth === 320, event.data.ad_server, isInterstitialCreative, isMobileSkinCreative, isSwingCreative);

                let iframeContent = `
                <iframe width=${browserWidth} 
                    height=${dynHeight} id="bm-creative" class="pointer-auto mx-auto" scrolling="no" frameborder="0" src='${htmlCode}' > 
                </iframe>             
                `
                if(isMobileSkinCreative || isSwingCreative){
                    iframeContent = `
                    <iframe width=${browserWidth} 
                        height=${dynHeight} id="bm-creative" name="native" class="pointer-auto mx-auto" scrolling="yes" frameborder="0" src='${htmlCode}' > 
                    </iframe>             
                    `
                }
                document.getElementById("bm-preview-iframe-container").innerHTML = iframeContent;
                
                this.setState({loading: false});
                /* var that = this;
                postscribe("#bm-preview-iframe-container", tagWithoutTrackings,
                {
                    done: function () {
                        that.setState({loading: false});
                    }
                }); */

                if (!isMobile()) {
                    this.setUserAgent(document.querySelector('#bm-creative').contentWindow, event.data.environmentCode);
                } 
            })
        }
        else if(event.data && event.data.reset){
            document.getElementById("bm-preview-iframe-container").innerHTML = "";
            document.getElementById("bm-creative-preview").style.display = "block";
        }
    }

    render() {
        return (
            <PreviewContainer {...this.state}>
                {<div id="bm-preview-iframe-container"></div>}
            </PreviewContainer>
        );
    }
}

export default TagPreview;
