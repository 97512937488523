
import React from 'react'
import classnames from 'classnames'
import ReactElementToJSXString from 'react-element-to-jsx-string';
import './index.scss'

const Content = [
    {
        id: 1,
        header: `Celebrating NRL team’s greatest Indigenous player`,
        image: `/images/template/7news/subarticle-1.jpg`,
        writer: `Joey Riordan`,
        category: `Rugby League`
    },
    {
        id: 2,
        header: `Socceroos ready to make history: Arnold`,
        image: `/images/template/7news/subarticle-2.jpg`,
        writer: `Ed Jackson`,
        category: `Soccer`
    },
    {
        id: 3,
        header: `Surf competition on hold in El Salvador`,
        image: `/images/template/7news/subarticle-3.jpg`,
        writer: `Justin Chadwick`,
        category: `Surfing`
    },
    {
        id: 4,
        header: `NBA's three-game series to decide champs`,
        image: `/images/template/7news/subarticle-4.jpg`,
        writer: `Digital Staff`,
        category: `NBA`
    },
]
const sevenNewsHeader = (desktop = false) => {
    return (
        desktop ? 
            <div className="desktop-header bottom-shadow">
                <div className="bg-gray">
                    <div className="d-flex justify-content-between desktop-container">
                    <div className="col-4">
                        <img className="logo" src="/images/template/7news/logo.png" alt="7News" />
                    </div>
                    <div className="col-4 text-center">
                        <img className="logo-sport" src="/images/template/7news/logo-sport.png" alt="7News" />
                    </div>
                    <div className="col-4 text-right">
                        <div className="d-flex flex-row-reverse">
                            <i className="fas fa-bars"></i>
                            <div className="date">Wednesday, 15 June 2022</div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="text-center header-tag">
                    <span>Local</span>
                    <span>Sport</span>
                    <span>Lifestyle</span>
                    <span>Entertaiment</span>
                    <span>Tech</span>
                    <span>Politics</span>
                    <span>Finance</span>
                    <span>World</span>
                    <span>Weather</span>
                    <span>Contact Us</span>
                </div>
            </div>
            : <img className={"template-image header bottom-shadow"} src={"/images/template/7news/header.png"} alt="7News" />
    )
}

const sevenNewsFooter = (desktop = false, isSkinCreative = false, isAdTop = false) => {
    return (
        desktop ? 
            <div className="desktop-footer">
                    <div className={classnames("d-flex desktop-container", isAdTop ? "px-4" : "justify-content-center")}>
                        <img className={isSkinCreative ? "template-image p-2 px-4" : ""} src="/images/template/7news/footer-desktop.png" alt="7News" />
                    </div>
                    <div className="bg-red mt-5">
                        <div className={classnames("d-flex desktop-container", isAdTop ? "" : "justify-content-center")}>
                            <div className="footer-tag text-left">
                                <span>FAQS</span>
                                <span>TERMS & CONDITIONS</span>
                                <span>PRIVACY POLICY</span>
                                <span>SUBMIT FEEDBACK</span>
                            </div>
                        </div>
                    </div>
            </div>
            :
            <div>
                <img className="template-image" src="/images/template/7news/footer.png" alt="7News" />
                <img className="template-image" src="/images/template/7news/footer-2.png" alt="7News" />
            </div>
        
    )
}

const sevenNewsSubTitle = (desktop = false) => {
    return (
        <div className={desktop ? "sub-title desktop" : "sub-title"}>
            {desktop ? <img className="template-image mt-2" src="/images/template/7news/sub-title-desktop.png" alt="7News" />
                :
                <div>
                    <img className='template-image' src="/images/template/7news/sub-title.png" alt="7News" />
                    <img className='mb-2 template-image' src="/images/template/7news/sub-title-2.png" alt="7News" />
                </div>
            }
        </div>
    )
}
const sevenNewsSubArticle = (content) => {
    return (
        <div className="sevenNews-subarticle" key={content.id}>
            <div>
                <h5 className="m-1">{content.header}</h5>
                <img src={content.image} alt="7News sub article" />
            </div>
            <div>
                <span>By {content.writer} / <span className="text-green">{content.category}</span></span>
                <span className="text-green">{"\u2192"}</span>
            </div>
        </div>  
    )
}
const sevenNewsArticle = (component = null) => {
    return(
        <div className="main-article">
            <span className="bg-white opening">
                <span>The Sir Doug Nicholls Round honouring<u>Indigenous Australians</u>and their contributions to football has been run and won.</span>
                <span>But as<u>National Reconciliation Week</u>continues, 7NEWS.com.au is celebrating the significant legacies left in the AFL era by zeroing in on one great Indigenous player from each club.</span>
                <span>These are the players walking in paths forged by state-league legends like Graham ‘Polly’ Farmer, Norm McDonald, Barry Cable, Syd Jackson, Maurice Rioli, Bill Dempsey, Stephen Michael and Ted Kilmurray.</span>
            </span>
            {component}
                
            <span className="bg-white">
                <h2>Brisbane Lions - Darryl White</h2>
                <span>With all due respect to 7AFL’s own Chris Johnson, his triple premiership teammate Darryl White has flown from behind the pack and emerged holding up his spot as the Lions’ greatest Indigenous player.</span>    
                <span>The long-sleeved and high-flying versatile footballer from Alice Springs was named fullback in the Indigenous Team of the Century, and his career highlights include six Brownlow votes in his first three AFL games and a goal of the year award for the very first of his 165 majors. White retired from the AFL in 2005 after 268 games with the Bears and<u>Lions.</u></span>
                <img alt="" className="template-image" src='/images/template/7news/article-1.jpg'/>
                <p>The high-flying Darryl White was a mainstay in Brisbane’s dynasty. Credit: Darren England/Getty Images</p>
                
                <h2>Carlton - Eddie Betts</h2>
                <span>There’s no one quite like Eddie. From the baggy shorts to slippery work in the forward line and, of course, his exceptional snaps from the pocket, Betts well and truly left his mark on the game with his 350 games and 640 goals.</span>
                <span>The small forward’s best football may have been played with Adelaide, where he earned All Australian honours three times, but it was with the<u>Blues</u>that he first made his name as a fan favourite. Betts earned his first of four goal of the year awards in 2006, earning three more in 2015, 2016 and 2019.</span>
                <span>Betts also accepted his status as an Indigenous role model in the back half of his career, speaking out against racism and working with organisations to make change.</span>         
                <img alt="" className="template-image" src='/images/template/7news/article-2.jpg'/>                
                <p>Eddie Betts was a fan favourite throughout his time at Carlton and Adelaide. Credit: Cameron Spencer/Getty Images</p>
                
                <h2>Collingwood - Leon Davis</h2>
                <span>Drafted out of WA - where he had hoped to stay - to a Victorian club that had just won the wooden spoon, Davis wasted no time earning his stripes in a 225-game, 270-goal<u>Pies</u>career that took in the 2002, 2003 and 2010 grand finals.</span>
                <span>But the small forward came into his own as he matured, crediting his more consistent football with a work ethic forged by a greater connection to his culture through time in the bush with his father.</span>
                <span>Davis’s highlight-reel career led to the 2008 goal of the year award and All Australian honours in 2009. Infamously dropped for the 2010 grand final replay after the drawn decider, he proved his talent in Collingwood’s run to the 2011 grand final when he turned his disappointment into a second All Australian selection - as a defender.</span>
                <img alt="" className="template-image" src='/images/template/7news/article-3.jpg'/>
                <p>Leon Davis starred as a forward before ending his career as a talented defender. Credit: David Crosling/AAP</p>

                <h2>Essendon - Michael Long</h2>
                <span>One of the AFL’s most iconic Indigenous figures, Long’s legacy was built with his dual premiership-winning career with the<u>Bombers</u>and enhanced as he became a powerful voice for his community - even when he was still on the field.</span>
                <span>The Norm Smith medallist’s stand against racial abuse during the 1995 Anzac Day game led to the AFL creating the racial vilification policy. “I think there comes a time in your life where you’ve got to stop this not only for yourself but for others who come into this game of ours,” he said in 2019.</span>
                <span>The 1993 Norm Smith medallist and a two-time All Australian, Long would contribute to Essendon’s dominant 2000 premiership before hanging up the boots after the 2001 season. In retirement, he started the Long Walk movement to bring attention to Indigenous issues while his foundation focuses on education and leadership for Indigenous youth in the Northern Territory.</span>
                <img alt="" className="template-image" src='/images/template/7news/article-4.jpg'/>
                <p>Michael Long remains one of the AFL’s most significant identities. Credit: Paul Kane/Getty Images</p>

                <h2>What is National Reconciliation Week?</h2>
                <span>National Reconciliation Week is an annual event for Australians to celebrate and learn about First Nations history, culture and achievements.</span>
                <span>The theme of this year’s week is, “Be Brave. Make Change.”</span>
                <span>“‘Be Brave. Make Change.’ is a challenge to us all to Be Brave and tackle the unfinished business of reconciliation so we can Make Change for all,” said Reconciliation Australia.</span>
                <span>The week is being held from May 27 to June 3.</span>
                <span>To mark National Reconciliation Week,<u>7NEWS.com.au</u>is taking a look at the issues impacting First National people in Australia and their stories.</span>
                
                <div className="sevenNews-tags mb-3">
                    <span>AFL</span><span>Carlton Blues</span><span>Melbourne Demons</span><span>Collingwood Magpies</span><span>Hawthorn Hawks</span><span>Eddie Betts</span><span>Fremantle Dockers</span><span>Essendon Bombers</span><span>West Coast Eagles</span><span>Gold Coast Suns</span><span>North Melbourne Kangaroos</span><span>Western Bulldogs</span><span>Sydney Swans</span><span>Sport</span>
                </div>
            </span>
        </div>
    )
}
export const sevenNewsMobile = (pos, isInterstitialCreative = false, isScrolloCreative = false, isScrolloDesktopCreative = false, isMobileSkinCreative = false, renderAd, height) => {
    let bottom = height && height >= 150
    return (
        <>
            <div className={classnames("bm-creative-preview-ad-content sevenNews position-relative", isScrolloDesktopCreative ? "ad-content-scrollo-desktop" : "")}>
                {sevenNewsHeader()}

                {!bottom && pos.match(/^(center|middle)$/ig) && !isInterstitialCreative ? renderAd() : null}
                
                {sevenNewsSubTitle()}

                {sevenNewsArticle((bottom && !isInterstitialCreative) ? <div> {renderAd()} </div> : null)}
                
                {sevenNewsFooter()}
            </div>
        </>
    )
}

export const sevenNewsDesktop = (adPos, width, height, isSkinCreative, isScrolloCreative, renderAd) => {
    return (
        <div className="sevenNews">
            {sevenNewsHeader(true)}
            <div className="desktop-container">
            {adPos === "top" && !isSkinCreative &&
                <div className={classnames('billboard-container mt-4', isSkinCreative ? 'h-0' : '')}>
                    <div className='sticky-container'>
                        {renderAd(false, width, isSkinCreative)}
                    </div>
            </div>}
            {sevenNewsSubTitle(true)}
            {adPos === "center" && 
            <div id="left-container">
                {renderAd(false, width, isSkinCreative)}
            </div>}
            
            <div id="right-container">
                {Content.map(item => sevenNewsSubArticle(item))}
            </div>
            <div id="bm-desktop-container" className={"bm-creative-preview-ad-content bm-desktop-container row sevenNews-desktop" + (isSkinCreative ? "" : " premium-sevenNews")}>
                <div className='preview-container sevenNews'>
                    <div id="page-container">
                        <div id='content'>
                            {sevenNewsArticle()}
                        </div>
                    </div>
                </div>
            </div>
            </div>
            {sevenNewsFooter(true, isSkinCreative, adPos === "top")}
        </div>
    )
}

export const sevenNewsScroller = (isDesktop, browserWidth, dynHeight) => {
    return (
        <>
            {isDesktop ?
                (
                    <> </>
                )
                : (
                    <div className={"bm-creative-preview-ad-content sevenNews"}>
                        <div className="sevenNews pro-scroll-sevenNews">
                            {sevenNewsHeader()}
                            {sevenNewsSubTitle()}
                        </div>
                
                        {sevenNewsArticle(<div style={{ width: browserWidth + "px", height: dynHeight + "px" }}> </div>)}
                
                        <div className="sevenNews"> {sevenNewsFooter()} </div>
                    </div>
                )}
        </>)
}

export const sevenNewsMobileSkin = (src = "") => {
    return (
        `
        <div class="bm-creative-preview-ad-content sevenNews">
          ${ReactElementToJSXString(sevenNewsHeader(false)).replace(/classname/ig, "class")}
          ${ReactElementToJSXString(sevenNewsSubTitle(false)).replace(/classname/ig, "class")}
          
          <div id="bm-creative-container" class="bm-ad col-12">
            <div id="bm-preview-iframe-container" class="mx-auto"> ${src} </div>
          </div> 
          ${ReactElementToJSXString(sevenNewsArticle(null)).replace(/classname/ig, "class")}
          ${ReactElementToJSXString(sevenNewsFooter(false, false, false)).replace(/classname/ig, "class")}
      </div>`
    )
}
export const sevenNewsCss = `
    .sevenNews{
        user-select: none;
        -webkit-user-drag: none;
        font-family: HeyWow, Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
        color: #292A33;
    }
    .bottom-shadow{ box-shadow: rgba(183, 182, 182, 0.52) 0px 10px 16px; }
    .template-image{ width: 100%; }
    img {
        user-select: none;
        -webkit-user-drag: none;
    }
    .main-article{
        padding: 15px;
        overflow-wrap: break-word;
        word-break: break-word;
        line-height: 1.7;
        font-size: 1.125rem;
        font-family: HeyWow, Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-weight: 300;
        background-color: white;   
    }
    .main-article u, .text-green{ color: #5fc749; }
    .main-article h2{ font-weight: 700; color: #292A33; margin-bottom: 10px; margin-top: 20px;}
    .main-article p{ margin-top: 5px; font-size: 14px; color: #737480; border-bottom: 1px solid #7374805d;}
    .main-article span{ display: block; margin-bottom: 10px;}
    .main-article .sevenNews-tags{
        display: flex;
        flex-wrap: wrap;
        flex: 1 1 0%;
        overflow-x: auto;
        margin-top: 20px;
    }
    .main-article .sevenNews-tags span{
        text-decoration: none;
        transition: background 250ms ease 0s, transform 150ms ease 0s;
        position: relative;
        background: rgb(241, 241, 241);
        color: rgb(41, 42, 51);
        font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 0.75rem;
        font-weight: 550;
        margin: 0px 0.625rem 0.625rem 0px;
        padding: 0.3125rem 0.9375rem;
        border-radius: 1.125rem;
    }
  `