import React from 'react'
import Spinner from '../utilities/Spinner'

const PreviewFrame = (props) => {
    return (
        <>
            {
                props.loading ?
                    <div className="mt-5 text-center d-flex flex-column justify-content-center h-100 bb-ad-spinner">
                        <Spinner width="100" height="100" />
                        <p className="bm-text_pink">Loading...</p>
                    </div> : null
            }
            <div id="bm-creative"></div>
        </>
    )
}

export default PreviewFrame;