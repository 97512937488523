import React, { Component } from 'react';

import { config } from '../../helpers'
import { getPreviewContainerWidth } from '../utilities/customFunctions'
import PreviewFrame from '../helpers/preview-frame'

const adContainer = "#bm-creative";

class Swipe extends Component {

  componentDidMount() {    
   window.addEventListener("resize", this.updateCreative); 
   window.addEventListener("orientationchange", this.updateCreative);

    if (this.props.details && this.props.details._id) {      
      this.loadSwipe(this.props.details);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.details !== prevProps.details) {
      if (this.props.details && this.props.details._id) {
        this.loadSwipe(this.props.details);
      }
    }
  }

  componentWillUnmount(){    
    window.removeEventListener("resize", this.updateCreative);
    window.removeEventListener("orientationchange", this.updateCreative);
    if (window.bmSwipeO) {      
      window.clearInterval(window.bmSwipeO.autoSwipeTimer)              
      if(typeof window.bmSwipeO.destroy === "function"){
        window.bmSwipeO.destroy();  
      } 
      window.bmSwipeO = {} ;
    }
  }

  updateCreative = () => {    
    if (this.props.details) {
      const {details} = this.props;
      var bmAdContainer = document.querySelectorAll('*[id^="bmAdContainer"]')[0];
      var bmWrapper = document.querySelectorAll('*[id^="bmWrapper"]')[0];

      var browserWidth = this.props.isDesktop ? details.width : getPreviewContainerWidth(details.width);
      //document.getElementsByClassName("bm-creative-preview")[0].clientWidth;
      
      var dynHeight = (browserWidth * details.height) / details.width;

      if (bmAdContainer) {        
        bmAdContainer.style.width = browserWidth + "px";
        bmAdContainer.style.height = dynHeight + "px";
      }
      if (bmWrapper) {
        bmWrapper.style.width = browserWidth + "px";
        bmWrapper.style.height = dynHeight + "px";
      }

      document.querySelector(adContainer).style.width = browserWidth + "px";
      document.querySelector(adContainer).style.height = dynHeight + "px";      
    }
  }

  loadSetSwipe = (details, swipeFaces) => {

    /* Remove if exists */
    if (document.getElementById('swipeInitScript')) {
      document.getElementById('swipeInitScript').remove();
    };

    const setswipe = document.createElement('script');
    setswipe.type = 'text/javascript';
    setswipe.id = "swipeInitScript";
    setswipe.async = false;
    setswipe.src = config.scripts.swipe.setSwipe;
    //setswipe.onload = this.loadSwipe(details);
    var that = this;
    setswipe.onload = function () {
      that.initSwipe(details, swipeFaces);
    }

    document.querySelector(adContainer).appendChild(setswipe);
  }

  loadSwipe = (details) => {
    var swipeFaces = [];
    var that = this;
    //console.log(this.props.loading);    
    document.querySelector(adContainer).innerHTML = "";
    //document.querySelector("#bm-creative").style.height = "0px";
    
    if (window.bmSwipeO) {      
      window.clearInterval(window.bmSwipeO.autoSwipeTimer)              
      if(typeof window.bmSwipeO.destroy === "function"){
        window.bmSwipeO.destroy();  
      } 
      window.bmSwipeO = {} ;
    }
    
    /* Remove if exists */
    if (document.getElementById('swipeCreativeScript')) {
      document.getElementById('swipeCreativeScript').remove();
    };

    let scriptSrc = details.swipe_type.replace(/"/g,"") === "3d" ? ((details.swipe_direction && details.swipe_direction === "vertical") ? config.scripts.swipe.threeDV : config.scripts.swipe.threeD ) : config.scripts.swipe.twoD;

    var script = document.createElement("script");
    script.type = "text/javascript";
    script.id = "swipeCreativeScript";
    script.src = scriptSrc;
    script.async = false;
    script.onload = function () {

      /* for (let i = 0; i < 8; i++) {
        if (details["image_upload_" + (i + 1)]) {
          var obj = {
            imageSrc: details["image_upload_" + (i + 1)],
            clickThroughURL: details["landingURL_" + (i + 1)] !== "" ? details["landingURL_" + (i + 1)] : details.default_landingURL,
            thirdPartyImpressionURL: details["impressionURL_" + (i + 1)] !== "" ? details["impressionURL_" + (i + 1)] : null
          };
          swipeFaces[i] = obj;
        }
      } */

      for(let i = 0; i< details.content.length ; i++){
        var obj = {
          imageSrc: details.content[i].imageSrc,
          clickThroughURL: details.content[i].clickThroughURL ? details.content[i].clickThroughURL : details.content[i].default_landingURL,
          thirdPartyImpressionURL: details.content[i].default_impressionURL ? details.content[i].default_impressionURL  : null
        };
        swipeFaces[i] = obj;
      }
      that.loadSetSwipe(details, swipeFaces);
    };
    document.querySelector(adContainer).appendChild(script);
  
  }

  initSwipe = (details, swipeFaces) => {
    if (document.getElementById('renderSwipe')) {
      document.getElementById('renderSwipe').remove()
    };

    const setswipe = document.createElement('script');
    setswipe.setAttribute('id', 'renderSwipe');
    setswipe.type = 'text/javascript';
    //setswipe.async = true;
    setswipe.async = false;
    
    var containerWidth = this.props.isDesktop ? details.width : getPreviewContainerWidth(details.width);
    //var containerWidth = document.getElementsByClassName("bm-creative-preview")[0].clientWidth;
    var dynHeight = (containerWidth * details.height) / details.width;

    document.querySelector(adContainer).style.height = dynHeight + "px";

    var swipeIconEnabled = true;
    var arrowsEnabled = false;
    if(details.swipe_type.replace(/"/g,"") === "2d" && this.props.isDesktop){
        swipeIconEnabled = false;
        arrowsEnabled = true;
    }

    var swipeIconDyn = details.swipe_icon ? details.swipe_icon : ((details.swipe_direction && details.swipe_direction === "vertical") ? config.scripts.swipe.defaultIconV : config.scripts.swipe.defaultIcon);
    var renderSwipe = `window.bmSwipeO = new BMSetSwipe({
            swipeType: '${details.swipe_type.replace(/"/g,"")}', //Can be 2d or 3d
            clickThroughURL: '${details.default_landingURL}' , //default clickthrough URL
            parentElement: document.getElementById("bm-creative"), //container element for the swipe element
            width: '${containerWidth}', //width of the swipe element
            height: '${dynHeight}', //height of the swipe element
            spinOnStart: '${details.spin}', //start spinning first time only        
            autoSwipe: {
                enabled: '${details.auto_swipe}',
                delay: 2
            },
            loop: '${details.loop}',
            swipeIcon: {
              enabled: '${swipeIconEnabled}',
              src: '${swipeIconDyn}'
            },         
            arrows:{
                enabled: '${arrowsEnabled}',
                leftIcon: '${config.scripts.swipe.leftArrowIcon}',
                rightIcon: '${config.scripts.swipe.rightArrowIcon}'
            }, 
            openURLTarget: "_blank", //_top, _parent, _blank
            faces: ${JSON.stringify(swipeFaces)}
        });`
    setswipe.innerHTML = renderSwipe;
    document.querySelector(adContainer).appendChild(setswipe);

  }

  render() {
    return (        
      <PreviewFrame {...this.props}/>
    );
  }
}

export default (Swipe);
