import { BenchmarkConstants } from "./benchmark.constants";
import axios from 'axios';
import { config } from '../../helpers'
const ROOT_URL = config.apiServer;

const loading = (status) => {
    return {
        type: BenchmarkConstants.BENCHMARK_LOADING,
        payload: status
    };
};



export const getBenchmarkData = (country = "", device = "", format = "", dimension = "") => dispatch => {
    dispatch(loading(true));
    let apiParam = `country=${country}`;

    if((device !== "" || device !== null) && format && dimension){
        apiParam = `&device=${device}&format=${format}&dimension=${dimension}&country=${country}`
    }
    axios.get(`${ROOT_URL}/get-benchmark-info?${apiParam}`)
        .then(response => {
            if (response.data) {
                dispatch({
                    type: BenchmarkConstants.GET_BENCHMARK,
                    payload: response.data.data ? response.data.data : ""
                });
                dispatch(loading(false));
            } 
            else {
                dispatch({
                    type: BenchmarkConstants.GET_BENCHMARK_ERROR,
                    payload: "error"
                });
                dispatch(loading(false));
            }
        }).catch(error => {
            dispatch({
                type: BenchmarkConstants.GET_BENCHMARK_ERROR,
                payload: "error"
            });
            dispatch(loading(false));
        })
}
