import { PreviewConstants } from "./preview.constants";
import axios from 'axios';
import { config } from '../../helpers'
import { AdInfo } from './sales-demo'
const ROOT_URL = config.apiServer;

const loading = (status) => {
    return {
        type: PreviewConstants.AD_LOADING_STATUS,
        payload: status
    };
};



export const getAdDetails = (id, isCampaign = false) => dispatch => {

    dispatch(loading(true));

    if(id === "velvedere-creative-ps"){
        dispatch({ type: PreviewConstants.AD_DETAILS, payload: AdInfo.proskin });
        dispatch(loading(false));
        return false;
    }
    else if(id === "velvedere-creative-cc"){
        dispatch({ type: PreviewConstants.AD_DETAILS, payload: AdInfo.cinecube });
        dispatch(loading(false));
        return false;
    }
    let url = isCampaign ? `${ROOT_URL}/brief_creative_preview/${id}` : `${ROOT_URL}/ad-builder/${id}`

    axios.get(url)
        .then(response => {
            if (response.data) {
                dispatch({
                    type: PreviewConstants.AD_DETAILS,
                    payload: response.data.data
                });
                dispatch(loading(false));
            }
            else {
                dispatch({
                    type: PreviewConstants.AD_ERRORS,
                    payload: "error"
                });
                dispatch(loading(false));
            }
        }).catch(error => {
            dispatch({
                type: PreviewConstants.AD_ERRORS,
                payload: "error"
            });
            dispatch(loading(false));
        })
}


export const getNexdPreviewTags = (id, as = "nexd") => dispatch => {
    dispatch(loading(true));
    if(id && as === "nexd"){
    axios.get(`https://api.nexd.com/creatives/embedded?creative_id=${id}&disableclick=true`)
        .then(response => {
            if (response.data) {
                dispatch({
                    type: PreviewConstants.NEXD_DETAILS,
                    payload: response.data.result ? response.data.result : {}
                });
                dispatch(loading(false));
            }
            else {
                dispatch({
                    type: PreviewConstants.NEXD_DETAILS_ERROR,
                    payload: "error"
                });
                dispatch(loading(false));
            }
        }).catch(error => {
            dispatch({
                type: PreviewConstants.NEXD_DETAILS_ERROR,
                payload: "error"
            });
            dispatch(loading(false));
        })
    }
    else{
        dispatch({
            type: PreviewConstants.NEXD_DETAILS_ERROR,
            payload: "error"
        });
        dispatch(loading(false));
    }
}

export const getShowcaseAdDetails = (id) => dispatch => {

    dispatch(loading(true));

    axios.get(`${ROOT_URL}/showcase-creative-preview/${id}`)
        .then(response => {
            if (response.data) {
                dispatch({
                    type: PreviewConstants.AD_DETAILS,
                    payload: response.data.data
                });
                dispatch(loading(false));
            }
            else {
                dispatch({
                    type: PreviewConstants.AD_ERRORS,
                    payload: "error"
                });
                dispatch(loading(false));
            }
        }).catch(error => {
            dispatch({
                type: PreviewConstants.AD_ERRORS,
                payload: "error"
            });
            dispatch(loading(false));
        })
}

export const getAirtoryPlacementPreviewTag = (id) => dispatch => {
    dispatch(loading(true));
    
    axios.get(`${ROOT_URL}/get-a-preview-tag?id=${id}`)
        .then(response => {
            if (response.data) {
                dispatch({
                    type: PreviewConstants.AIRTORY_TAG_DETAILS,
                    payload: response.data.tag ? response.data.tag : ""
                });
                dispatch(loading(false));
            }
            else {
                dispatch({
                    type: PreviewConstants.AIRTORY_TAG_DETAILS_ERROR,
                    payload: "error"
                });
                dispatch(loading(false));
            }
        }).catch(error => {
            dispatch({
                type: PreviewConstants.AIRTORY_TAG_DETAILS_ERROR,
                payload: "error"
            });
            dispatch(loading(false));
        })
    
}