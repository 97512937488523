import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getBenchmarkData } from '../../redux/benchmark/benchmark.actions'
import './index.scss';

class Benchmark extends Component {

    componentDidMount(){
       this.init(); 
    }

    componentDidUpdate(prevProps){
        if(this.props.details !== prevProps.details){
            this.init();
        }
    }

    init(){
        const {details, getBenchmarkData} = this.props;
        if(details && details.country){
            getBenchmarkData(details.country, details.device, details.formatId, details.dimension)
        }  
    }

  render() {
      const { data } = this.props;

      return(
          
          data ?     
                <>        
                <div id="benchmark-info" className="benchmark-info d-flex flex-column">
                    <div className="d-flex flex-row align-items-center mb-2">                       
                        <h2 className="benchmark-title">Benchmark</h2>
                    </div>
                    <div className="d-flex flex-row align-items-center">
                        <img className="benchmark-icon mr-3" src="/images/benchmark/er-icon.svg" alt=""/>
                        <div className= "d-flex flex-column justify-content-start align-items-center">
                            <span className="font-weight-bold">Engagement rate</span>
                            <h2>{data.engagement_rate}%</h2>
                        </div>
                    </div>
                    <hr/>
                    <div className="d-flex flex-row align-items-center">
                        <img className="benchmark-icon mr-3" src="/images/benchmark/et-icon.svg" alt=""/>
                        <div className= "d-flex flex-column justify-content-start align-items-center">
                            <span className="font-weight-bold">Engaged time</span>
                            <h2>{data.engaged_time} sec</h2>
                        </div>
                    </div>
                    <hr/>
                    <div className="d-flex flex-row align-items-center">
                        <img className="benchmark-icon mr-3" src="/images/benchmark/ctr-icon.svg" alt=""/>
                        <div className= "d-flex flex-column justify-content-start align-items-center">
                            <span className="font-weight-bold">Click-thru rate</span>
                            <h2>{data.click_through_rate}%</h2>
                        </div>
                    </div>
                </div>               
                </>
                :
                null          
        )
  }
}

const mapStateToProps = (state) => {
  return {
    details: state.previewAd.details,
    loading: state.benchmark.loading,
    data: state.benchmark.data,
  }
};

const mapDispatchToProps = {
    getBenchmarkData
};

export default (connect(mapStateToProps, mapDispatchToProps)(Benchmark));
