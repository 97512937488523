import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter} from 'react-router-dom';

import BBRoutes from './routes';

import { store } from './redux/store'


import * as serviceWorker from './serviceWorker';
import { config } from './helpers';


window.addEventListener('message', function (e) {
    // eslint-disable-next-line
    if (config.buddyServer != e.origin) {
        return false;
    }
    const { action, value } = e.data
    // eslint-disable-next-line
    if (action == 'save') {
        if (!config.authId()) {
            config.setToken(value.showDealInfo);
        }
        // eslint-disable-next-line
        else if (config.authId() == value.showDealInfo) {
            config.setToken(value.showDealInfo);
        }
    }
    // eslint-disable-next-line
    else if (action == 'remove') {
        config.removeToken();
    }

});

const root = createRoot(document.getElementById('root'));

root.render(
    <Provider store={store}>
        <BrowserRouter>
                <BBRoutes />
        </BrowserRouter>
    </Provider>);

serviceWorker.unregister();
