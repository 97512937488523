export const AdInfo = {
    "proskin": {
        "_id": "velvedere-creative-ps",
        "ad_name": "Pro-Skin",
        "width": "1920",
        "height": "1080",
        "accountId": "",
        "accountName": "Velvedere",
        "dimension": "1920x1080",
        "device": "1",
        "ad_for": "nexd",
        "ad_type": "Pro-Skin",
        "ad_id": "velvedere",
        "creative_id": "NkaOVgE6RHBLTcXE",
        "html_tag": "<ins class=\"adcads\"\r\ndata-click-tracker=\"https://bigads.co\"\r\ndata-debug=\"true\"\r\ndata-has-desktop=\"yes\"\r\ndata-preview-site=\"true\"\r\ndata-settings=\"https://data-eu.nexd.com/ads/creatives/x5bYA3nOEhggqomX/settings.json?6391d6df73db2f854d604243de5921a694ef3639\"\r\ndata-stay-in-container=\"true\"\r\ndata-tracking=\"no\"\r\ndata-trackingjs=\"no\"\r\ndata-unit=\"x5bYA3nOEhggqomX\"\r\ndata-url=\"https://data-eu.nexd.com/ads/creatives/\">\r\n<script src=\"https://cdn.nexd.com/frmwrk/teele-release-v5.5.93.js\" type=\"text/javascript\"></script>\r\n</ins>",
        "refName": "Velvedere",
        "default_landingURL": "https://bigads.co",
        "content": [
            {
                "imageSrc": "https://s3-ap-southeast-2.amazonaws.com/library.bigbuddy.io/prodBuddy/creative/61f84a0a4ce3c54f266546a2/970x250 TOP.png"
            }
        ],
        "scripts": {// eslint-disable-next-line
            "secureScript": "<ins class=\"adcads\"\r\n\tdata-adc-id=\"A3xQCXY8iVrP\"\r\n\tdata-unit=\"A3xQCXY8iVrP\"\r\n\tdata-clickable=\"yes\"\r\n\tdata-redir=\"yes\"\r\n\tdata-click-tracker=\"${CLICK_URL}https://bigads.co\"\r\n\tdata-click-macro=\"${CLICK_URL}\"\r\n\tdata-has-desktop=\"yes\"\r\n\tdata-responsive=\"yes\"\r\n\tdata-width=\"1920\"\r\n\tdata-height=\"1080\"\r\n\tdata-meta-source-url=\"${SOURCE_URL}\">\r\n\t<script src=\"https://media.adcanvas.com/A3xQCXY8iVrP/adtag.js\" type=\"text/javascript\"></script>\r\n</ins>",
            "adcId": "velvedere-creative-ps"
        },
        "format_type": "Infeed",
        "ad_server": "nexd",
        "brand_info": {
            "favicon_image": null,
            "primaryColor": "",
            "accentColor": "",
            "logo": null,
            "name": "Velvedere"
        }
    },
    "cinecube": {
        "_id": "velvedere-creative-cc",
        "ad_name": "Cine-Cube",
        "width": "300",
        "height": "600",
        "accountId": "",
        "accountName": "Velvedere",
        "dimension": "300x600",
        "device": "0",
        "ad_for": "airtory",
        "ad_type": "Cine-Cube",
        "ad_id": "velvedere",
        "creative_id": "e02fd564db84ba6c37a06fbb83df76a4",
        "html_tag": "<script id=\"ad6583c9726f12bb70327d2963862606e0/placement/e02fd564db84ba6c37a06fbb83df76a4\">var adTag=document.createElement(\"script\");function adTagLoad(e){if(document.body)e();else{var a=new MutationObserver(function(){document.body&&(a.disconnect(),e())}); a.observe(document.documentElement,{childList:!0})}} adTag.type=\"text/javascript\",adTag.src=\"https://studio.airtory.com/serve/preview/e02fd564db84ba6c37a06fbb83df76a4\",adTagLoad(function(){document.body.appendChild(adTag)});</script>",
        "refName": "Velvedere",
        "default_landingURL": "https://bigads.co",
        "content": [
            {
                "imageSrc": "https://s3-ap-southeast-2.amazonaws.com/library.bigbuddy.io/prodBuddy/creative/61f74b644ce3c511f5355721/Velvedere Video.mp4"
            }
        ],
        "scripts": {// eslint-disable-next-line
            "secureScript": "<script id=\"ad6583c9726f12bb70327d2963862606e0/placement/e02fd564db84ba6c37a06fbb83df76a4\">var _airAdParams={\"click_url\":\"${CLICK_URL}\",\"cachebuster\":\"${CACHEBUSTER}\"};function adTagLoad(e){if(document.body)e();else{var a=new MutationObserver(function(){document.body&&(a.disconnect(),e())});a.observe(document.documentElement,{childList:!0})}}var pixel=new Image,adTag=document.createElement(\"script\"),requestParams=function(e,a){var t,d=[];for(t in e)if(e.hasOwnProperty(t)&&\"click_url\"!=t){var n=a?a+\"[\"+t+\"]\":t,o=e[t];d.push(null!==o&&\"object\"==typeof o?serialize(o,n):encodeURIComponent(n)+\"=\"+encodeURIComponent(o))}return d.join(\"&\")}(_airAdParams);adTag.type=\"text/javascript\",adTag.src=\"https://cdn.airtory.com/6583c9726f12bb70327d2963862606e0.js?\"+_airAdParams.cachebuster,pixel.src=\"https://studio.airtory.com/serve/track/6583c9726f12bb70327d2963862606e0/placement/e02fd564db84ba6c37a06fbb83df76a4/impression?\"+requestParams,pixel.setAttribute(\"style\",\"position:absolute;visibility:hidden;z-index:-999;\"),adTagLoad(function(){document.body.appendChild(adTag),document.body.appendChild(pixel)});</script><noscript><a href=\"https://bigads.co\" target=\"_blank\"><img src=\"https://cdn.airtory.com/webp/f550d508712858d5738ed2936a15111a_phpJB7dV3.webp\" /></a><img src=\"https://studio.airtory.com/serve/track/6583c9726f12bb70327d2963862606e0/placement/e02fd564db84ba6c37a06fbb83df76a4/fallback\"/></noscript>",
            "adcId": "velvedere-ad-cc"
        },
        "format_type": "Infeed",
        "ad_server": "airtory",
        "brand_info": {
            "favicon_image": null,
            "primaryColor": "",
            "accentColor": "",
            "logo": null,
            "name": "Velvedere"
        }
    }

}