import React from "react";
import classnames from 'classnames';
import { getQueryStringParams, isMobile } from './utilities/customFunctions'
import Toastify from './toastify'
import Media from 'react-media';

import Headers from './helpers/headers'
//import { LARGE_DEVICES } from './devices/device-list'
import DeviceSelector from './devices/device-selector'
import { connect } from 'react-redux';

import { saveDeviceSelected, refreshPreview } from '../redux/device/device.actions'
import { useLocation } from "react-router"

function App(props) {

  let { search } = useLocation()
  let searchParams = getQueryStringParams(search);

  let siteId = "";
  if (searchParams && searchParams.siteId) {
    siteId = searchParams.siteId;
  }
  let desktop = false;
  if (searchParams && searchParams.desktop) {
    desktop = searchParams.desktop === "true" ? true : false;
  }
 
  const onDeviceChange = (deviceId) => {
    props.saveDeviceSelected(deviceId);
    props.refreshPreview();
  }

  return (

    <React.Fragment>

      <div className="bb-container">
        <div className={classnames("bb-container-main")}>

          <Media queries={{ mobileWidth: "(max-width: 480px)", mobileHeight: "(max-height: 480px)" }}>
            {
              matches => (matches.mobileWidth || matches.mobileHeight || desktop || isMobile()) ? (
                document.body.style.background = "white",
                props.children
              ) : (
                document.body.style.background = "#1c2135",
                <>
                  <Headers onDeviceChange={onDeviceChange} onRefreshClick={props.refreshPreview} {...props} />
                  {/* eslint-disable-next-line */}
                  <div id="bb-preview-device-container" className={`_${siteId} ${props.details && props.details.device == "1" ? "bb-preview-desktop" : "bb-preview-mobile"}`}>
                    <DeviceSelector {...props} />
                  </div>
                </>
              )
            }
          </Media>
        </div>
      </div>
      <Toastify />
    </React.Fragment>

  )
}

const mapStateToProps = (state) => {
  return {
    deviceId: state.device.id,
    details: state.previewAd.details,
  }
};

const mapDispatchToProps = {
  saveDeviceSelected,
  refreshPreview
};

export default (connect(mapStateToProps, mapDispatchToProps)(App));
