import React, { Component } from 'react'
import classnames from 'classnames'
import ReactTooltip from 'react-tooltip'

export default class Tooltip extends Component {

    render() {

        const { label, className, iconname, light, place } = this.props;
        var id = label.replace(/[^a-zA-Z0-9]/g, '');
        return (
            <React.Fragment>
                <label className={classnames("bb-tooltip-label", className)}>
                    <span>{label}</span>
                    <i className={classnames("fas pl-2", light ? "text-white" : "text-dark",iconname ? iconname: "fa-question-circle")} data-tip 
                    data-for={id + "-help"} aria-hidden="true"></i>
                </label>
                <ReactTooltip id={id + "-help"} className="ft-tooltip" place={place ? place : "bottom"}>
                    {this.props.children}
                </ReactTooltip>
            </React.Fragment>
        )
    }
}
