import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getNexdPreviewTags } from '../../../redux/preview/preview.actions'
import NexdPreview from './index'

class PreviewNexdContainer extends Component {

  componentDidMount() {
    const { details } = this.props;    

    if(details && ( details.creative_id || details.briefId || details.is_brief)){
      this.props.getNexdPreviewTags(details.creative_id || "", details.ad_server);
    }
    
  }

  render() {
    const {nexd} = this.props;
    return (
        nexd ? <NexdPreview {...this.props} /> : null
    );  
}
}

const mapStateToProps = (state) => {

    return {
      nexd: state.previewAd.nexd,
      loading: state.previewAd.loading
    }
  };
  
  const mapDispatchToProps = {
    getNexdPreviewTags
  };
  
  export default (connect(mapStateToProps, mapDispatchToProps)(PreviewNexdContainer));
  