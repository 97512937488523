import React from 'react'
import styled from 'styled-components'

/* const Frame = styled.div`
    width: ${props => props.width + 10}px;
    height: ${props => props.height + 10}px;
    background-color: #ffffff;
    position: relative;
    margin: 20px auto 10px auto;
    border: 7px solid #474b57;
    border-radius: 50px;
    box-shadow: 14px 21px 42px 10px rgba(0, 0, 0, .2); 
`;
 
const FrameContent = styled.div`
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    background-color: #6b6d76;
    border: 16px solid #6b6d76;
    position: absolute;
    left: 50%;
    top: 50%; 
    transform: translate(-50%, -50%);
    border-radius: 47px; 
    overflow: hidden;
`; */

const BodyContainer = styled.div`
    overflow: auto;
    width: 100%;
    height: 100%; /*FrameContentBottom plus FrameContentHeader height*/
    background: white;
    //margin:10px;
    &::-webkit-scrollbar{
        width: 7px;
        height: 7px;
        box-shadow: inset 0 0 100px #2E3547;
        // border-radius: 10px;
        margin-right: 20px;
        
        &-thumb{
            height: 1em;
            border: 0.1em solid rgba(0, 0, 0, 0); 
            background-clip: padding-box;
            -webkit-border-radius: 0.5;
            background-color: rgba(202, 203, 211, 0.20);
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.025);
        }
    
        &-track{
            -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.025);
            //border-radius: 10px;
            padding: 10px;
        }
    }
`;

const FrameContentBody = styled.div`
    width: 100%;
`;

const Screen = (props) => {

    const { previewRnd } = props;
 
    return (
        <>
            {/* <Frame width={info.frameContent.width} height={info.frameContent.height}> */}
                {/* <FrameContent width={info.frameContent.width} height={info.frameContent.height}> */}
                    <BodyContainer>
                        <FrameContentBody id="bb-device-frame-content-body" key ={previewRnd}>
                            {props.children} 
                        </FrameContentBody>
                    </BodyContainer>
                {/* </FrameContent> */}
            {/* </Frame>             */}
        </>
    )
}

export default Screen;