import React, { Component } from 'react'
import { Oval } from 'react-loader-spinner'
import classnames from 'classnames'
export default class Spinner extends Component {

    render() {
        const { className , color, height, width } = this.props;

        return (

            <Oval
                height={height ? height : "100"}
                width={width ? width : "100"}
                color={color ? color : "#EC1555"}
                wrapperClass={classnames(className , "d-flex align-self-center justify-content-center")}  
                secondaryColor= "white"
            />
        );
    }
}

