import React, { Component } from "react";
import PreviewContainer from "../../../../preview/container";

import { config } from "../../../../../helpers";

class ProSkinDesktopClassic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clickThroughURL: "",
      width: "",
      height: "",
      totalF: 0,
      loading: true,
      isDesktop: false,
      isVideo: false,
      videoStyle: "",
      videoObject: null
    };
  }

  xyz = [];

  componentDidMount() {
    window.addEventListener("message", this.handleAdBuilder);
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.handleAdBuilder);
  }

  handleAdBuilder = (event) => {
    if (
      event.data &&
      (event.data.image_upload_1 ||
        event.data.image_upload_2 ||
        event.data.image_upload_3 ||
        event.data.image_upload_4)
    ) {
      var that = this;
      this.setState(
        {
          clickThroughURL: event.data.default_landingURL,
          width: event.data.size.width,
          height: event.data.size.height,
          totalF: 0,
          loading: true,
          // eslint-disable-next-line
          isDesktop: event.data.device == "1" ? true : false,
          // eslint-disable-next-line
          isVideo: event.data.isVideo == "true" ? true : false,
          videoStyle: event.data.videoStyle,
          videoObject: {
            src: event.data.image_logo,
            showAudioIcon: "true",
            fitType: event.data.videoStyle || "cover", 
            loop: event.data.video_loop.toString() || "true",
            x: event.data.video_x || -1,               
            y: event.data.video_y || 0.85,
            width: event.data.video_size_x || "432",
            height:  event.data.video_size_y || "243",
            position:  event.data.video_position || "left"
          }
        },
        () => {
          that.loadAd(event);
        }
      );
    }
  };

  loadAd = (event) => {
    var count = 0;
    this.xyz = [];
    var that = this;

    const { clickThroughURL, isVideo } = this.state;

    document.querySelector("#bm-creative").innerHTML = "";
    document.querySelector("#bm-creative").style.height = "0px";
    if (window.bmProskinDC) {
      window.bmProskinDC.destroy();
    }

    /* Remove if exists */
    if (document.getElementById("AdCreativeScript")) {
      document.getElementById("AdCreativeScript").remove();
    }
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.id = "AdCreativeScript";
    script.src = isVideo
      ? config.scripts.proskin.desktop.video.script
      : config.scripts.proskin.desktop.classic.script;
    script.async = false;
    script.onload = function () {
      for (let i = 0; i < 4; i++) {
        if (event.data["image_upload_" + (i + 1)]) {
          that.setState({ totalF: that.state.totalF + 1 });
        }
      }

      if (that.state.totalF === 0) {
        if (window.bmProskinDC) {
          window.bmProskinDC.destroy();
        }
        that.setState({ loading: false });
      }

      for (let i = 0; i < 4; i++) {
        //if (event.data["image_upload_" + (i + 1)]) {
        var files = event.data["image_upload_" + (i + 1)];

        if (FileReader && files && typeof files === "object") {
          let fr = new FileReader(); // eslint-disable-next-line
          fr.onload = function () {
            var obj = {
              imageSrc: fr.result,
              clickThroughURL:
                event.data["landingURL_" + (i + 1)] !== ""
                  ? event.data["landingURL_" + (i + 1)]
                  : clickThroughURL,
              thirdPartyImpressionURL:
                event.data["impressionURL_" + (i + 1)] !== ""
                  ? event.data["impressionURL_" + (i + 1)]
                  : null,
            };
            that.xyz[i] = obj;
            count++;
            that.loadSetSkin(count);
          };
          fr.readAsDataURL(files);
        } else if (files) {
          var obj = {
            imageSrc: files,
            clickThroughURL:
              event.data["landingURL_" + (i + 1)] !== ""
                ? event.data["landingURL_" + (i + 1)]
                : clickThroughURL,
            thirdPartyImpressionURL:
              event.data["impressionURL_" + (i + 1)] !== ""
                ? event.data["impressionURL_" + (i + 1)]
                : null,
          };
          that.xyz[i] = obj;
          count++;
          that.loadSetSkin(count);
        }
        //}
      }
      if (event.data["image_logo"]) {
        let files = event.data["image_logo"];
        if (FileReader && files && typeof files === "object") {
            let fr = new FileReader();
            fr.onload = function () {
                that.xyz[4] = fr.result;
                count++;
                that.loadSetSkin(count);
                
            }
            fr.readAsDataURL(files);
        }
        else if (files) {
            that.xyz[4] = files;
            count++;
            that.loadSetSkin(count);

        }
    }
    };
    document.querySelector("#bm-creative").appendChild(script);
  };

  loadSetSkin = (count) => {
    if (document.getElementById("AdCreativeScript")) {
      document.getElementById("AdCreativeScript").remove();
    }

    var script = document.createElement("script");
    script.type = "text/javascript";
    script.id = "AdCreativeScript";
    script.src = this.state.isVideo
      ? config.scripts.proskin.desktop.video.script
      : config.scripts.proskin.desktop.classic.script;
    script.async = false;
    var that = this;

    script.onload = function () {
      that.callInit(count);
    };

    document.querySelector("#bm-creative").appendChild(script);
  };

  callInit = (count) => {
    if (count === this.state.totalF) {
      if (window.bmProskinDC) {
        window.bmProskinDC.destroy();
      }
      this.inits();
    }
  };

  inits = () => {
    const { clickThroughURL, isVideo, videoStyle } = this.state;

    var AdImage = [];
    for (let i = 0; i < this.xyz.length; i++) {
      //if (this.xyz[i]) {
      AdImage.push(this.xyz[i]);
      //}
    }

    if (document.getElementById("renderAd")) {
      document.getElementById("renderAd").remove();
    }

    const setAd = document.createElement("script");
    setAd.setAttribute("id", "renderAd");
    setAd.type = "text/javascript";
    setAd.async = false;

    var containerWidth = 0; //isDesktop ? width : document.getElementsByClassName("bm-creative-preview")[0].clientWidth;
    var dynHeight = 0; //(containerWidth * height) / width;

    document.querySelector("#bm-creative").style.height = dynHeight + "px";
    var headerElement = AdImage[1] || "";
    var leftElement = AdImage[2] || "";
    var rightElement = AdImage[3] || "";
    var bgElement = AdImage[0] || "";
    let videoObj = ''
    
    if(isVideo){
      videoObj = {...this.state.videoObject}
      videoObj.src = AdImage[4]
    }
    var renderAd = isVideo
      ? `window.bmProskinDC = new BMSetProSkinVideo({
            clickThroughURL: '${clickThroughURL}' , //default clickthrough URL
            parentElement: document.getElementById("bm-creative"), //container element for the Ad element
            previewContainerElement: document.getElementById("bm-creative-preview"),
            width: '${containerWidth}', //width of the Ad element
            height: '${dynHeight}', //height of the Ad element
            thirdPartyImpressionURL: null,        
            openURLTarget: "_blank", //_top, _parent, _blank
            headerElement: ${JSON.stringify(headerElement)},
            leftElement:${JSON.stringify(leftElement)},
            rightElement: ${JSON.stringify(rightElement)},
            bgElement: ${JSON.stringify(bgElement)},
            showAudioIcon: "true",
            videoStyle: "${videoStyle}",

            video :${JSON.stringify({...videoObj})}

        });`
      : `window.bmProskinDC = new BMSetProSkin({
            clickThroughURL: '${clickThroughURL}' , //default clickthrough URL
            parentElement: document.getElementById("bm-creative"), //container element for the Ad element
            previewContainerElement: document.getElementById("bm-creative-preview"),
            width: '${containerWidth}', //width of the Ad element
            height: '${dynHeight}', //height of the Ad element
            thirdPartyImpressionURL: null,        
            openURLTarget: "_blank", //_top, _parent, _blank
            headerElement: ${JSON.stringify(headerElement)},
            leftElement:${JSON.stringify(leftElement)},
            rightElement: ${JSON.stringify(rightElement)},
            bgElement: ${JSON.stringify(bgElement)}
        });`;
    setAd.innerHTML = renderAd;

    this.setState({ loading: false });
    document.querySelector("#bm-creative").appendChild(setAd);
  };

  render() {
    return (
      <PreviewContainer {...this.state}>
        {
          <React.Fragment>
            {this.state.loading ? (
              <div className="w-100 text-center d-flex flex-column justify-content-center h-100">
                <p className="bm-text_pink">Advertisement</p>
              </div>
            ) : null}
            <div id="bm-creative"></div>
          </React.Fragment>
        }
      </PreviewContainer>
    );
  }
}

export default ProSkinDesktopClassic;
