

import { dailyMailMobile, dailyMailDesktop, dailyMailScroller, dailyMailMobileSkin, dailyMailCss } from "./dailymail"
import { gumtreeMobile, gumtreeDesktop, gumtreeScroller, gumtreeMobileSkin, gumtreeCss } from "./gumtree"
import { sevenNewsMobile, sevenNewsDesktop, sevenNewsScroller, sevenNewsMobileSkin, sevenNewsCss } from "./7News"
import { newsMobile, newsDesktop, newsScroller, newsMobileSkin, newsCss } from "./news"
import { escapeMobile, escapeDesktop, escapeScroller, escapeMobileSkin, escapeCss } from "./escape"
import { tasteMobile, tasteDesktop, tasteScroller, tasteMobileSkin, tasteCss } from "./taste"


const news7 = window.location.href.indexOf("test.preview.studio") > -1 ? [{
    name: "7news",
    id: "3yhsao",
    title: "7news.com.au",
    selected: false,
    getMobilePreview: (pos, isInterstitialCreative = false, isScrolloCreative = false, isScrolloDesktopCreative = false, isMobileSkinCreative = false, renderAd, height ) => sevenNewsMobile(pos, isInterstitialCreative, isScrolloCreative, isScrolloDesktopCreative, isMobileSkinCreative, renderAd, height),
    getDesktopPreview: (adPos, width, height, isSkinCreative, isScrolloCreative, renderAd) => { return sevenNewsDesktop(adPos, width, height, isSkinCreative, isScrolloCreative, renderAd)},
    getScrollerPreview: (isDesktop, browserWidth, dynHeight) => sevenNewsScroller(isDesktop, browserWidth, dynHeight),
    getMobileSkin: (src) => sevenNewsMobileSkin(src),
    getCss: () => {return sevenNewsCss} // only is used for the iframe css getMobileSkin
}] : []
export const templateLists = [
    {
        name: "Dailymail",
        id: "1qweF6",
        title: "dailymail.co.uk",
        selected: false,
        getMobilePreview: (pos, isInterstitialCreative = false, isScrolloCreative = false, isScrolloDesktopCreative = false, isMobileSkinCreative = false, renderAd, height ) => dailyMailMobile(pos, isInterstitialCreative, isScrolloCreative, isScrolloDesktopCreative, isMobileSkinCreative, renderAd, height),
        getDesktopPreview: (adPos, width, height, isSkinCreative, isScrolloCreative, renderAd) => { if(document.getElementById("bb-preview-device-container")){document.getElementById("bb-preview-device-container").style.background = "white";} return dailyMailDesktop(adPos, width, height, isSkinCreative, isScrolloCreative, renderAd)},
        getScrollerPreview: (isDesktop, browserWidth, dynHeight) => dailyMailScroller(isDesktop, browserWidth, dynHeight),
        getMobileSkin: (src) => dailyMailMobileSkin(src),
        getCss: () => {return dailyMailCss} // only is used for the iframe css getMobileSkin
    },
    {
        name: "Gumtree",
        id: "2eadyu",
        title: "gumtree.com.au",
        selected: false,
        getMobilePreview: (pos, isInterstitialCreative = false, isScrolloCreative = false, isScrolloDesktopCreative = false, isMobileSkinCreative = false, renderAd, height ) => gumtreeMobile(pos, isInterstitialCreative, isScrolloCreative, isScrolloDesktopCreative, isMobileSkinCreative, renderAd, height),
        getDesktopPreview: (adPos, width, height, isSkinCreative, isScrolloCreative, renderAd) => { return gumtreeDesktop(adPos, width, height, isSkinCreative, isScrolloCreative, renderAd)},
        getScrollerPreview: (isDesktop, browserWidth, dynHeight) => gumtreeScroller(isDesktop, browserWidth, dynHeight),
        getMobileSkin: (src) => gumtreeMobileSkin(src),
        getCss: () => {return gumtreeCss}
    },
    ...news7,
    {
        name: "news",
        id: "4nqepws",
        title: "news.com.au",
        selected: false,
        getMobilePreview: (pos, isInterstitialCreative = false, isScrolloCreative = false, isScrolloDesktopCreative = false, isMobileSkinCreative = false, renderAd, height ) => newsMobile(pos, isInterstitialCreative, isScrolloCreative, isScrolloDesktopCreative, isMobileSkinCreative, renderAd, height),
        getDesktopPreview: (adPos, width, height, isSkinCreative, isScrolloCreative, renderAd) => { return newsDesktop(adPos, width, height, isSkinCreative, isScrolloCreative, renderAd)},
        getScrollerPreview: (isDesktop, browserWidth, dynHeight) => newsScroller(isDesktop, browserWidth, dynHeight),
        getMobileSkin: (src) => newsMobileSkin(src),
        getCss: () => {return newsCss}
    },
    {
        name: "Taste",
        id: "5tqaye",
        title: "taste.com.au",
        selected: false,
        getMobilePreview: (pos, isInterstitialCreative = false, isScrolloCreative = false, isScrolloDesktopCreative = false, isMobileSkinCreative = false, renderAd, height ) => tasteMobile(pos, isInterstitialCreative, isScrolloCreative, isScrolloDesktopCreative, isMobileSkinCreative, renderAd, height),
        getDesktopPreview: (adPos, width, height, isSkinCreative, isScrolloCreative, renderAd) => { return tasteDesktop(adPos, width, height, isSkinCreative, isScrolloCreative, renderAd)},
        getScrollerPreview: (isDesktop, browserWidth, dynHeight) => tasteScroller(isDesktop, browserWidth, dynHeight),
        getMobileSkin: (src) => tasteMobileSkin(src),
        getCss: () => {return tasteCss}
    },
    {
        name: "Escape",
        id: "6eqsce",
        title: "escape.com.au",
        selected: false,
        getMobilePreview: (pos, isInterstitialCreative = false, isScrolloCreative = false, isScrolloDesktopCreative = false, isMobileSkinCreative = false, renderAd, height ) => escapeMobile(pos, isInterstitialCreative, isScrolloCreative, isScrolloDesktopCreative, isMobileSkinCreative, renderAd, height),
        getDesktopPreview: (adPos, width, height, isSkinCreative, isScrolloCreative, renderAd) => { return escapeDesktop(adPos, width, height, isSkinCreative, isScrolloCreative, renderAd)},
        getScrollerPreview: (isDesktop, browserWidth, dynHeight) => escapeScroller(isDesktop, browserWidth, dynHeight),
        getMobileSkin: (src) => escapeMobileSkin(src),
        getCss: () => {return escapeCss}
    },
]

