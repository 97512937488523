import React from 'react'
import styled from 'styled-components'
import { SAMSUNG_NOTE_X } from '../../device-list';

const Mobile = styled.div`
    width: ${props => props.width }px;
    height: ${props => props.height }px;   
    position: relative;
    margin: 20px auto 10px auto;
    user-select: none;
`;

const MobileButtons = styled.div`
    width: 3px;
    background-color: #5B5B5B;
    height: 60px;
    position: absolute;
    z-index: 2;
`
const LeftButtons = styled(MobileButtons)`
    right: -3px;
    border-radius: 2px 0 0 2px;
    border-right: 1px solid #434343;
`;

const VolumeButton = styled(LeftButtons)`
    top: 120px;
    height: 100px;
`;

const BixbyButton = styled(LeftButtons)`
    top: 240px;
`;
/* 
const PowerButton = styled(MobileButtons)`
    right: -3px;
    top: 160px;
    border-radius: 0 2px 2px 0;
    border-left: 1px solid #434343;
`; */

const Chassis = styled.div`
    width: 100%;
    height: 100%;
    border-radius: ${props => props.isNote ? 10 : 44}px;
    background: #474b57;
    display: flex;
    justify-content: center;
    align-items: center;    
    position: relative;
    z-index: 4;

`;
/* 
const Earpiece = styled.div`
    width: 44px;
    height: 3px;
    top: 24px;
    background-color: #404040;
    z-index: 4;
    position: absolute;
    border-radius: 2px;    
`; */

const Camera = styled.div`
    width: 16px;
    height: 16px;
    top: 18px;
    background-color: #090A50;
    border: 3px solid #404040;
    z-index: 4;
    position: absolute;
    border-radius: 50%;
`;

const Body = styled.div`
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    border-radius: ${props => props.isNote ? 10 : 44}px;
    background-color: #6b6d76;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;

`;

const Screen = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: ${props => props.isNote ? 10 : 30}px;
    //background-color: #fff;    
    overflow: auto;
    backface-visibility: hidden;
    &::-webkit-scrollbar{
        width: 7px;
        height: 7px;
        box-shadow: inset 0 0 100px #2E3547;
        // border-radius: 10px;
        margin-right: 20px;
        
        &-thumb{
            height: 1em;
            border: 0.1em solid rgba(0, 0, 0, 0); 
            background-clip: padding-box;
            -webkit-border-radius: 0.5;
            background-color: rgba(202, 203, 211, 0.20);
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.025);
        }
    
        &-track{
            -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.025);
            //border-radius: 10px;
            padding: 10px;
        }
    }
`;

const checkIfNote = (id) => {
    if (id === SAMSUNG_NOTE_X) {
        return true;
    }
    else {
        return false;
    } 
}

const Samsung = (props) => {

    const { info, previewRnd } = props;
 
    let isNote = checkIfNote(info.id);

    return (
        <Mobile width={info.frameContent.width} height={info.frameContent.height} >
            <VolumeButton />
            <BixbyButton />
            {/* <PowerButton /> */}
            <Chassis isNote={isNote}>
                {/* <Earpiece /> */}
                <Camera />
                <Body width={info.viewport.width} 
                      height={info.viewport.height}
                      isNote={isNote}>
                    <Screen id="bb-device-frame-content-body" className="bb-samsung"
                            key ={previewRnd} 
                            isNote={isNote}
                             >
                        {props.children}
                    </Screen>
                </Body>
            </Chassis>
        </Mobile>
    )
}

export default Samsung;
