import React, { Component } from 'react';
import PreviewContainer from '../../preview/container';

import { config } from '../../../helpers'

class Streamview extends Component {
    constructor(props) {
        super(props)
        this.state = {
            clickThroughURL: "",
            width: "",
            height: "",
            startFrameImage: "",
            endFrameImage: "",
            videoSrc: "",
            videoStyle: "",
            closeButtonPosition: "",
            loading: true,
            totalF: 0,
            isDesktop: false
        };
    }

    xyz = [];

    componentDidMount() {
        window.addEventListener('message', this.handleBuilder);
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.handleBuilder);
    }

    handleBuilder = (event) => {
        if (event.data && (event.data.videoStyle)) {
            var that = this;
            this.setState({
                clickThroughURL: event.data.default_landingURL,
                width: event.data.size.width,
                height: event.data.size.height,
                startFrameImage: event.data.startFrameImage,
                endFrameImage: event.data.startFrameImage,
                videoSrc: event.data.videoSrc,
                videoStyle: event.data.videoStyle,
                closeButtonPosition: event.data.closeButtonPosition,
                loading: true,
                // eslint-disable-next-line
                isDesktop: event.data.device == "1" ? true : false,
            }, () => { that.loadStreamview(event);})

        }
    }

    loadStreamview = (event) => {
        var count = 0;
        this.xyz = new Array(2);
        var that = this;

        document.querySelector("#bm-creative").innerHTML = "";
        document.querySelector("#bm-creative").style.height = "0px";
        if (window.bmSV) {
            window.bmSV.destroy();
        }
        /* Remove if exists */
        if (document.getElementById('svCreativeScript')) {
            document.getElementById('svCreativeScript').remove();
        };

        var script = document.createElement("script");
        script.type = "text/javascript";
        script.id = "svCreativeScript";
        script.src = config.scripts.streamview.script;
        script.async = false;
        script.onload = function () {


            if (event.data["startFrameImage"] && event.data["videoSrc"]) {
                that.setState({ totalF: 2 });
            }
            else if (event.data["startFrameImage"] || event.data["videoSrc"]) {
                that.setState({ totalF: 1 });
            }

            if (that.state.totalF === 0) {
                if (window.bmSV) {
                    window.bmSV.destroy();
                }
                that.setState({ loading: false });
            }

            if (event.data["startFrameImage"]) {
                let files = event.data["startFrameImage"];
                if (FileReader && files && typeof files === "object") {
                    let fr = new FileReader();
                    fr.onload = function () {
                        var obj = {
                            src: fr.result,
                            thirdPartyImpressionURL: event.data["impressionURL_1"] !== "" ? event.data["impressionURL_1"] : null
                        };
                        that.xyz[0] = obj;
                        count++;
                        that.callInit(count);
                    }
                    fr.readAsDataURL(files);
                }
                else if (files) {
                    var obj = {
                        src: files,
                        thirdPartyImpressionURL: event.data["impressionURL_1"] !== "" ? event.data["impressionURL_1"] : null
                    };
                    that.xyz[0] = obj;
                    count++;
                    that.callInit(count);

                }
            }
            if (event.data["videoSrc"]) {
                let files = event.data["videoSrc"];
                if (FileReader && files && typeof files === "object") {
                    let fr = new FileReader();
                    fr.onload = function () {
                        var obj = {
                            src: fr.result,
                            thirdPartyImpressionURL: event.data["impressionURL_2"] !== "" ? event.data["impressionURL_2"] : null
                        };
                        that.xyz[1] = obj;
                        count++;
                        that.callInit(count);
                    }
                    fr.readAsDataURL(files);
                }
                else if (files) {
                    var object = {
                        src: files,
                        thirdPartyImpressionURL: event.data["impressionURL_2"] !== "" ? event.data["impressionURL_2"] : null
                    };
                    that.xyz[1] = object;
                    count++;
                    that.callInit(count);

                }
            }
        }
        document.querySelector("#bm-creative").appendChild(script);
    }

    callInit = (count) => {
        if (count === this.state.totalF) {
            if (window.bmSV) {
                window.bmSV.destroy();
            }
            this.inits();
        }
    }

    inits = () => {
        const { clickThroughURL, width, height, videoStyle, closeButtonPosition, isDesktop } = this.state;

        var startFrameImage = "";
        if (this.xyz[0]) {
            startFrameImage = this.xyz[0].src || "";
        }

        var videoSrc = "";
        if (this.xyz[1]) {
            videoSrc = this.xyz[1].src || "";
        }

        if (document.getElementById('renderStreamview')) {
            document.getElementById('renderStreamview').remove()
        };

        const setSv = document.createElement('script');
        setSv.setAttribute('id', 'renderStreamview');
        setSv.type = 'text/javascript';
        setSv.async = false;

        var containerWidth = isDesktop ? width : document.getElementsByClassName("bm-creative-preview")[0].clientWidth;
        var dynHeight = (containerWidth * height) / width;

        document.querySelector("#bm-creative").style.height = dynHeight + "px";

        var renderStreamview = `window.bmSV = new BMStreamview({
            clickThroughURL: '${clickThroughURL}' , //default clickthrough URL
            parentElement: document.getElementById("bm-creative"), //container element for the Sv element
            width: '${containerWidth}', //width of the Sv element
            height: '${dynHeight}', //height of the Sv element
            startFrameImage: '${startFrameImage}',//"https://via.placeholder.com/300/0000FF/808080?text=1",
            endFrameImage: '${startFrameImage}',
            videoSrc: '${videoSrc}',
            videoStyle: "${videoStyle}", 
            closeButtonShow: "false",
            closeButtonPosition: "${closeButtonPosition}",
            openURLTarget: "_blank", //_top, _parent, _blank
        });       
        `
        setSv.innerHTML = renderStreamview;

        this.setState({ loading: false });
        document.querySelector("#bm-creative").appendChild(setSv);

    }

    render() {
        return (
            <PreviewContainer {...this.state}>
                {
                    <React.Fragment>
                        {this.state.loading ?
                            <div className="w-100 text-center d-flex flex-column justify-content-center h-100">
                                <p className="bm-text_pink">Advertisement</p>
                            </div> : null
                        }
                        <div id="bm-creative" className="bm-creative-full-width"></div>
                    </React.Fragment>
                }
            </PreviewContainer>
        );
    }
}

export default Streamview;
