import React, { Component } from 'react';

import { config } from '../../../../../helpers'
//import { getPreviewContainerWidth } from '../../../../utilities/customFunctions'
import PreviewFrame from '../../../../helpers/preview-frame'

const adContainer = "#bm-creative";

class TruSkinDesktopClassic extends Component {

  componentDidMount() {    
   window.addEventListener("resize", this.updateCreative); 
   window.addEventListener("orientationchange", this.updateCreative);

    if (this.props.details && this.props.details._id) {      
      this.loadAd(this.props.details);
    }
  }

/*   componentDidUpdate(prevProps) {
    if (this.props.details !== prevProps.details) {
      if (this.props.details && this.props.details._id) {
        this.loadAd(this.props.details);
      }
    }
  } */

  componentWillUnmount(){    
    window.removeEventListener("resize", this.updateCreative);
    window.removeEventListener("orientationchange", this.updateCreative);
  }

  updateCreative = () => {    
    if (this.props.details) {
      //const {details} = this.props;
      var bmAdContainer = document.querySelectorAll('*[id^="bmAdContainer"]')[0];
      var bmWrapper = document.querySelectorAll('*[id^="bmWrapper"]')[0];

      var browserWidth = 0;//this.props.isDesktop ? details.width : getPreviewContainerWidth(details.width);
      //document.getElementsByClassName("bm-creative-preview")[0].clientWidth;
      
      var dynHeight = 0;//(browserWidth * details.height) / details.width;

      if (bmAdContainer) {        
        bmAdContainer.style.width = browserWidth + "px";
        bmAdContainer.style.height = dynHeight + "px";
      }
      if (bmWrapper) {
        bmWrapper.style.width = browserWidth + "px";
        bmWrapper.style.height = dynHeight + "px";
      }

      document.querySelector(adContainer).style.width = browserWidth + "px";
      document.querySelector(adContainer).style.height = dynHeight + "px";      
    }
  }

  loadAd = (details) => {
    var adImages = [];
    var that = this;
    //console.log(this.props.loading);    
    document.querySelector(adContainer).innerHTML = "";
    document.querySelector(adContainer).style.height = "0px";        

    if (window.bmTruskinDC) {
        window.bmTruskinDC.destroy();
    }

    /* Remove if exists */
    if (document.getElementById('adCreativeScript')) {
      document.getElementById('adCreativeScript').remove();
    };
    
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.id = "adCreativeScript";
    script.src = config.scripts.truskin.desktop.classic.script;
    script.async = false;
    script.onload = function () {

      for(let i = 0; i< details.content.length ; i++){
        var obj = {
          imageSrc: details.content[i].imageSrc,
          clickThroughURL: details.content[i].clickThroughURL ? details.content[i].clickThroughURL : details.content[i].default_landingURL,
          thirdPartyImpressionURL: details.content[i].default_impressionURL ? details.content[i].default_impressionURL  : null
        };
        adImages[i] = obj;
      }
      that.initAd(details, adImages);
    };
    document.querySelector(adContainer).appendChild(script);
  
  }

  initAd = (details, adImages) => {
    if (document.getElementById('renderAd')) {
      document.getElementById('renderAd').remove()
    };

    const setAd = document.createElement('script');
    setAd.setAttribute('id', 'renderAd');
    setAd.type = 'text/javascript';
    //setad.async = true;
    setAd.async = false;
    
    var containerWidth = 0; //this.props.isDesktop ? details.width : getPreviewContainerWidth(details.width);
    //var containerWidth = document.getElementsByClassName("bm-creative-preview")[0].clientWidth;
    var dynHeight = 0; //(containerWidth * details.height) / details.width;

    document.querySelector(adContainer).style.height = dynHeight + "px";
   
    var headerElement = adImages[0] || "";
    var leftElement = adImages[1] || "";
    var rightElement = adImages[2] || "";
    var footerElement = adImages[3] || "";

    var renderAd = `window.bmTruskinDC = new BMSetTruSkin({
          clickThroughURL: '${details.default_landingURL}' , //default clickthrough URL
          parentElement: document.getElementById("bm-creative"), //container element for the Ad element
          previewContainerElement: document.getElementById("bm-creative-preview"),
          width: '${containerWidth}', //width of the Ad element
          height: '${dynHeight}', //height of the Ad element
          thirdPartyImpressionURL: null,        
          openURLTarget: "_blank", //_top, _parent, _blank
          headerElement: ${JSON.stringify(headerElement)},
          leftElement:${JSON.stringify(leftElement)},
          rightElement: ${JSON.stringify(rightElement)},
          footerElement: ${JSON.stringify(footerElement)}
      });`

    setAd.innerHTML = renderAd;
    document.querySelector(adContainer).appendChild(setAd);

  }

  render() {
    return (        
      <PreviewFrame {...this.props}/>
    );
  }
}

export default TruSkinDesktopClassic;
