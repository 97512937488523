import React from 'react'
import styled from 'styled-components'

//import { IPHONE_X, IPHONE_Y, IPHONE_Z } from '../../device-list'

const Frame = styled.div`
    width: ${props => props.width + 10}px;
    height: ${props => props.height + 10}px;
    background-color: #ffffff;
    position: relative;
    margin: 20px auto 10px auto;
    border: 7px solid #474b57;
    border-radius: 50px;
    box-shadow: 14px 21px 42px 10px rgba(0, 0, 0, .2); 
`;

const FrameContent = styled.div`
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    background-color: #6b6d76;
    border: 16px solid #6b6d76;
    position: absolute;
    left: 50%;
    top: 50%; 
    transform: translate(-50%, -50%);
    border-radius: 47px; 
    //overflow: hidden;
`;
  
const FrameContentHeader = styled.div`
    width: 100%;
    position: relative;
    top: 0;
    left: 0;     
`;

const IphoneXFrameContentHeader = styled(FrameContentHeader)`
    height: 30px;
    background-color: #f9f9f9; /*linear-gradient(to top left,  #D0FBED,#FDD7EA , #f9f9f9); */
    border-radius: 32px 32px 0 0;
    border-bottom: 1px solid #ededed;
    z-index: 3;
`;

const IphoneOldFrameContentHeader = styled(FrameContentHeader)`
    height: 65px;
    background-color: #6b6d76; 
`;

const FrameHeader = styled.div`
    position: absolute;    
    top: -2px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #6b6d76;   
    
`;

const IphoneXFrameHeader = styled(FrameHeader)`
    width: 170px;
    height: 24px;
    border-radius: 0 0 23px 23px;
`;

const IphoneOldFrameHeader = styled(FrameHeader)`
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const FrameHeaderBottom = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const FrameHeaderSensor = styled.div`    
    background-color: #090A50;
    border: 3px solid #404040;
    border-radius: 50%;    
`;

const IphoneXFrameHeaderSensor = styled(FrameHeaderSensor)`
    position: absolute;
    width: 5px;
    height: 5px;
    left: 45px;
    top: 5px;
`;

const IphoneOldFrameHeaderSensor = styled(FrameHeaderSensor)`
    margin-bottom: 10px;
    width: 8px;
    height: 8px;
`;

const FrameHeaderSpeaker = styled.div`    
    background-color: #404040;
    margin-left: auto;
    margin-right: auto;    
`;

const IphoneXFrameHeaderSpeaker = styled(FrameHeaderSpeaker)`
    margin-top: 6px;
    width: 40px;
    height: 4px;
`;

const IphoneOldFrameHeaderSpeaker = styled(FrameHeaderSpeaker)`
    width: 80px;
    height: 6px;
    border-radius: 10px;
    margin-right: 20px;
`;

const FrameHeaderCamera = styled.div`    
    background-color: #090A50;
    border: 3px solid #404040;
    border-radius: 50%;    
`;

const IphoneXFrameHeaderCamera = styled(FrameHeaderCamera)`
    position: absolute;
    width: 10px;
    height: 10px;
    right: 40px;
    top: 3px;
`;

const IphoneOldFrameHeaderCamera = styled(FrameHeaderCamera)`
    width: 12px;
    height: 12px;
    margin-right: 20px;
`;

const HeaderContainer = styled.div`
`;

const HeaderBars = styled.div`
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    color: #404040;
`;

const HeaderBarsBlock = styled.div`
    margin-top: 4px;
    span {
    font-size: 14px;
    margin-left: 40px;
    font-weight: bold;
    }
    i {
    font-size: 14px;
    }
    .faw-icons{
        margin-right: 10px;
        *{
            margin-right: 5px;
        }
    }
`;

const BodyContainer = styled.div`
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100% - ${props => props.extraHeight}px); /*FrameContentBottom plus FrameContentHeader height*/
    background: white;
    position: relative;
    &::-webkit-scrollbar{
        width: 7px;
        height: 7px;
        box-shadow: inset 0 0 100px #2E3547;
        // border-radius: 10px;
        margin-right: 20px;
        
        &-thumb{
            height: 1em;
            border: 0.1em solid rgba(0, 0, 0, 0); 
            background-clip: padding-box;
            -webkit-border-radius: 0.5;
            background-color: rgba(202, 203, 211, 0.20);
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.025);
        }
    
        &-track{
            -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.025);
            //border-radius: 10px;
            padding: 10px;
        }
    }
`;

const FrameContentBody = styled.div`
    width: 100%;
`;

const FrameContentBottom = styled.div`
    position: absolute;    
    width: 100%;
    border-radius: 0 0 30px 30px;     
`;

const IphoneXFrameContentBottom = styled(FrameContentBottom)`
    bottom: 0;
    height: 28px;
    background-color: #f9f9f9;
`;

const IphoneOldFrameContentBottom = styled(FrameContentBottom)`
    background-color: #6b6d76;
    bottom: -13px;
    height: 65px;   
`;


const IphoneXBottomShape = styled.div`
    width: 110px;
    height: 4px;
    margin-top: 15px;
    background-color: #c8c8c8;
    margin-left: auto;
    margin-right: auto;
    border-radius: 3px;
    transition: all .3s;
    cursor: pointer;

    &:hover {
        background-color: #323232;
    }
`;

const IphoneOldBottomShape = styled.div`
    display: block;
	margin: 0 auto;
	width: 56px;
	height: 56px;
	background: #ccc;
	border-radius: 50%;
	background: linear-gradient(
		135deg, #303233 0%,
		#b5b7b9 50%,
		#f0f2f2 69%,
		#303233 100%
	);
	position: relative; 
    &:after{
        content: "";
	display: block;
	width: 48px;
	height: 48px;
	background: #6b6d76;
	border-radius: 50%;
	position: absolute;
	left: 4px;
    top: 4px;
    opacity:0.9; 
    }
`;

const VolumeButton = styled.div`
    left: -22px;
    top: 82px;
    position: absolute;
    background: #6b6d76;
    height: 24px;
    width: 3px;
    &:after{
        left: 0;
        top: 50px;
        content: "";
        position: absolute;
        background: #6b6d76;
        height: 45px;
        width: 3px;
    }
    &:before{
        left: 0;
        top: 106px;
        content: "";
        position: absolute;
        background: #6b6d76;
        height: 45px;
        width: 3px;
    }
`;

const PowerButton = styled.div`
    right: -22px;
    top: 130px;
    position: absolute;
    background: #6b6d76;
    height: 80px;
    width: 3px;
`;

const checkIfOld = (id) => {
    /* if (id === IPHONE_5) {
        return true;
    }
    else {
        return false;
    } */
    return false;
}

function checkTime(i) {
    if (i < 10) {
        i = "0" + i;
    }
    return i;
}

function getTime() {
    let today = new Date();
    let h = today.getHours();
    let m = today.getMinutes();
    m = checkTime(m);    
    return (h + ":" + m); 
}

const Iphone = (props) => {

    const { info, previewRnd } = props;
 
    let isOld = checkIfOld(info.id);
    return (
        <>
            <Frame width={info.frameContent.width} height={info.frameContent.height}>
                <FrameContent width={info.frameContent.width} height={info.frameContent.height}>
                    {isOld
                        ?
                        <IphoneOldFrameContentHeader>
                            <IphoneOldFrameHeader>
                                <IphoneOldFrameHeaderSensor />
                                <FrameHeaderBottom>
                                    <IphoneOldFrameHeaderCamera />
                                    <IphoneOldFrameHeaderSpeaker />
                                </FrameHeaderBottom>                                
                            </IphoneOldFrameHeader>
                            <VolumeButton />
                            <PowerButton />   
                        </IphoneOldFrameContentHeader>
                        :
                        <IphoneXFrameContentHeader>
                            <IphoneXFrameHeader>
                                <IphoneXFrameHeaderSensor />
                                <IphoneXFrameHeaderSpeaker />
                                <IphoneXFrameHeaderCamera />                                 
                            </IphoneXFrameHeader>
                            <VolumeButton />
                            <PowerButton /> 
                            <HeaderContainer>
                                <HeaderBars>
                                    <HeaderBarsBlock>
                                        <span>{getTime()}</span>
                                    </HeaderBarsBlock>
                                    <HeaderBarsBlock>
                                        <div className="faw-icons">
                                            <i className="fas fa-signal"></i>
                                            <i className="fas fa-wifi"></i>
                                            <i className="fas fa-battery-full"></i>
                                        </div>
                                    </HeaderBarsBlock>
                                </HeaderBars>
                            </HeaderContainer>
                        </IphoneXFrameContentHeader>
                    }
                    <BodyContainer id="bb-device-frame-body" extraHeight = {isOld ? 130 : 58}>
                        <FrameContentBody id="bb-device-frame-content-body" key ={previewRnd}>
                            {props.children}
                        </FrameContentBody>
                    </BodyContainer>
                    {isOld ?
                        <IphoneOldFrameContentBottom>
                            <IphoneOldBottomShape />
                        </IphoneOldFrameContentBottom>
                        :
                        <IphoneXFrameContentBottom>
                            <IphoneXBottomShape />
                        </IphoneXFrameContentBottom>
                    }
                </FrameContent>
            </Frame>
        </>
    )
}

export default Iphone;