
import React from 'react'
import classnames from 'classnames'
import ReactElementToJSXString from 'react-element-to-jsx-string';
import './index.scss'

const MainArticle = [
    {
        id: 1,
        header: `10 of the world’s dreamiest resort swimming pools`,
        category: `TOP LISTS`,
        description: `Melinda Browning`,
        image: `/images/template/escape/article-1.png`,
    },
    {
        id: 2,
        header: `Win a Viking cruise to the Mediterranean worth over $19K`,
        category: `COMPETITIONS`,
        description: `In partnership with Viking`,
        image: `/images/template/escape/article-2.jpg`,
    },
    {
        id: 3,
        header: `11 exceptionally cool resorts in Bali`,
        category: `TOP LISTS`,
        description: `Jenny Hewett`,
        image: `/images/template/escape/article-3.jpg`,
    },
]
const SubArticle = [
    {
        id: 1,
        header: `Merrick Watts`,
        image: `/images/template/escape/person-1.jpg`,
    },
    {
        id: 2,
        header: `Tyson Mayr`,
        image: `/images/template/escape/person-2.jpg`,
    },
    {
        id: 3,
        header: `Melissa Leong`,
        image: `/images/template/escape/person-3.jpg`,
    },
    {
        id: 4,
        header: `Darren McMullen`,
        image: `/images/template/escape/person-4.jpg`,
    },
    {
        id: 5,
        header: `Alison Kubler`,
        image: `/images/template/escape/person-5.jpg`,
    },
    {
        id: 6,
        header: `Kendall Hill`,
        image: `/images/template/escape/person-6.jpg`,
    },
]
const escapeHeader = (desktop = false) => {
    return (
        desktop ? 
            <div className="desktop-header desktop-size mb-3">
                <div className="text-center bg-white pt-3">
                    <img className="template-image" src="/images/template/escape/header-desktop-1.png" alt="escape.com.au header" />
                </div>
                <div className="text-center bg-white">
                    <img className="template-image" src="/images/template/escape/header-desktop-2.png" alt="escape.com.au header" />
                </div>
            </div>
            : <div className="mobile-header">
                <img className={"template-image header"} src={"/images/template/escape/header.png"} alt="escape.com.au header" />
            </div>
    )
}

const escapeFooter = (desktop = false) => {
    return (
        <div>
        <div className='text-center bg-white subcontent'>
            <img alt="" src={desktop ? "/images/template/escape/subcontent-desktop.png" : "/images/template/escape/subcontent.png"} className={classnames("template-image", desktop ? "desktop-size mb-4" : "")}/>
        </div>
        {desktop ?
            <>
                <div className="desktop-footer">
                    <div className='text-center'>
                        <div className='desktop-size'>
                            {escapeTraveler(desktop)}
                        </div>
                    </div>
                    <div className="bg-white p-3">
                        <img className="template-image" src="/images/template/escape/footer-desktop-1.png" alt="escape.com.au footer" />
                    </div>
                </div>
                <div className="bg-gray p-2 text-center">
                    <img className="template-image desktop-size" src="/images/template/escape/footer-desktop-2.png" alt="escape.com.au footer" />
                </div>
            </>
            :
            <div className='bg-white'>
                {escapeTraveler(desktop)}
                <img className="template-image" src="/images/template/escape/footer-1.png" alt="escape.com.au footer" />
                <img className="template-image" src="/images/template/escape/footer-2.png" alt="escape.com.au footer" />
            </div>}
        </div>
        
    )
}
const escapeTraveler = (desktop = false) => {
    return (
        <div className='traveler-content'>
            <h2 className='font-weight-bold text-left'>Travel Experts</h2>
            <div className='d-flex traveler-list'>
                {SubArticle.map( content => 
                <div className={classnames("escape-traveler", (desktop ? "desktop col-2" : "mobile col-6"))} key={content.id}>
                    <img src={content.image} alt="escape.com.au article" />
                    <h6 className='my-2 text-left font-weight-bold'>{content.header}</h6>
                </div>)}
                
            </div>
        </div>
    )
}
const escapeArticle = (content, desktop = false, customClass = "col-6") => {
    return (
        <div className={classnames("escape-article", (desktop ? "desktop d-flex" : "mobile"), customClass)} key={content.id}>
            <div className={classnames('card-image mb-4 p-0', desktop ? "col-6" : "")}>
                <img src={content.image} alt="escape.com.au article" />
            </div>
            <div className='card-info'>
                <div className='category-label text-main font-weight-bold'>{content.category}</div>
                <h4 className='my-2 font-weight-bold'>{content.header}</h4>
                <div className='description-label'>{content.description}</div>
            </div>
        </div>
    )
}

const escapeMainArticle = (desktop = false) => {
    return (
        <div className={"main-article bg-white pb-0"}>
            <h2 className="font-weight-bold text-black">Pack Your Bags</h2>
            <div className="escape-article p-0">
                {desktop ? <div className='my-2'>
                        <img alt="" src="/images/template/escape/main-article.jpg"/>
                        <div className='category-label text-main font-weight-bold mt-3'>AUSTRALIA</div>
                        <h4 className='my-2 font-weight-bold'>Best places to go to avoid Australian winter</h4>
                        <div className='description-label mb-4'>Simone Mitchell</div>
                    </div> : null}
            </div>
            <div className='py-2 article-list'>
                {MainArticle.map( content => escapeArticle(content, desktop, desktop ? "col-12" : "col-12"))}
            </div>
        </div>
    )
}
export const escapeMobile = (pos, isInterstitialCreative = false, isScrolloCreative = false, isScrolloDesktopCreative = false, isMobileSkinCreative = false, renderAd, height) => {
    let bottom = height && height <= 150
    return (
        <>
            <div className={classnames("bm-creative-preview-ad-content escape mobile position-relative", isScrolloDesktopCreative ? "ad-content-scrollo-desktop" : "")}>
                {escapeHeader()}
                
                {escapeMainArticle()}

                {!bottom && pos.match(/^(center|middle)$/ig) && !isInterstitialCreative ? <div className="center-ad-mobile"> {renderAd()} </div> : null}
                
                {escapeFooter()}
                {bottom && !isInterstitialCreative ? <div className='sticky-ad-mobile'>
                    {renderAd()}
                </div> : null}
            </div>
        </>
    )
}

export const escapeDesktop = (adPos, width, height, isSkinCreative, isScrolloCreative, renderAd) => {
    return (
        <div className={classnames("escape desktop", isSkinCreative ? "proskin-desktop" : "")}>
            {adPos === "top" && !isSkinCreative &&
                <div className={classnames('billboard-container', isSkinCreative ? 'h-0' : '')}>
                    <div className='sticky-container'>
                        {renderAd(false, width, isSkinCreative)}
                    </div>
            </div>}
            {escapeHeader(true)}
            <div className="desktop-size w-100">
                {adPos === "center" ? <div id="right-container"> {renderAd(false, width, isSkinCreative)} </div> : null }

                <div id="bm-desktop-container" style={adPos === "center" ? {paddingRight: "24px"} : {}} className={"bm-creative-preview-ad-content bm-desktop-container row escape-desktop" + (isSkinCreative ? "" : " premium-escape")}>
                    <div className='preview-container escape'>
                        <div id="page-container">
                            <div id='content'>
                                {escapeMainArticle(true)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {escapeFooter(true)}
        </div>
    )
}

export const escapeScroller = (isDesktop, browserWidth, dynHeight) => {
    return (
        <>
            {isDesktop ?
                (
                    <> </>
                )
                : (
                    <div className={"bm-creative-preview-ad-content escape mobile"}>
                        <div className="escape pro-scroll-escape">
                            {escapeHeader()}
                            {escapeMainArticle()}
                        </div>

                        <div style={{ width: browserWidth + "px", height: dynHeight + "px" }}> </div>
                
                        <div className="escape"> {escapeFooter()} </div>
                    </div>
                )}
        </>)
}

export const escapeMobileSkin = (src = "") => {
    return (
        `
        <div class="bm-creative-preview-ad-content escape proskin-mobile">
          ${ReactElementToJSXString(escapeHeader(false)).replace(/classname/ig, "class")}
          <div class="proskin-mobile-content">
            ${ReactElementToJSXString(escapeMainArticle(false)).replace(/classname/ig, "class")}
          </div>
          
          <div id="bm-creative-container" class="bm-ad col-12">
            <div id="bm-preview-iframe-container" class="mx-auto"> ${src} </div>
          </div> 
          ${ReactElementToJSXString(escapeFooter(false)).replace(/classname/ig, "class")}
      </div>`
    )
}
export const escapeCss = `
    *, ::after, ::before {
        box-sizing: border-box;
    }
    .d-flex{ display: flex; }
    .col-6{ flex: 0 0 50%; max-width: 50%; padding: 0px; margin: 0; }
    .col-12{ flex: 0 0 100%; max-width: 100%; padding: 0px; margin: 0; }
    .bg-white{ background-color: white; }
    .mb-4{ margin-bottom: 1.5rem!important; }
    .my-2{ margin-bottom: .5rem!important; margin-top: .5rem!important; }
    .p-0{ padding: 0px!important }
    .font-weight-bold { font-weight: 700!important; }
    h2{ font-size: 2rem; }
    h4{ font-size: 1.5rem; }
    h6{ font-size: 1rem; }

    .escape{
        font-family: Quicksand,"Helvetica Neue",Roboto,Arial,sans-serif;
        user-select: none;
        -webkit-user-drag: none;
        letter-spacing: 1.2px !important;
        position: relative;
        font-size: 14px;
        color: #202223;
    }
    .template-image{ width: 100%; }
    .text-description{ font-weight: 600; text-align: left; }
    .text-main{ color: #0f6cc7; }
    .bg-white{ background-color: white; }
    .bg-gray{ background-color: #727272; }
    .main-article{ padding: 24px; }
    .main-article h2{ margin-top: 0px; }
    .traveler-content, .subcontent {
        padding-left: 24px;
        padding-right: 24px;
    }
    .traveler-content {
        overflow: hidden !important;
        padding-bottom: 30px;
    }
    .traveler-content img{ width: 100%; border-radius: 8px;} 
    .escape-traveler{
        padding: 0;
        padding-right: 16px;
        &:last-child{ padding-right: 0; }
    }
    .traveler-list{ overflow: hidden; }
    .escape-article {
        padding: 0px 0px 35px 0px;
        background: white;
        display: inline-grid;
    }
    .escape-article img{ width: 100%; height: auto; border-radius: 8px; }
    
  `