class Config {
    constructor() {
        this.scripts = { 
            swipe: {
                setSwipe: "https://s3-ap-southeast-2.amazonaws.com/library.bigbuddy.io/scripts/swipe/BMSetSwipe.0.2.js",
                threeD: "https://s3-ap-southeast-2.amazonaws.com/library.bigbuddy.io/scripts/swipe/BMSwipe3d.0.1.js",
                threeDV: "https://s3-ap-southeast-2.amazonaws.com/library.bigbuddy.io/scripts/swipe/BMSwipe3dv.0.2.js",
                twoD: "https://s3-ap-southeast-2.amazonaws.com/library.bigbuddy.io/scripts/swipe/BMSwipe2d.0.2.js",
                defaultIcon: "https://s3-ap-southeast-2.amazonaws.com/library.bigbuddy.io/templates/images/swipe/swipe.png",
                defaultIconV: "https://s3-ap-southeast-2.amazonaws.com/library.bigbuddy.io/templates/images/swipe/swipe_vertical.png",
                leftArrowIcon: "https://s3-ap-southeast-2.amazonaws.com/library.bigbuddy.io/templates/images/carousel/left_arrow.png",
                rightArrowIcon: "https://s3-ap-southeast-2.amazonaws.com/library.bigbuddy.io/templates/images/carousel/right_arrow.png"
            },
            streamview: {
                script: "https://s3-ap-southeast-2.amazonaws.com/library.bigbuddy.io/scripts/streamview/BMStreamview.0.3.js"
            },
            still: {
                script: "https://s3-ap-southeast-2.amazonaws.com/library.bigbuddy.io/scripts/still/BMSetStill.0.1.js"
            },
            scratch: {
                script: "https://s3-ap-southeast-2.amazonaws.com/library.bigbuddy.io/scripts/scratch/BMSetScratch.0.1.js",
                defaultIcon: "https://s3-ap-southeast-2.amazonaws.com/library.bigbuddy.io/templates/images/scratch/scratchIcon.png"
            },
            truskin: {
                desktop: {
                    classic: {
                        script: "https://s3-ap-southeast-2.amazonaws.com/library.bigbuddy.io/scripts/truskin/desktop/classic/BMSetTruSkin.0.1.js"
                    }
                }
            },
            proskin: {
                desktop: {
                    classic: {
                        script: "https://s3-ap-southeast-2.amazonaws.com/library.bigbuddy.io/scripts/proskin/desktop/classic/BMSetProSkin.0.1.js"
                    },
                    video: {
                        script: "https://s3-ap-southeast-2.amazonaws.com/library.bigbuddy.io/scripts/proskin/desktop/video/BMSetProSkinVideo.0.2.js"
                    }
                }
            }
           

        }


        this.apiServer = "https://engine.bigbuddy.io";        
        this.host_bb = window.location.protocol + "//" + window.location.host + '';
        this.dataLimit = 10;

        this.buddyServer = "https://buddydecarbonise.com";

        if (process.env.NODE_ENV === "development" || 
            window.location.href.indexOf("test.preview.studio") > -1  ||
            window.location.href.indexOf("beta.preview.studio") > -1 ) 
        {
            this.apiServer = "https://staging2.bigbuddy.io";
            this.buddyServer = "https://test.buddydecarbonise.com";
            //this.buddyServer = "http://localhost:3000";
        }

        /* Buddy admin releated */
        this.bbShowDealInfo = "bb-show-deal-info";
        
        this.authId = () =>{
            if(localStorage.getItem(this.bbShowDealInfo)){
                return localStorage.getItem(this.bbShowDealInfo);
            }
            else{
                return false;
            }
        }

        this.setToken = (v) =>{
            localStorage.setItem(this.bbShowDealInfo, v);
        }

        this.removeToken = () =>{
            localStorage.removeItem(this.bbShowDealInfo);
        }
    }
}

export const config = new Config();
   